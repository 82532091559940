import React, { useEffect, useState } from 'react'
import { useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/hizlihesap/actions";
import { Badge, Button, Calendar, Card, Col, Row, Select, Tag } from 'antd';
import moment from 'moment';
import 'moment/locale/tr';
import { CalendarOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import info from '../../utils/marketplaces.json'
import { isHaveAccess } from '../../utils/Permission';
import { showNotification } from '../../utils/showNotification';
moment.locale('tr');

const MONTHS = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık']



const PaymentCalendarContent = () => {
  const [value, setValue] = useState(moment())
  const [marketPlace, setMarketPlace] = useState([])
  const [marketPlaceOptions, setMarketPlaceOptions] = useState([])

  const history = useHistory()

  useEffect(() => {
    const user_id = JSON.parse(window.localStorage.getItem('user')).id
    const access = async () => {
      const perms = await isHaveAccess(user_id, "payment-calendar")
      if (!perms) {
        history.push('/')
        showNotification('error', 'Yetkiniz Yok!', 'Gitmek istediğiniz sayfaya yetkiniz bulunmamaktadır!')
        return
      }
    }
    access()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const tempArr = []
    const marketPlaceTemp = []
    for (const [key, value] of Object.entries(info.marketplaces)) {
      const obj = {
        id: value,
        name: key
      }
      tempArr.push(obj)
      marketPlaceTemp.push(value)
    }
    setMarketPlace(marketPlaceTemp)
    setMarketPlaceOptions(tempArr)
  }, [])



  const onSelect = value => {
    setValue(value)
  };

  const onPanelChange = value => {
    setValue(value)
  };


  const handleMarketplaceSelect = (value) => {
    if (value.includes("all")) {
      setMarketPlace(marketPlaceOptions.map(r => r.id))
    } else {
      setMarketPlace(value)
      console.log('VALUE : ', value)
    }
  }

  const marketPlaceSelectOptions = marketPlaceOptions.map((res, index) => {
    return (
      <Select.Option key={index} value={res.id}>{res.name}</Select.Option>
    )
  })

  const headerRender = ({ value, onChange }) => {
    const today = moment();
    const thisMount = today.month()

    const month = value.month();
    const year = value.year()
    return (
      <div>
        <Row gutter={[16, 16]} align='middle'>
          <Col xs={12}>
            <span className='font-w600 font-s24'>{MONTHS[month]} {year}</span>
          </Col>
          <Col xs={12} align='right'>
            <Button size='small' icon={<LeftOutlined />} onClick={() => {
              const newValue = value.clone();
              newValue.month(parseInt(month - 1, 10))
              onChange(newValue)
            }} />
            <Button size='small' icon={<CalendarOutlined />} onClick={() => {
              const newValue = value.clone();
              newValue.month(parseInt(thisMount, 10))
              onChange(newValue)
            }}>Bugün</Button>
            <Button size='small' icon={<RightOutlined />} onClick={() => {
              const newValue = value.clone();
              newValue.month(parseInt(month + 1, 10))
              onChange(newValue)
            }} />
          </Col>
          <Col xs={3}>
            <label className="payment-calendar-container">Gelirler
              <input type="checkbox" defaultChecked={true} onChange={(event) => console.log('CHECKED: ', event.target.checked)} />
              <span className="checkmark checkmark-green"></span>
            </label>
          </Col>
          <Col xs={3}>
            <label className="payment-calendar-container">Giderler
              <input type="checkbox" defaultChecked={true} onChange={(event) => console.log('CHECKED: ', event.target.checked)} />
              <span className="checkmark checkmark-red"></span>
            </label>
          </Col>
          <Col xs={6}>
            <label className="payment-calendar-container">Özel Günler
              <input type="checkbox" defaultChecked={true} onChange={(event) => console.log('CHECKED: ', event.target.checked)} />
              <span className="checkmark checkmark-blue"></span>
            </label>
          </Col>
          <Col xs={12} align=''>
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              dropdownClassName='product-filter'
              placeholder="Lütfen pazaryeri seçin"
              onChange={handleMarketplaceSelect}
              value={marketPlace}>
              <Select.Option value={'all'}>Hepsi</Select.Option>
              {marketPlaceSelectOptions}
            </Select>
          </Col>
        </Row>
      </div>
    );
  }

  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map(item => (
          <li key={item.content}>
            <Badge style={{ fontSize: 8 }} status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  }

  const getListData = (value) => {
    let listData;
    switch (value.date()) {
      case 8:
        listData = [
          { type: 'error', content: 'İade - 250 ₺' },
          { type: 'success', content: 'Ödeme - 2000 ₺' },
        ];
        break;
      case 10:
        listData = [
          { type: 'warning', content: 'This is warning event.' },
          { type: 'success', content: 'This is usual event.' },
          { type: 'error', content: 'This is error event.' },
        ];
        break;
      case 15:
        listData = [
          { type: 'warning', content: 'This is warning event' },
          { type: 'success', content: 'This is very long usual event。。....' },
          { type: 'error', content: 'This is error event 1.' },
          { type: 'error', content: 'This is error event 2.' },
          { type: 'error', content: 'This is error event 3.' },
          { type: 'error', content: 'This is error event 4.' },
        ];
        break;
      default:
    }
    return listData || [];
  }

  const dateFullCellRender = (date) => {
    const day = date.day();
    const date_ = date.date()
    let style;
    if (day === 6 || day === 0) {
      style = { backgroundColor: '#f7f7f7', height: 116, maxHeight: 116, minHeight: 116 }
    } else {
      style = { height: 116, maxHeight: 116, minHeight: 116 }
    }
    return (
      <div style={style} className='ant-picker-cell-inner ant-picker-calendar-date'>
        <div className='ant-picker-calendar-date-value'>
          {date_}
        </div>
        <div className='ant-picker-calendar-date-content'>
          {dateCellRender(date)}
        </div>
      </div>
    )
  }

  const events = () => {
    switch (value.date()) {
      case 8:
        return (
          <div>
            <Row className='my-10 p-10' style={{ borderBottom: '1px solid #dfe2e8' }}>
              <Col xs={6} align='left'>
                <Tag color="green" style={{ textAlign: 'center' }}>6<br />Cum</Tag>
              </Col>
              <Col xs={18} align='right'>
                <span className='font-w700' style={{ fontSize: 12 }}>Ödeme Günü</span>
                <br />
                <span style={{ fontSize: 11 }}>06.06.2022 - 06.07.2022</span>
              </Col>
            </Row>
            <Row className='my-10 p-10' style={{ borderBottom: '1px solid #dfe2e8' }}>
              <Col xs={6} align='left'>
                <Tag color="green" style={{ textAlign: 'center' }}>6<br />Cum</Tag>
              </Col>
              <Col xs={18} align='right'>
                <span className='font-w700' style={{ fontSize: 12 }}>Ödeme Günü</span>
                <br />
                <span style={{ fontSize: 11 }}>06.06.2022 - 06.07.2022</span>
              </Col>
            </Row>
          </div>
        )
      case 10:
        return (
          <div>
            <Row className='my-10 p-10' style={{ borderBottom: '1px solid #dfe2e8' }}>
              <Col xs={6} align='left'>
                <Tag color="green" style={{ textAlign: 'center' }}>6<br />Cum</Tag>
              </Col>
              <Col xs={18} align='right'>
                <span className='font-w700' style={{ fontSize: 12 }}>Ödeme Günü</span>
                <br />
                <span style={{ fontSize: 11 }}>06.06.2022 - 06.07.2022</span>
              </Col>
            </Row>
            <Row className='my-10 p-10' style={{ borderBottom: '1px solid #dfe2e8' }}>
              <Col xs={6} align='left'>
                <Tag color="green" style={{ textAlign: 'center' }}>6<br />Cum</Tag>
              </Col>
              <Col xs={18} align='right'>
                <span className='font-w700' style={{ fontSize: 12 }}>Ödeme Günü</span>
                <br />
                <span style={{ fontSize: 11 }}>06.06.2022 - 06.07.2022</span>
              </Col>
            </Row>
          </div>
        )
      case 15:
        return (
          <div>
            <Row className='my-10 p-10' style={{ borderBottom: '1px solid #dfe2e8' }}>
              <Col xs={6} align='left'>
                <Tag color="green" style={{ textAlign: 'center' }}>6<br />Cum</Tag>
              </Col>
              <Col xs={18} align='right'>
                <span className='font-w700' style={{ fontSize: 12 }}>Ödeme Günü</span>
                <br />
                <span style={{ fontSize: 11 }}>06.06.2022 - 06.07.2022</span>
              </Col>
            </Row>
            <Row className='my-10 p-10' style={{ borderBottom: '1px solid #dfe2e8' }}>
              <Col xs={6} align='left'>
                <Tag color="green" style={{ textAlign: 'center' }}>6<br />Cum</Tag>
              </Col>
              <Col xs={18} align='right'>
                <span className='font-w700' style={{ fontSize: 12 }}>Ödeme Günü</span>
                <br />
                <span style={{ fontSize: 11 }}>06.06.2022 - 06.07.2022</span>
              </Col>
            </Row>
          </div>
        )
      default:
    }
  }

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={19}>
          <Card>
            <Calendar locale={{
              lang: {
                locale: 'tr',
                dayFormat: moment.updateLocale('tr', {
                  weekdaysMin: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt']
                }),
              }
            }} value={value} onSelect={onSelect} dateFullCellRender={dateFullCellRender}
              dateCellRender={dateCellRender} onPanelChange={onPanelChange} headerRender={headerRender} />
          </Card>
        </Col>
        <Col xs={5}>
          <Card style={{height: "100%"}}>
            <span className='font-s18 font-w600' style={{fontSize: 15, whiteSpace: 'nowrap'}}>Seçili Tarihteki Etkinlikler</span>
          </Card>
          {events()}
        </Col>
      </Row>
    </div>
  )
}


const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(PaymentCalendarContent));