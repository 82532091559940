import React, { useEffect, useState }  from 'react';
import { Line, G2 } from '@ant-design/plots';
import { each, findIndex } from '@antv/util';
import { MarketplaceDetails } from '../../../utils/StaticInfo';

const CompareReportChart = (props) => {
  const { marketPlaces, orderList } = props;
  const { InteractionAction, registerInteraction, registerAction } = G2;
  const [reportType, setReportType] = useState(props.reportType.toString().slice(0,1))
  const [data, setData] = useState([]);

  useEffect(() => {
    setReportType(props.reportType.toString().slice(0,1))
  }, [props])

  useEffect(() => {
    const tempArr = orderList?.map((order)=> {
      return {
        day: order.date.split("-").slice(-1)[0],
        date: order.date,
        orderCount: order.deliveredTotalOrder,
        returnedTotalOrder: order.returnedTotalOrder,
        returnedTotalPrice: order.returnedTotalPrice,
        turnover: order.deliveredTotalPrice,
        marketplace: "Trendyol",
      }
    });
    if (tempArr) setData(tempArr)
  }, [orderList]);
  

  G2.registerShape('point', 'custom-point', {
    draw(cfg, container) {
      const point = {
        x: cfg.x,
        y: cfg.y,
      };
      const group = container.addGroup();
      group.addShape('circle', {
        name: 'outer-point',
        attrs: {
          x: point.x,
          y: point.y,
          fill: cfg.color || 'red',
          opacity: 0.5,
          r: 6,
        },
      });
      group.addShape('circle', {
        name: 'inner-point',
        attrs: {
          x: point.x,
          y: point.y,
          fill: cfg.color || 'red',
          opacity: 1,
          r: 2,
        },
      });
      return group;
    },
  });

  class CustomMarkerAction extends InteractionAction {
    active() {
      const view = this.getView();
      const evt = this.context.event;

      if (evt.data) {
        // items: 数组对象，当前 tooltip 显示的每条内容
        const { items } = evt.data;
        const pointGeometries = view.geometries.filter((geom) => geom.type === 'point');
        each(pointGeometries, (pointGeometry) => {
          each(pointGeometry.elements, (pointElement, idx) => {
            const active = findIndex(items, (item) => item.data === pointElement.data) !== -1;
            const [point0, point1] = pointElement.shape.getChildren();

            if (active) {
              // outer-circle
              point0.animate(
                {
                  r: 10,
                  opacity: 0.2,
                },
                {
                  duration: 1800,
                  easing: 'easeLinear',
                  repeat: true,
                },
              ); // inner-circle

              point1.animate(
                {
                  r: 6,
                  opacity: 0.4,
                },
                {
                  duration: 800,
                  easing: 'easeLinear',
                  repeat: true,
                },
              );
            } else {
              this.resetElementState(pointElement);
            }
          });
        });
      }
    }

    reset() {
      const view = this.getView();
      const points = view.geometries.filter((geom) => geom.type === 'point');
      each(points, (point) => {
        each(point.elements, (pointElement) => {
          this.resetElementState(pointElement);
        });
      });
    }

    resetElementState(element) {
      const [point0, point1] = element.shape.getChildren();
      point0.stopAnimate();
      point1.stopAnimate();
      const { r, opacity } = point0.get('attrs');
      point0.attr({
        r,
        opacity,
      });
      const { r: r1, opacity: opacity1 } = point1.get('attrs');
      point1.attr({
        r: r1,
        opacity: opacity1,
      });
    }

    getView() {
      return this.context.view;
    }
  }

  registerAction('custom-marker-action', CustomMarkerAction);
  registerInteraction('custom-marker-interaction', {
    start: [
      {
        trigger: 'tooltip:show',
        action: 'custom-marker-action:active',
      },
    ],
    end: [
      {
        trigger: 'tooltip:hide',
        action: 'custom-marker-action:reset',
      },
    ],
  });
  const config = {
    data,
    xField: 'day',
    yField: reportType === '1' ? 'turnover' : reportType === '2' ? 'orderCount' : reportType === '3' ? 'orderCount' : 'returnedTotalPrice',
    yAxis: {
        tickCount: 10,
        // tickInterval: 2500,
    },
    label: {
      formatter: (value)=> reportType === '1' ? value.turnover.toFixed(2) + '₺' : reportType === '2' ? value.orderCount : reportType === '3' ? value.orderCount : value.returnedTotalPrice.toFixed(2) + '₺',
    },
    tooltip: {
        showMarkers: false,
        customContent: (title, data) => {
            const turnover = data[0]?.data.turnover;
            const returnedTotalPrice = data[0]?.data.returnedTotalPrice;
            const returnedTotalOrder = data[0]?.data.returnedTotalOrder;
            const date = data[0]?.data?.date;
            const orderCount = data[0]?.data?.orderCount; 
            return (
                `<div style="padding:10px 5px">
                    <p><b>${date}</b></p>
                    ${reportType === '4' ? 'İade Tutar': 'Ciro' }: ${reportType === '4' ? returnedTotalPrice?.toFixed(2) : turnover?.toFixed(2)} TL
                    </br>
                    ${reportType === '4' ? 'İade': '' } Adet: ${reportType === '4' ? returnedTotalOrder : orderCount}
                </div>`
            );
        },
    },
    seriesField: 'marketplace',
    colorField: 'marketplace',
    color: MarketplaceDetails.filter(el => el.id === marketPlaces[0])[0]?.color,
    point: {
      size: 5,
      shape: 'custom-point',
      style: {
        fill: 'white',
        stroke: MarketplaceDetails.filter(el => el.id === marketPlaces[0])[0]?.color,
        lineWidth: 2,
      },
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: 'red',
        },
      },
    },
    interactions: [
      {
        type: 'custom-marker-interaction',
      },
    ],
  };
  return <Line {...config} />;
}

export default CompareReportChart