import React from 'react'
import { Space } from 'antd'
import { FaFacebookF } from "react-icons/fa"

const LoginWithFacebook = () => {
  return (
    <div className='login-with-facebook-btn'>
      <Space>
        <FaFacebookF size={16} />
        <span>Facebook İle Giriş Yap</span>
      </Space>
    </div>
  )
}

export default LoginWithFacebook