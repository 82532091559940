import React, { useEffect, useState } from 'react'
import { Button, Card, Col, DatePicker, Divider, Row, Select } from 'antd'
import Information from '../../utils/Information'
import info from '../../utils/marketplaces.json'
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { showNotification } from '../../utils/showNotification';

const { RangePicker } = DatePicker;

const CreateRaport = (props) => {
  const [marketPlaceOptions, setMarketPlaceOptions] = useState([])
  const [marketPlace, setMarketPlace] = useState([])
  const [reportType, setReportType] = useState(1)
  const [startDate, setStartDate] = useState(moment().startOf('week').valueOf())
  const [endDate, setEndDate] = useState(moment().valueOf())

  const history = useHistory();



  useEffect(() => {
    const tempArr = []
    const marketPlaceTemp = []
    for (const [key, value] of Object.entries(info.marketplaces)) {
      const obj = {
        id: value,
        name: key
      }
      tempArr.push(obj)
      marketPlaceTemp.push(value)
    }
    setMarketPlaceOptions(tempArr)
  }, [])

  const handleMarketplaceSelect = (value) => {
    if (value.length > 3) {
      return
    } else {
      setMarketPlace(value)
      console.log('VALUE : ', value)
    }
  }

  const marketPlaceSelectOptions = marketPlaceOptions.map((res, index) => {
    return (
      <Select.Option key={index} value={res.id}>{res.name}</Select.Option>
    )
  })
  useEffect(() => {
    var divElem = document.getElementById("range-picker-container")
    var inputElements = divElem.querySelectorAll("input");
    inputElements[0].id = "range-start-date"
    inputElements[1].id = "range-end-date"

    const dateChange = (e, index) => {
      const value = e.target.value
      if (value.length === 2 && e.inputType !== "deleteContentBackward") {
        inputElements[index].value = value + "."
      } else if (value.length === 5 && e.inputType !== "deleteContentBackward") {
        inputElements[index].value = value + "."
      }
    }

    inputElements[0].addEventListener("input", (e) =>dateChange(e, 0))
    inputElements[1].addEventListener("input", (e) =>dateChange(e, 1))

    return () => {
      inputElements[0].removeEventListener("input", (e) =>dateChange(e, 0))
      inputElements[1].removeEventListener("input", (e) =>dateChange(e, 1))
    }
  }, [])

  const handleRangeChange = (value) => {
    props.rangeData(value)
    setStartDate(value[0].valueOf())
    setEndDate(value[1].valueOf())
  }

  const handleCompareReport = () => {
    if (!reportType || marketPlace.length === 0 ) {
      showNotification(
        "error",
        "Raporlar",
        "Pazaryeri ve rapor tipi eksiksiz girilmelidir!"
      );
      return
    }
    history.push(`/reports/compare?reportType=${reportType}1&marketplace=${marketPlace}&startDate=${startDate}&endDate=${endDate}`)
  }

  return (
    <Card className='card-shadow'>
      <Row gutter={[16, 16]}>
        <Col xs={12}>
          <span style={{ fontSize: 12, textDecoration: 'underline' }}>En fazla 3 pazar yeri karşılaştırabilirsiniz</span>&nbsp;&nbsp;&nbsp; <Information />
        </Col>
        <Col xs={12} align='right' id="range-picker-container">
          <RangePicker
            allowClear={false}
            bordered={false}
            showTime
            onChange={handleRangeChange}
            defaultValue={[moment(moment().startOf('week').format('YYYY-MM-DD HH:mm'), "YYYY-MM-DD HH:mm"), moment(moment().format('YYYY-MM-DD HH:mm'), "YYYY-MM-DD HH:mm")]}
            format={"DD.MM.YYYY HH:mm"} />
        </Col>
        <Col xs={5}>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            dropdownClassName='product-filter'
            maxTagCount={1}
            placeholder="Pazaryeri seç"
            onChange={handleMarketplaceSelect}
            value={marketPlace}>
            {marketPlaceSelectOptions}
          </Select>
        </Col>
        <Col xs={5}>
          <Select
            style={{ width: '100%' }}
            placeholder="Rapor Detay Seç"
            onChange={(value)=>setReportType(value)}>
            <Select.Option value={1}>Ciro Raporu</Select.Option>
            <Select.Option value={2}>Satış Raporu</Select.Option>
            <Select.Option value={3}>İade Raporu</Select.Option>
            <Select.Option value={4}>Kargo Raporu</Select.Option>
          </Select>
        </Col>
        <Col xs={4} align='center'>
          <Divider type="vertical" style={{ height: "100%" }} />
        </Col>
        <Col xs={5} align='right'>
          <Button block onClick={handleCompareReport}>Rapor Karşılaştır</Button>
        </Col>
        <Col xs={5} align='right'>
          <Button type='primary' block>Rapor İndir</Button>
        </Col>
      </Row>
    </Card>
  )
}

export default CreateRaport