import { protectedApi } from "../../services/axios";


export const getFaqSections = async ({cancelToken}) => {
  try {
    const response = await protectedApi.get(`management/faqsections`, { cancelToken: cancelToken });
    return response;
  } catch (error) {
    return error.response;
  }
}
export const getFaqs = async ({cancelToken}) => {
  try {
    const response = await protectedApi.get(`management/faqs`, { cancelToken: cancelToken });
    return response;
  } catch (error) {
    return error.response;
  }
}