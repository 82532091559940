import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../../redux/hizlihesap/actions";
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { Button, Card, Col, Divider, Form, Input, Modal, Row, Select, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { showNotification } from '../../../utils/showNotification';
import moment from 'moment'
import { createSupportComment, getCommentsBySupport, getSupport, updateSupport } from '../api'
import CommentCard from '../../../components/support/CommentCard';

const { Option } = Select;
const { confirm } = Modal;

const RequestContent = (props) => {
  const [commentForm, setCommentForm] = useState(false)
  const [taskData, setTaskData] = useState({})
  const [commentList, setCommentList] = useState([])

  const [form] = Form.useForm()
  const params = useParams()
  const history = useHistory()


  const getSupportInfo = async (id) => {
    const response = await getSupport(id)
    if(response.status === 403){
      showNotification('error', 'Yetkiniz yok!', 'Bu alana girmeye yetkiniz yok sizi destek sayfasına yönlendiriyoruz.')
      history.push('/support')
      return;
    }
    console.log('RESPONSE: ', response)
    setTaskData(response.data)
    document.getElementById('task-description').innerHTML = response.data.description
  }

  const handleGetComments = async () => {
    const response = await getCommentsBySupport(params.id);
    setCommentList(response.data.results);
  }

  useEffect(() => {
    getSupportInfo(params.id)
    handleGetComments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])


  const onFinish = async (values) => {
    props.setRedux({ loading: true })
    values.isAuthorized = false;
    values.support = Number(params.id);
    try {
      const response = await createSupportComment(values);
      if (response.status === 201) {
        handleGetComments()
        setCommentForm(false)
        form.resetFields()
        showNotification('success', 'Yorumunuz Gönderildi!', 'Yazmış olduğunuz yorum başarıyla gönderildi. En yakın zamanda destek ekiplerimiz size ulaşacak.')
      }
    } catch (error) {
      console.log(error)
    }
    props.setRedux({ loading: false })
  }

  const handleChangeStatus = (value, event) => {
    props.setRedux({ loading: true })
    confirm({
      content: `Talebinizin statüsünü '${event.children}' olarak güncellemek istediğinize emin misiniz?`,
      okText: "Evet",
      cancelText: "Hayır",
      async onOk() {
        showNotification('success', 'Güncellendi!', `Talep statünüz '${event.children}' olarak güncellenmiştir!`)
        handleUpdateSupportStatus(value)
        props.setRedux({ loading: false })
      },
      onCancel() {
        console.log('Cancel');
        props.setRedux({ loading: false })
      },
    });
  }

  const handleUpdateSupportStatus = async (value) => {
    const response = await updateSupport(params.id, { status: value })
    if (response.status === 200) {
      getSupportInfo(params.id)
    }

  }

  const toggleCommentForm = () => {
    setCommentForm(!commentForm)
  }

  const comments = commentList?.map((data, index) => {
    return (
      <Col xs={24} key={index}>
        <CommentCard data={data} />
      </Col>
    )
  })

  const commentFormStyle = {
    border: commentForm ? '1px solid #ff5c09' : '0px solid transparent',
    borderRadius: 16,
    margin: '8px 0px',
    padding: commentForm ? 16 : 0,
    minHeight: 0,
    height: commentForm ? 230 : 0,
    maxHeight: 230,
    overflow: 'hidden',
    transition: 'all 0.5s'
  }
  return (
    <Card>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <Row>
            <Col xs={24} align='left'>
              <Row gutter={[24, 24]}>
                <Col xs={12}>
                  <span className='page-title-style'>Kayıt NO - {taskData.id}</span>
                </Col>
                <Col xs={12} align='right'>
                  <Button size='large' onClick={() => history.push('/support')}>
                    Geri
                  </Button>
                </Col>
                <Col xs={24}>
                  <span style={{ fontSize: 18, fontWeight: 600 }}> KONU - {taskData.topic}</span>
                </Col>
                <Col xs={24}>
                  <Divider />
                  <span><b>Bildirim Durumu: </b> {
                    taskData.status === 1 ? "Yeni" :
                      taskData.status === 2 ? "İşlemde" :
                        taskData.status === 3 ? "Çözüldü" :
                          taskData.status === 4 ? "Tamamlandı" :
                            taskData.status === 5 ? "İptal" : null}
                  </span>&nbsp;&nbsp;&nbsp;
                  <Select size='large' allowClear style={{ width: '150px' }} value={null} disabled={taskData.status === 5} onChange={handleChangeStatus} placeholder='Statü Güncelle'>
                    <Option value={3}>Çözüldü</Option>
                    <Option value={4}>Tamamlandı</Option>
                    <Option value={5}>İptal</Option>
                  </Select>
                </Col>
                <Col xs={12} lg={6}>
                  <span><b>Kayıdı Açan: </b></span><br />
                  <span>{taskData.createdBy?.firstName + " " + taskData.createdBy?.lastName}</span>
                </Col>
                <Col xs={12} lg={6}>
                  <span><b>Kayıt Açılış Tarihi: </b></span><br />
                  <span>{moment(taskData.createdAt).format('DD.MM.YYYY HH:mm')}</span>
                </Col>
                <Col xs={12} lg={6}>
                  <span><b>Son Güncelleme Tarihi: </b></span><br />
                  <span>{moment(taskData.updatedAt).format('DD.MM.YYYY HH:mm')}</span>
                </Col>
                <Col xs={12} lg={6}>
                  <span><b>Öncelik: </b></span><br />
                  <span>{taskData.priority === 1 ? 'Yüksek' : taskData.priority === 2 ? 'Orta' : taskData.priority === 3 ? 'Düşük' : null}</span>
                </Col>
                <Col xs={24}>
                  <span><b>Açıklama:</b></span>
                  <div id={'task-description'}></div>
                  <Divider />
                  <Button type='primary' icon={<EditOutlined />} onClick={toggleCommentForm}>Yorum yaz</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <div className='support-comment-form'>
            <Form style={commentFormStyle}
              name="support-comment-form"
              initialValues={{ category: 2 }}
              onFinish={onFinish}
              form={form}
              // onFinishFailed={onFinishFailed}
              layout='vertical'
              autoComplete="off">
              <Row gutter={[24, 0]}>
                <Col xs={{ span: 24, offset: 0 }}>
                  <Form.Item label="Açıklama" name="description" rules={[{ required: true, message: 'Lütfen açıklama yazın!' }]}>
                    <Input.TextArea rows={4} placeholder="Lütfen destek talebinizi ayrıntılı olarak açıklayınız" maxLength={250} autoSize={{ minRows: 4, maxRows: 4 }} />
                  </Form.Item>
                </Col>
                <Col xs={24} align='right'>
                  <Space>
                    <Form.Item>
                      <Button size='large' type="primary" htmlType='submit'>
                        Gönder
                      </Button>
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
        {
          comments
        }
      </Row>
    </Card>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(RequestContent));