import React from "react";
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import "../src/assets/css/styles.less"
import { ConfigProvider } from 'antd';
import trTR from 'antd/lib/locale/tr_TR';
import ScrollToTop from "./routes/ScrollToTop";
import Main from "./Main";

const App = () => (
  <Provider store={configureStore()}>
    <BrowserRouter>
      <ConfigProvider locale={trTR}>
        <ScrollToTop>
          <Main />
        </ScrollToTop>
      </ConfigProvider>
    </BrowserRouter>
  </Provider>
);
export default App;
