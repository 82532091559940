import { protectedApi } from '../../services/axios'

export const getUser = async (id) => {
  try {
    const response = await protectedApi.get(`management/user/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const uploadFiles = async (payload) => {
  try {
    const response = await protectedApi.post(`management/files`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}


export const getMyCompanyUsers = async (company_id) => {
  try {
    const response = await protectedApi.get(`management/profiles?company__id=${company_id}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const createTask = async (payload) => {
  try {
    const response = await protectedApi.post(`management/tasks`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const getMyCompanyTasks = async ({company_id, dateGte, dateLte, ordering, search}) => {
  if(!ordering) {
    ordering = "dueDate"
  }
  try {
    const response = await protectedApi.get(`management/tasks?ordering=${ordering}&search=${search}&createdBy__profile__company__id=${company_id}&repeatDate__gte=${dateGte}&repeatDate__lte=${dateLte}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const updateTask = async (id, payload) => {
  try {
    const response = await protectedApi.put(`management/task/${id}`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const updateTaskGroup = async (payload) => {
  try {
    const response = await protectedApi.post(`management/updategrouptask`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}