import { publicApi } from "../../services/axios";


// Reset Password API
export const resetPassword = async (payload) => {
  try {
    const response = await publicApi.post(`/pass/reset_confirm/${payload.uid}/${payload.token}/`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const createPassword = async (payload) => {
  try {
    const response = await publicApi.post(`api/password_reset/confirm/`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}