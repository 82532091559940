import React, { useEffect } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../../redux/hizlihesap/actions";
import { Card, Col, Divider, Row, Switch } from 'antd';
import { updateComCategory, updateComChannel } from '../api';
import { showNotification } from '../../../utils/showNotification';

const EmailPreferences = (props) => {

  useEffect(() => {
    if (Object.keys(props.user).length === 0) return
  }, [props.user])

  const onChange = async (name, checked) => {
    props.setRedux({ loading: true })
    try {
      const response = await updateComCategory(props.user.profile.comCategories.id, { [name]: checked })
      if (response.status === 200) {
        props.updateUser(props.user.id)
        showNotification('success', 'Güncellendi!', 'İletişim tercihleriniz başarıyla güncellendi!')
      }

    } catch (error) {
      console.log('error: ', error)
      showNotification('error', 'HATA!', 'Beklenmeyen bir hata gerçekleşti!')
    }
    props.setRedux({ loading: false })
  }

  const changeCancel = async (checked) => {
    props.setRedux({ loading: true })
    try {
      const response = await updateComChannel(props.user.profile.comChannels.id, { isCancel: checked })
      if (response.status === 200) {
        props.updateUser(props.user.id)
        showNotification('success', 'Güncellendi!', 'İletişim tercihleriniz başarıyla güncellendi!')
      }

    } catch (error) {
      console.log('error: ', error)
      showNotification('error', 'HATA!', 'Beklenmeyen bir hata gerçekleşti!')
    }
    props.setRedux({ loading: false })
  }


  return (
    <Card>
      <Row>
        <Col xs={24} lg={{ span: 22, offset: 1 }}>
          <Row gutter={[24, 24]}>
            <Col xs={24} align='left'>
              <span className='page-title-style'>E-Posta Tercihleri</span>
            </Col>
            <Col xs={24}>
              <span>Hızlı hesap üzerinde sizlerin <b>sorularınızı cevaplamak</b> ve <b>farklı süreçlerinizde yardımcı olmak</b> amacıyla size e-posta aracılığıyla ulaşmak istiyoruz.</span>
            </Col>
            <Col xs={24} align='right'>
              <Row gutter={[0,8]} className="email-prefs">
               
                <Col className="pref-item">
                <span><b>Yeni Sipariş</b></span>
                <Switch checked={props.user?.profile?.comCategories.newOrder} onChange={(checked)=>onChange('newOrder', checked)} />
                </Col>
                <Col className="pref-divider" ><Divider type='vertical'/></Col>
                <Col className="pref-item">
                <span><b>İptal Talebi</b></span>
                <Switch checked={props.user?.profile?.comChannels.isCancel} onChange={changeCancel} />
                </Col>
                <Col className="pref-divider" ><Divider type='vertical'/></Col>
                <Col className="pref-item">
                <span><b>İade Talebi</b></span>
                <Switch checked={props.user?.profile?.comCategories.newReturn} onChange={(checked)=>onChange('newReturn', checked)} />
                </Col>
                <Col className="pref-divider" ><Divider type='vertical'/></Col>
                <Col className="pref-item">
                <span><b>Haftalık Finans Özeti</b></span>
                <Switch checked={props.user?.profile?.comCategories.weeklyFinance} onChange={(checked)=>onChange('weeklyFinance', checked)} />
                </Col>
                <Col className="pref-divider" ><Divider type='vertical'/></Col>
                <Col className="pref-item">
                  <span><b>Haftalık Stok Özeti</b></span>
                  <Switch checked={props.user?.profile?.comCategories.weeklyStock} onChange={(checked)=>onChange('weeklyStock', checked)} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(EmailPreferences));