import React from 'react'
import { Space } from 'antd'
import { FcGoogle } from "react-icons/fc"

const LoginWithGoogle = () => {
  return (
    <div className='login-with-google-btn'>
      <Space>
        <FcGoogle size={18} />
        <span>Google İle Giriş Yap</span>
      </Space>
    </div>
  )
}

export default LoginWithGoogle