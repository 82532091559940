import React, { useEffect, useRef, useState } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../../redux/hizlihesap/actions";
import { Button, Card, Col, Form, Input, Row, Select, Space, Upload } from 'antd';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { UploadOutlined } from '@ant-design/icons';
import { showNotification } from '../../../utils/showNotification';
import { createSupport, getUser, uploadFiles } from '../api';
import JoditEditor from 'jodit-react';
import { getApiBaseClear } from '../../../services/config';

const NewRequestContent = (props) => {

  const editor = useRef(null)
  const [descriptionContent, setDescriptionContent] = useState('')
  const [form] = Form.useForm()
  const history = useHistory()

  useEffect(() => {
    const handleGetUser = async (user_id) => {
      const response = await getUser(user_id)
      form.setFieldsValue({
        first_name: response.data.first_name,
        last_name: response.data.last_name,
        email: response.data.email,
      })
    }

    const user_id = JSON.parse(window.localStorage.getItem('user')).id
    handleGetUser(user_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFinish = async (values) => {
    props.setRedux({ loading: true })
    console.log('VALUES: ', values)
    if(values.files) {
      const orjFiles = values.files.map(data => data.originFileObj)
      values.files = orjFiles

    const fileUrls = []
    values.files.map(async (file) => {
      const fData = new FormData()
      fData.append("file", file)
      const res = await uploadFiles(fData);
      const apiBaseClear = getApiBaseClear();
      const fileUrl = apiBaseClear + res.data.file;
      fileUrls.push(fileUrl)
      return null;
    })
    values.files = fileUrls
    }

    values.status = 1
    
    setTimeout(async () => {
      try {
        const response = await createSupport(values)
        console.log('RESPONSE: ', response)
        if (response.status === 201) {
          history.push('/support')
          showNotification('success', 'Talebiniz alındı!', 'Talebiniz alındı, en kısa zamanda destek ekiplerimiz çözüm için sizinle iletişime geçecek!')
          props.setRedux({ loading: false })
        }
      } catch (error) {

      }
    }, 1000)
  }

  const onChangeFiles = (info) => {
    if (info.file.status === 'uploading') {
      info.file.status = 'done';
    }
    if (info.file.status === 'done') {
    }
  }
  const beforeUploadFiles = (file) => true

  // Bu fonksiyonu kaldırmayın hata verir!
  const customRequest = (opts) => { }
  // antd form da files alanı içerisine elimizdeki filelist'i yazan fonksiyon.
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const editorConfig = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    // enableDragAndDropFileToEditor: true,
    placeholder: 'Lütfen uzun bir açıklama metini yazınız',
    buttons: "bold,italic,underline,strikethrough,ul,ol,font,fontsize,paragraph,superscript,subscript,spellcheck,copyformat,cut,copy,paste,selectall"
  }
  return (
    <Card>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <Form
            name="update-password-form"
            initialValues={{ priority: 2 }}
            onFinish={onFinish}
            form={form}
            // onFinishFailed={onFinishFailed}
            layout='vertical'
            autoComplete="off">
            <Row>
              <Col xs={12} align='left'>
                <p className='page-title-style'>Yeni Talep Oluştur</p>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={{ span: 24, offset: 0 }} lg={8}>
                <Form.Item label="Ad" name="first_name" rules={[{ required: false, message: '' }]}>
                  <Input size='large' placeholder='Ad' disabled={true} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={8}>
                <Form.Item label="Soyad" name="last_name" rules={[{ required: false, message: '' }]}>
                  <Input size='large' placeholder='Soyad' disabled={true} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={8}>
                <Form.Item label="E-Posta" name="email" rules={[{ required: false, message: '' }]}>
                  <Input size='large' placeholder='E-Posta' disabled={true} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Kategori" name="category" rules={[{ required: true, message: 'Lütfen bir kategori seçiniz' }]}>
                  <Select size='large' allowClear style={{ width: '100%' }} placeholder="Lütfen kategori seçin">
                    <Select.Option value={1}>Genel</Select.Option>
                    <Select.Option value={2}>Pazaryeri Entegrasyon</Select.Option>
                    <Select.Option value={3}>Ödeme Takvimi</Select.Option>
                    <Select.Option value={4}>Raporlar</Select.Option>
                    <Select.Option value={5}>Ürün-Stok Yönetimi</Select.Option>
                    <Select.Option value={6}>Abonelik ve Ödeme</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Öncelik" name="priority" rules={[{ required: true, message: 'Lütfen bir öncelik seçiniz' }]}>
                  <Select size='large' allowClear style={{ width: '100%' }} placeholder="Lütfen öncelik seçin">
                    <Select.Option value={1}>Yüksek</Select.Option>
                    <Select.Option value={2}>Orta</Select.Option>
                    <Select.Option value={3}>Düşük</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }}>
                <Form.Item label="Konu" name="topic" rules={[{ required: true, message: 'Lütfen konu yazın!' }]}>
                  <Input size='large' placeholder='Lütfen destek talebinizi kısaca açıklayınız' />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }}>
                <Form.Item label="Açıklama" name="description" rules={[{ required: false, message: 'Lütfen açıklama yazın!' }]}>
                  <JoditEditor
                    ref={editor}
                    value={descriptionContent}
                    config={editorConfig}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={setDescriptionContent} // preferred to use only this option to update the content for performance reasons
                    onChange={newContent => { }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} style={{ marginBottom: 20, marginTop: 10 }}>
                <Row>
                  <Col xs={24}>
                    <span style={{ fontSize: 12 }}><b>İzin verilen dosya türleri:</b> .jpg, .gif, .jpeg, .png, .doc, .xls, .pdf, .txt, .docx, .xlsx, .tif</span>
                  </Col>
                  <Col>
                    <Form.Item label="" name="files" valuePropName='fileList' getValueFromEvent={normFile} rules={[{ required: false, message: '' }]}>
                      <Upload onChange={onChangeFiles} beforeUpload={beforeUploadFiles} customRequest={customRequest} style={{ whiteSpace: 'nowrap' }}>
                        <Button icon={<UploadOutlined />}>Yükle</Button>&nbsp;&nbsp;&nbsp;
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} align='right'>
                <Space>
                  <Form.Item>
                    <Button size='large' onClick={() => history.push('/support')}>
                      İptal
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button size='large' type="primary" htmlType='submit'>
                      Gönder
                    </Button>
                  </Form.Item>
                </Space>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Card>
  )
}


const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(NewRequestContent));