
import React from 'react'
import { Card, Col, Row } from 'antd'
import SaleOrderQuantityChart from './SaleOrderQuantityChart'

const SaleOrderQuantityCard = (props) => {
  const { data } = props;
  
  return (
    <Card className='border bg-white height-100 card-shadow'>
      <Row gutter={[24,24]}>
        <Col xs={24}>
          <span className='page-title-style'>Ciro / Sipariş Adedi Grafiği</span>
        </Col>
        <Col xs={24}>
          <Row>
            <Col xs={12}>
              <p className='font-s18'><b>Ciro: </b> <span style={{color: '#ff5c09'}}>{ data?.totalPrice?.toFixed(2) } ₺</span></p>
            </Col>
            <Col xs={12}>
              <p className='font-s18'><b>Satış Adedi: </b> <span style={{color: 'rgba(82, 196, 26, 1)'}}>{ data.orderCount }</span></p>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <SaleOrderQuantityChart data={data?.dayByDayOrderList} />
        </Col>
      </Row>
    </Card>
  )
}

export default SaleOrderQuantityCard