import { Button, Col, Image, Row } from "antd";
import React from "react";
import { MarketplaceDetails } from "../../../utils/StaticInfo";
const ComparedMarketplaces = (props) => {
  const { marketplace } = props;
  
  return (
    <Row align="bottom">
      <Col lg={20}>
        <Row>
          {marketplace.map((id) => {
            let src = MarketplaceDetails.filter(el => el.id === id)[0]?.img;
            let color = MarketplaceDetails.filter(el => el.id === id)[0]?.color;
            return (
              <Col key={id} xs={3} style={{ border: `2px solid ${color}`, borderRadius: 10, marginRight: 5, overflow: "hidden", display: "flex", alignItems: "center" }}>
                {<Image preview={false} src={src} alt="" />}
              </Col>
            );
          })}
        </Row>
      </Col>
      <Col lg={4}>
        <Button style={{background: "#00c609"}} ghost block>
          Rapor Kaydet
        </Button>
      </Col>
    </Row>
  );
};
export default ComparedMarketplaces;
