import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/hizlihesap/actions";
import { Button, Card, Col, Input, Pagination, Row, Select, Table } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { getSupportsByCompany, getUser } from './api';
import { showNotification } from '../../utils/showNotification';
import moment from 'moment';
import { isHaveAccess } from '../../utils/Permission';
import Information from '../../utils/Information';


const SupportContent = (props) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([]);
  const [demandCount, setDemandCount] = useState(0)
  const [paginationInfo, setPaginationInfo] = useState({ offset: 1, limit: 10 })
  const [companyId, setCompanyId] = useState(null);
  const [statuses, setStatuses] = useState([1,2,3,4,5]);
  const [filterTopic, setFilterTopic] = useState("");
  
  
  const history = useHistory();


  useEffect(() => {
    const user_id = JSON.parse(window.localStorage.getItem('user')).id
    const access = async () => {
      const perms = await isHaveAccess(user_id, "support")
      if (!perms) {
        history.push('/')
        showNotification('error', 'Yetkiniz Yok!', 'Gitmek istediğiniz sayfaya yetkiniz bulunmamaktadır!')
        return
      }
    }
    access()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  const handleTablePagination = (offset, limit) => {
    // Bütün veriyi direkt çekmekten yerine her tabloda gösterilen kadar veri çekmek için
    // tablo pagination'ın onchange fonksiyonunu kullanacağız.
    setPaginationInfo(prevState => ({
      ...prevState,
      offset: offset,
      limit: limit
    }))
  }


  const handleGetSupports = async ({company_id, status, topicFilter,offset, limit}) => {
    const response = await getSupportsByCompany({company_id: company_id, status: status, topicFilter, offset: offset, limit: limit})
    const tempArr = response.data.results.map((data, index) => {
      return {
        key: index,
        id: data.id,
        entryNo: data.id,
        status: data.status === 1 ? "Yeni" :
          data.status === 2 ? "İşlemde" :
            data.status === 3 ? "Çözüldü" :
              data.status === 4 ? "Tamamlandı" :
                data.status === 5 ? "İptal" : null,
        statusNo: data.status,
        category: data.category === 1 ? "Genel" :
          data.category === 2 ? "Pazaryeri Entegrasyon" :
            data.category === 3 ? "Ödeme Takvimi" :
              data.category === 4 ? "Raporlar" :
                data.category === 5 ? "Ürün-Stok Yönetimi" :
                  data.category === 6 ? "Abonelik ve Ödeme" : null,
        topic: data.topic,
        priority: data.priority === 1 ? "Yüksek" : data.priority === 2 ? "Orta" : data.priority === 3 ? "Düşük" : null,
        createdBy: data.createdBy.firstName + " " + data.createdBy.lastName,
        updateAt: moment(data.updateAt).format('DD.MM.YYYY HH:mm'),
      }
    })
    setData(tempArr)
    setDemandCount(response.data.count)
    setLoading(false)
  }

  useEffect(() => {
    const handleGetUser = async (user_id) => {
      const response = await getUser(user_id)
      setCompanyId(response.data.profile.company)
    }

    const user_id = JSON.parse(window.localStorage.getItem('user')).id
    handleGetUser(user_id)
  }, [])

  useEffect(() => {
    if(companyId === null) return
    setLoading(true)
    let statusText = ""
    if (statuses.length !== 0) {
      statusText = statuses.join(",");
    }
    handleGetSupports({company_id: companyId, status: statusText, offset: paginationInfo.offset, limit: paginationInfo.limit, topicFilter: filterTopic})
  }, [companyId, statuses, paginationInfo, filterTopic])

  const handleSetStatus = (checked, id) => {
    const temp = [...statuses];
    if(checked){
      temp.push(id)
      setStatuses(temp)
    }else{
      const popOut =  temp.filter(data => data !== id)
      setStatuses(popOut)
    }
  }

  const handleNewRequest = () => {
    if(companyId === null){
      showNotification('error', 'Firma bilgileri eksik!', 'Lütfen öncelikle firma bilgileriniz girin!')
      return
    }
    history.push('/support/new-request')
  }

  const columns = [
    {
      title: 'Kayıt No',
      dataIndex: 'entryNo',
      key: 'entryNo',
    },
    {
      title: 'Durum',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
       <div className={`status status-${record.statusNo}`}>
        {text}
       </div>
      )
    },  
    {
      title: 'Kategori',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Konu',
      dataIndex: 'topic',
      key: 'topic',
    },
    {
      title: 'Öncelik',
      dataIndex: 'priority',
      key: 'priority',
    },
    {
      title: 'Kaydı Açan',
      dataIndex: 'createdBy',
      key: 'createdBy',
    },
    {
      title: 'Güncelleme Tarihi',
      dataIndex: 'updateAt',
      key: 'updatedAt',
    },
    {
      title: 'İşlem',
      key: 'action',
      render: (text, record) => (
        <Button type='primary' size='small' onClick={() => history.push(`/support/request/${record.id}`)}>Detay</Button>
      ),
    },
  ];
  return (
    <Card>
      <Row gutter={[24, 24]}>
        <Col xs={24} lg={12} className='support-status-list'>
          <Row gutter={[8,8]} >
            <Col>
              <label className="order-status-container">Yeni
                <input type="checkbox" checked={statuses.includes(1) ? 'checked' : ""} onChange={(event) => handleSetStatus(event.target.checked, 1)} />
                <span className="checkmark checkmark-green"></span>
              </label>
            </Col>
            <Col>
              <label className="order-status-container">İşlemde
                <input type="checkbox" checked={statuses.includes(2) ? 'checked' : ""} onChange={(event) => handleSetStatus(event.target.checked, 2)} />
                <span className="checkmark checkmark-yellow"></span>
              </label>
            </Col>
            <Col>
              <label className="order-status-container">Çözüldü
                <input type="checkbox" checked={statuses.includes(3) ? 'checked' : ""} onChange={(event) => handleSetStatus(event.target.checked, 3)} />
                <span className="checkmark checkmark-blue"></span>
              </label>
            </Col>
            <Col>
              <label className="order-status-container">Tamamlandı
                <input type="checkbox" checked={statuses.includes(4) ? 'checked' : ""} onChange={(event) => handleSetStatus(event.target.checked, 4)} />
                <span className="checkmark checkmark-orange"></span>
              </label>
            </Col>
            <Col>
              <label className="order-status-container">İptal
                <input type="checkbox" checked={statuses.includes(5) ? 'checked' : ""} onChange={(event) => handleSetStatus(event.target.checked, 5)} />
                <span className="checkmark checkmark-black"></span>
              </label>
            </Col>
            <Col xs={8} lg={3}>
              <Information />
            </Col>
          </Row>
          </Col>
          <Col xs={24}>
          <Row gutter={[16, 16]} align='bottom'>
            <Col xs={24} lg={12} align=''>
              <Input size='large' placeholder='Konu ile ara' onChange={(e)=>setFilterTopic(e.target.value)} suffix={
                <Button style={{ position: "absolute", right: 0 }} type="primary" size='large'>
                  Arama Yap
                </Button>
              }/>
            </Col>
            <Col xs={24} lg={6} align=''>
              <Select size='large' placeholder="Durum" style={{width: "100%"}} onChange={(value)=>setStatuses([parseInt(value)])}>
                <Select.Option value="1">Yeni</Select.Option>
                <Select.Option value="2">İşlemde</Select.Option>
                <Select.Option value="3">Çözüldü</Select.Option>
                <Select.Option value="4">Tamamlandı</Select.Option>
                <Select.Option value="5">İptal</Select.Option>
              </Select>
            </Col>
            <Col xs={24} lg={6} align=''>
              <Button size='large' type='primary' style={{width: "100%"}} onClick={handleNewRequest}>Yeni Talep Oluştur</Button>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Table className='small-table' columns={columns} pagination={false} dataSource={data} rowClassName={'cursor-p'}
            size='small' loading={{ spinning: loading, indicator: <LoadingOutlined /> }}/>
        </Col>
        <Col xs={12} align='left'>
          Toplam: {demandCount} Kayıt
        </Col>
        <Col xs={12} align='right'>
          <Pagination size='small' style={{ fontSize: 12 }} onChange={handleTablePagination} current={paginationInfo.offset} defaultCurrent={1} total={demandCount} />
        </Col>
      </Row>
    </Card>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(SupportContent));