import React from 'react'
import { Col, Divider, Image, Row } from 'antd'
import { FcOnlineSupport } from 'react-icons/fc'
import moment from 'moment'
import { getApiBaseClear } from '../../services/config'

const CommentCard = (props) => {
  return (
    <Row gutter={[24, 24]}>
      <Col xs={24}>
        <div style={{ border: '1px solid #84b300', borderRadius: 16, padding: 16 }}>
          <Row style={{ backgroundColor: '#f6f8fa', padding: 8, marginTop: 8, marginBottom: 8, borderRadius: 8 }} align='middle'>
            <Col xs={3}>
              {props.data.isAuthorized ?
                <FcOnlineSupport size={26} />
                :
                <Image width={35} style={{ borderRadius: '50%' }} preview={false} src={getApiBaseClear() + props.data.createdBy.photo} />
              }
            </Col>
            <Col xs={6}>
              <span><b>{props.data.isAuthorized ? 'Yetkili: ' : 'Kullanıcı: '} </b> {props.data.createdBy.firstName + " " + props.data.createdBy.lastName}</span>
            </Col>
            <Col xs={15} align='right'>
              <span>{moment(props.data.createdAt).format('DD.MM.YYYY HH:mm')}</span>
            </Col>
          </Row>
          <Row className='my-20'>
            <Col xs={24}>
              <p>
                {props.data.description}
              </p>
            </Col>
            {props.data.isAuthorized &&
              <>
                <Col xs={24}>
                  <Divider />
                </Col>
                <Col xs={24}>
                  İyi Çalışmalar,
                  Yetkili ad
                  Yetkili seviyesi
                  ...
                </Col>
              </>
            }
          </Row>
        </div>

      </Col>
    </Row>
  )
}

export default CommentCard