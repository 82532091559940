import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../../redux/hizlihesap/actions";
import { Button, Card, Col, Form, Image, Input, Row, Select, Upload } from "antd";
import {
  createCompany,
  getBossesUsers,
  getCompany,
  getRegions,
  getUser,
  updateCompany,
  updateProfile,
} from "../api";
import { UserOutlined } from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import tempImg from "../../../assets/images/icons/user.svg";
import { showNotification } from "../../../utils/showNotification";
import ImgCrop from "antd-img-crop";

const CompanyContent = (props) => {
  const [isEditable, setIsEditable] = useState(false);
  const [user, setUser] = useState({});
  const [companyLogo, setCompanyLogo] = useState(null);

  const [form] = Form.useForm();

  const [cities, setCities] = useState([]);

  const handleButton = () => {
    if (!user.profile.isBoss) {
      showNotification(
        "error",
        "Yetkiniz yok!",
        "Bu alanda işlem yapmak için yetkiniz bulunmamakta!"
      );
      return;
    }
    if (isEditable) {
      form.submit();
    } else {
      setIsEditable(true);
    }
  };

  const onFinish = async (values) => {
    if (!user.profile.isBoss) {
      showNotification(
        "error",
        "Yetkiniz yok!",
        "Bu alanda işlem yapmak için yetkiniz bulunmamakta!"
      );
      return;
    }
    props.setRedux({ loading: true });
    if (user.profile.company === null) {
      handleCreateCompany(user, values);
    } else {
      handleUpdateCompany(user, values);
    }
    setIsEditable(false);
    props.setRedux({ loading: false });
  };

  const handleCreateCompany = async (user, values) => {
    const response = await createCompany(values);
    if (response.status === 201) {
      try {
        const res = await updateProfile(user.id, { company: response.data.id });
        if (res.status === 200) {
          const users = await getBossesUsers(user.id);
          if (users.status === 200) {
            users.data.results.map(async (data) => {
              const updProfile = await updateProfile(data.user.id, {
                company: response.data.id,
              });
              if (updProfile.status === 200) {
                showNotification(
                  "success",
                  "Oluşturuldu!",
                  "Firma bilgileriniz başarıyla oluşturulmuştur!"
                );
              }
            });
          }
        }
      } catch (error) {
        showNotification("error", "Upss!", "Beklenmedik bir hata gerçekleşti!");
      }
    }
  };
  const handleUpdateCompany = async (user, values) => {
    const response = await updateCompany(user.profile.company, values);
    if (response.status === 200) {
      showNotification(
        "success",
        "Güncellendi!",
        "Firma bilgileriniz başarıyla güncellenmiştir!"
      );
    }
  };

  const handleGetUser = async (id) => {
    try {
      const response = await getUser(id);
      if (response.status === 200) {
        setUser(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUsersCompany = async (id) => {
    props.setRedux({ loading: true });
    const response = await getCompany(id);
    setCompanyLogo(response.data.logo);
    if (response.status === 200) {
      form.setFieldsValue({
        shortName: response.data.shortName,
        fullName: response.data.fullName,
        phone: response.data.phone !== null ? response.data.phone : undefined,
        address: response.data.address,
        area: response.data.area,
        tax: response.data.tax,
        taxNo: response.data.taxNo,
        country: response.data.country?.name,
        region: response.data.region?.name
      });
    }
    props.setRedux({ loading: false });
  };

  const uploadImage = async (info) => {
    return;
    // ANTD 4.20.0 sürümü ile birlikte gelen özellikler sebebiyle onChange üzerinden
    // yapılan geliştirmeler image-crop işlemi yapmamıza müsade etmiyordu.
    // Bu sorunu gidermek adına customRequest fonksiyonumuzda direkt servise giderek sorunu çözdük.
  };
  const beforeUpload = (file) => {
    return true;
  };
  const customRequest = async (info) => {
    console.log("OPTIONS: ", info);
    if (!info.file.type.includes("image")) {
      showNotification(
        "error",
        "Geçersiz Dosya!",
        "Lütfen yalnızca fotoğraf yükleyin!"
      );
      return;
    }
    props.setRedux({ loading: true });
    const fData = new FormData();
    fData.append("logo", info.file);
    try {
      const response = await updateCompany(user.profile.company, fData);
      if (response.status === 200) {
        showNotification(
          "success",
          "Güncellendi!",
          "Profil fotoğrafınız başarıyla güncellendi!"
        );
      }
    } catch (error) {
      console.log("error: ", error);
      showNotification("error", "HATA!", "Beklenmeyen bir hata gerçekleşti!");
    }
    props.setRedux({ loading: false });
  };

  useEffect(() => {
    const user_ = JSON.parse(window.localStorage.getItem("user"));
    handleGetUser(user_.id);
  }, []);

  useEffect(() => {
    if (user.email) {
      if (user.profile.company === null) return;
      getUsersCompany(user.profile.company);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleCountryChange = (id) => {
    const handleGetCities = async () => {
      const response = await getRegions(id);
      const tempArr = response.data.results.map((city)=>{
        return {id: city.id, name: city.name};
      });
      if (tempArr) setCities(tempArr);
    };
    handleGetCities();
  };

  const citySelectOptions = cities.map((city)=>{
    return <Select.Option key={city.id} value={city.id} >{city.name}</Select.Option>
  });


  return (
    <Card>
      <Row>
        <Col xs={24} lg={{ span: 24, offset: 0 }}>
          <Form
            name="profile-form"
            form={form}
            initialValues={{}}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            layout="vertical"
            autoComplete="off"
          >
            <Row>
              <Col xs={12} lg={24} align="left">
                <span className="page-title-style">Firma Bilgileri</span>
              </Col>
              <Col xs={24}>
                <Image
                  className={isEditable && "cursor-p"}
                  style={{ borderRadius: "50%" }}
                  onClick={() =>
                    isEditable &&
                    document.getElementById("upload-image").click()
                  }
                  preview={false}
                  src={companyLogo === null ? tempImg : companyLogo}
                  width={150}
                />
                <div style={{ display: "none" }}>
                  <ImgCrop
                    rotate
                    zoom
                    shape={"round"}
                    modalTitle={"Fotoğrafı Düzenle"}
                    modalOk={"Tamam"}
                    modalCancel={"İptal"}
                  >
                    <Upload
                      id="upload-image"
                      onChange={uploadImage}
                      beforeUpload={beforeUpload}
                      customRequest={customRequest}
                    >
                      FOTOĞRAF YÜKLE
                    </Upload>
                  </ImgCrop>
                </div>
              </Col>
            </Row>
            <Row gutter={[24, 0]} align="bottom">
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item
                  label="Kısa Ünvan"
                  name="shortName"
                  rules={[
                    {
                      required: true,
                      message: "Lütfen kısa ünvanınızı giriniz!",
                    },
                  ]}
                >
                  <Input
                    suffix={<UserOutlined />}
                    size="large"
                    placeholder="Kısa Ünvan"
                    disabled={!isEditable}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item
                  label="Ticari Ünvan"
                  name="fullName"
                  rules={[
                    {
                      required: true,
                      message: "Lütfen uzun ünvanınızı giriniz!",
                    },
                  ]}
                >
                  <Input
                    suffix={<UserOutlined />}
                    size="large"
                    placeholder="Uzun Ünvan"
                    disabled={!isEditable}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Sektor" name="area">
                  <Input
                    suffix={<UserOutlined />}
                    disabled={!isEditable}
                    size="large"
                    placeholder="Sektor"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item
                  label="Telefon"
                  name="phone"
                  rules={[
                    { required: false, message: "Lütfen telefon giriniz!" },
                  ]}
                >
                  <PhoneInput
                    country={"tr"}
                    regions={["europe"]}
                    masks={{ tr: "(...) ... .. .." }}
                    inputClass={"phone-input"}
                    buttonClass={"phone-input-flag"}
                    disabled={!isEditable}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={24}>
                <Form.Item label="Adres" name="address">
                  <Input
                    suffix={<UserOutlined />}
                    disabled={!isEditable}
                    size="large"
                    placeholder="Adres"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Ülke" name="country">
                  <Select placeholder="Ülke" size="large" disabled={!isEditable} onChange={handleCountryChange}>
                    <Select.Option  value="9">Türkiye</Select.Option>
                  </Select>
                  
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Şehir" name="region">
                  <Select placeholder="Şehir" size="large" disabled={!isEditable}>
                    {citySelectOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Vergi Dairesi" name="tax">
                  <Input
                    suffix={<UserOutlined />}
                    disabled={!isEditable}
                    size="large"
                    placeholder="Vergi Dairesi"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Vergi Numarası" name="taxNo">
                  <Input
                    suffix={<UserOutlined />}
                    disabled={!isEditable}
                    size="large"
                    type="number"
                    placeholder="Vergi Numarası"
                  />
                </Form.Item>
              </Col>

              <Col xs={12} lg={{ span: 4, offset: 8 }}>
                <Form.Item>
                  <Button
                    block
                    size="large"
                    type="primary"
                    onClick={handleButton}
                  >
                    {isEditable ? "Kaydet" : "Düzenle"}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Card>
  );
};

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(CompanyContent));
