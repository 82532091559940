import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Image,
  Modal,
  Pagination,
  Popover,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
// import info from "../../utils/marketplaces.json";
import infoIcon from "../../assets/images/icons/DetayIconWhiteBg.svg";
import axios from "axios";
import { getMyOrders } from "./api";
import moment from "moment";
import { isHaveAccess } from "../../utils/Permission";
import { showNotification } from "../../utils/showNotification";
import { Link, useHistory } from "react-router-dom";

import { Fragment } from "react";
import ReactToPrint from "react-to-print";
import { PrintSticker } from "../../components/order/PrintSticker";
import { PrintTicket } from "../../components/order/PrintTicket";
import OrderFilter from "../../components/order/OrderFilter";

import trendyolLogo from "../../assets/images/logo/trendyol.jpg";

const OrderContent = () => {
  const [loading, setLoading] = useState(false);
  // const [marketPlace, setMarketPlace] = useState([]);
  // const [marketPlaceOptions, setMarketPlaceOptions] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState({
    offset: 1,
    limit: 10,
  });
  const [orderCount, setOrderCount] = useState(null);
  const [mainData, setMainData] = useState([]);
  const [isCustomerModalVisible, setIsCustomerModalVisible] = useState(false);
  const [isOrderStatusModalVisible, setIsOrderStatusModalVisible] =
    useState(false);
  const [customerModalData, setCustomerModalData] = useState({});
  const [orderStatusModalData, setOrderStatusModalData] = useState([]);

  
  const [filterData, setFilterData] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);

  // ReactToPrint, functional component'tan çağırırken content ref ile gönderiliyor.
  const printStickerRef = useRef([]);
  const printTicketRef = useRef([]);
  printTicketRef.current = [];
  printStickerRef.current = [];

  const addToTicket = (el) => {
    if (el && !printTicketRef.current.includes(el)) {
      printTicketRef.current.push(el);
    }
  };
  const addToSticker = (el) => {
    if (el && !printStickerRef.current.includes(el)) {
      printStickerRef.current.push(el);
    }
  };

  const handleFilterData = ({data}) => {
    setFilterData(data)
  }

    const history = useHistory();

  
  useEffect(() => {
    const user_id = JSON.parse(window.localStorage.getItem("user")).id;
    const access = async () => {
      const perms = await isHaveAccess(user_id, "order-management");
      if (!perms) {
        history.push("/");
        showNotification(
          "error",
          "Yetkiniz Yok!",
          "Gitmek istediğiniz sayfaya yetkiniz bulunmamaktadır!"
        );
        return;
      }
    };
    access();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCustomerModal = (record) => {
    setCustomerModalData(record.customerData);
    setIsCustomerModalVisible(true);
  };
  const handleOrderModal = (record) => {
    setOrderStatusModalData(record.packageHistories);
    setIsOrderStatusModalVisible(true);
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source()
    handleGetMyOrders({
      offset: paginationInfo.offset, 
      limit: paginationInfo.limit, 
      customerName: filterData.customerName, 
      productName: filterData.productName, 
      orderNumber: filterData.orderNumber,
      packageNumber: filterData.packageNumber,
      cargoProviderName: filterData.cargoProviderName,
      status: filterData.status,
      barcode: filterData.barcode,
      orderDate__gte: filterData.orderDateStart,
      orderDate__lte: filterData.orderDateEnd,
      status_in: filterData.status_in,
      source: source
     })
    return () => {
      source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInfo, filterData])
  
  // Get my orders
  const handleGetMyOrders = async ({ offset, limit, customerName, productName, orderNumber, packageNumber, cargoProviderName, status, barcode, orderDate__lte, orderDate__gte, status_in, source }) => {
    setLoading(true);
    let user = JSON.parse(window.localStorage.getItem("user"));
    try {
      const response = await getMyOrders({
        user_id: user.id,
        offset: offset,
        limit: limit,
        customerName: customerName,
        productName: productName,
        orderNumber: orderNumber,
        packageNumber: packageNumber,
        cargoProviderName: cargoProviderName,
        status: status,
        barcode: barcode,
        orderDate__lte: orderDate__lte,
        orderDate__gte: orderDate__gte,
        status__in: status_in,
        cancelToken: source.token,
      });
      setOrderCount(response.data.count);
      const tempArr = response.data.results.map((data, index) => {
        let kdvAmount = 0;
        // eslint-disable-next-line array-callback-return
        data.lines.map((line) => {
          kdvAmount = kdvAmount + line.price * (line.vatBaseAmount / 100);
        });
        const status =
          data.packageHistories[data.packageHistories.length - 1].status;
        return {
          key: index,
          id: data.id,
          marketplace: "Trendyol",
          orderNo: data.orderNumber,
          orderDate: moment(data.orderDate).format("DD.MM.YYYY HH:mm"),
          customer: data.customerFirstName + " " + data.customerLastName,
          totalPrice: data.totalPrice + " ₺",
          totalDiscount: data.totalDiscount + " ₺",
          kdv: kdvAmount.toFixed(2) + " ₺",
          orderStatus: status,
          lines: data.lines,
          customerData: {
            invoiceAddress: data.invoiceAddress,
            shipmentAddress: data.shipmentAddress,
          },
          packageNumber: data.trendyolId,
          packageHistories: data.packageHistories,
          fastDelivery: data.fastDelivery,
          fastDeliveryType: data.fastDeliveryType,
          cargoTrackingNumber: data.cargoTrackingNumber,
          cargoProviderName: data.cargoProviderName,
          shipmentAddress: data.shipmentAddress,
        };
      });
      setMainData(tempArr);
      setLoading(false);
    } catch (error) {}
  };

  const handleTablePagination = (offset, limit) => {
    // Bütün veriyi direkt çekmekten yerine her tabloda gösterilen kadar veri çekmek için
    // tablo pagination'ın onchange fonksiyonunu kullanacağız.
    setPaginationInfo((prevState) => ({
      ...prevState,
      offset: offset,
      limit: limit,
    }));
  };

  // // Get marketplaces for options
  // useEffect(() => {
  //   const tempArr = [];
  //   const marketPlaceTemp = [];
  //   for (const [key, value] of Object.entries(info.marketplaces)) {
  //     const obj = {
  //       id: value,
  //       name: key,
  //     };
  //     tempArr.push(obj);
  //     marketPlaceTemp.push(value);
  //   }
  //   setMarketPlace(marketPlaceTemp);
  //   setMarketPlaceOptions(tempArr);
  // }, []);

  // Handle Marketplace options select change
  // const handleMarketplaceSelect = (value) => {
  //   if (value.includes("all")) {
  //     setMarketPlace(marketPlaceOptions.map((r) => r.id));
  //   } else {
  //     setMarketPlace(value);
  //   }
  // };
  // const marketPlaceSelectOptions = marketPlaceOptions.map((res, index) => {
  //   return (
  //     <Select.Option key={index} value={res.id}>
  //       {res.name}
  //     </Select.Option>
  //   );
  // });
  
  
  const operations = (
    <div className="operation-container">
      <p>
        <Link to="#">İşleme Al</Link>
      </p>
      <p>
        <Link to="#">Başka Kargo Fir. İle Gönder</Link>
      </p>
      <p>
        <Link to="#">İptal Et</Link>
      </p>
      <p>
        <Link to="#">Mağaza Kartı Yazdır</Link>
      </p>
    </div>
  );

  const addInvoice = (
    <div>
      <div className="header">
        <span className="title">Hızlı Hesap</span>
        <span className="subtitle">e-faturam</span>
      </div>
      <div className="content">
        <p>
          Hızlı Hesap e-fatura ile anında <br /> faturanızı oluşturun,
          işlerinizi hızlandırın.
        </p>
        <Button type="primary">Hemen Başvur</Button>
        <Button className="invoice-info">Fatura Bilgileri</Button>
      </div>
    </div>
  );
 

  const mainColumns = [
    {
      title: "Sipariş Bilgileri",
      dataIndex: "orderNo",
      key: "orderNo",
      width: 120,
      render: (text, record) => (
        <Row gutter={[0, 8]}>
          <Col>
            <img src={trendyolLogo} width="70" alt="" />
          </Col>
          <Col>
            <span className="text-green">#{text}</span>
          </Col>
          <Col>
            <span>Sipariş Tarihi: {record.orderDate}</span>
          </Col>
        </Row>
      ),
    },
    {
      title: "Paket No",
      dataIndex: "packageNumber",
      key: "packageNumber",
      width: 80,
    },
    {
      title: "Alıcı",
      dataIndex: "customer",
      key: "customer",
      width: 130,
      render: (text, record) => (
        <Row gutter={[0, 8]}>
          {record.fastDelivery && (
            <Col xs={24}>
              <span className="text-orange">{record.fastDeliveryType}</span>
            </Col>
          )}
          <Col xs={24}>
            <span>{text}</span>
          </Col>
          <Col xs={24}>
            <Image
              preview={false}
              width={20}
              src={infoIcon}
              className="cursor-p"
              onClick={() => handleCustomerModal(record)}
            />
          </Col>
        </Row>
      ),
    },
    {
      title: "Adet",
      dataIndex: "_",
      key: "_",
      width: 50,
      render: (_, record) =>
        record.lines.map((value, index) => {
          const top =
            (92 / record.lines.length) * index + 46 / record.lines.length;
          const topDv = (100 / record.lines.length) * (index + 1);

          return (
            <Fragment key={value.id}>
              <Col
                style={{ position: "absolute", top: `${top}%` }}
                className="quantity-container"
              >
                <span>{value.quantity}</span>
              </Col>
              {record.lines.length > 1 && index + 1 < record.lines.length && (
                <Divider
                  className="divider"
                  style={{ position: "absolute", top: `${topDv}%` }}
                />
              )}
            </Fragment>
          );
        }),
    },
    {
      title: "Bilgiler",
      dataIndex: "lines",
      key: "lines",
      width: "40%",
      render: (_, record) => (
        <Row gutter={[0, 8]} className="product-container">
          {record.lines.map((value, index) => {
            const topDv = (100 / record.lines.length) * (index + 1);
            return (
              <Fragment key={value.id}>
                <Col className="product">
                  <Row>
                    <Col xs={4}>
                      <Image src={value.images[0]["url"]} width="50" alt="" />
                    </Col>
                    <Col xs={20}>
                      <Row>
                        <Col>
                          <span>
                            <b>{value.productName}</b>
                          </span>
                        </Col>
                        <Col xs={24}>
                          <span>
                            Stok Kodu: <b>{value.merchantSku}</b>
                          </span>
                        </Col>
                        <Col xs={24}>
                          <span>
                            Barkod: <b>{value.barcode}</b>
                          </span>
                        </Col>
                        <Col xs={24}>
                          <span>Renk: ---</span>
                        </Col>
                        <Col xs={24}>
                          <span>
                            Beden: <b>{value.productSize}</b>
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                {record.lines.length > 1 && index + 1 < record.lines.length && (
                  <Divider
                    className="divider"
                    style={{ position: "absolute", top: `${topDv}%` }}
                  />
                )}
              </Fragment>
            );
          })}
        </Row>
      ),
    },
    {
      title: "Birim Fiyat",
      dataIndex: "totalPrice",
      key: "totalPrice",
      ellipsis: true,
      render: (_, record) =>
        record.lines.map((value, index) => {
          const top =
            (92 / record.lines.length) * index + 46 / record.lines.length;
          const topDv = (100 / record.lines.length) * (index + 1);
          return (
            <Fragment key={value.id}>
              <Col style={{ position: "absolute", top: `${top}%` }}>
                <span>{value.price} TL</span>
              </Col>
              {record.lines.length > 1 && index + 1 < record.lines.length && (
                <Divider
                  className="divider"
                  style={{ position: "absolute", top: `${topDv}%` }}
                />
              )}
            </Fragment>
          );
        }),
    },
    {
      title: "Kargo",
      dataIndex: "cargoTrackingNumber",
      key: "cargoTrackingNumber",
      width: 70,
    },
    {
      title: "Fatura",
      dataIndex: "totalPrice",
      key: "totalPrice",
      width: 30,
      render: (text, record) => (
        <Row gutter={[0, 8]}>
          <Col xs={24} align={"middle"}>
            <span>
              Satış Tutarı: <br /> <b>{text}</b>
            </span>
          </Col>
          <Col xs={24} align={"middle"}>
            <Popover
              content={addInvoice}
              className="add-invoice-container"
              overlayClassName="add-invoice-content"
              placement="bottomRight"
              trigger="click"
            >
              <label className="add-invoice">
                Fatura <br /> İşlemleri
              </label>
              <DownOutlined />
            </Popover>
          </Col>
        </Row>
      ),
    },
    {
      title: "Durum",
      dataIndex: "orderStatus",
      key: "orderStatus",
      width: 30,
      render: (text, record, index) => (
        <Row gutter={[0, 8]} className="statusBtn">
          <Col xs={24} align={"middle"}>
            <span className={`${text.toLowerCase()} status`}>{text}</span>
          </Col>
          <Col align={"middle"} xs={24}>
            <ReactToPrint trigger={()=><Button>Etiketi A4 Yazdır</Button>} content={()=> printTicketRef.current[index] } />
            <div style={{ display: "none" }}><PrintTicket ref={addToTicket} props={record} /></div>  
          
          </Col>
          <Col align={"middle"} xs={24}>
            <ReactToPrint trigger={()=><Button>Etiketi Sticker Yazdır</Button>} content={()=> printStickerRef.current[index] } />
            <div style={{ display: "none" }}><PrintSticker ref={addToSticker} props={record} /></div>
          </Col>
          <Col xs={{span: 12, offset: 2}}>
            <Popover
              content={operations}
              placement="bottom"
              className="order-action"
              trigger={["click"]}
              overlayClassName="operations-content"
            >
              <Typography.Link>
                <Space>
                İşlemler
                </Space>
                <DownOutlined />
              </Typography.Link>
            </Popover>
          </Col>
          <Col xs={8} align={"right"}>
            <Image
              preview={false}
              width={20}
              src={infoIcon}
              className="cursor-p"
              onClick={() => handleOrderModal(record)}
            />
          </Col>
        </Row>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedRows(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <Card >
     <OrderFilter filterData={handleFilterData} ticketRef= {printTicketRef.current} stickerRef= {printStickerRef.current} selectedRows={selectedRows} />
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <Table
            id="orders"
            className="small-table"
            /* expandable={handleRowExpandable} */ scroll={{ x: true }}
            rowSelection={{...rowSelection}}
            columns={mainColumns}
            pagination={false}
            dataSource={mainData}
            rowClassName={(record) => record.orderStatus.toLowerCase()}
            size="small"
            loading={{ spinning: loading, indicator: <LoadingOutlined /> }}
          />
        </Col>
        <Col xs={12} align="left">
          Toplam: {orderCount} Kayıt
        </Col>
        <Col xs={12} align="right">
          <Pagination
            size="small"
            style={{ fontSize: 12 }}
            onChange={handleTablePagination}
            current={paginationInfo.offset}
            defaultCurrent={1}
            total={orderCount}
          />
        </Col>
      </Row>

      {/* ---------- CUSTOMER INFORMATION MODAL ---------- */}
      <Modal
        title={"Müşteri Bilgileri"}
        visible={isCustomerModalVisible}
        onCancel={() => setIsCustomerModalVisible(false)}
        footer={null}
      >
        {customerModalData.shipmentAddress && (
          <Row gutter={[16, 0]}>
            <Col xs={24} align="center">
              <span style={{ fontWeight: 700, fontSize: 18 }}>
                {customerModalData.shipmentAddress.fullName}
              </span>
            </Col>
            <Col xs={7}>
              <p
                style={{
                  fontWeight: 700,
                  fontSize: 15,
                  textDecoration: "underline",
                }}
              >
                Kargo Bilgileri
              </p>
              <span>
                <b>Şehir: </b>
              </span>
              <br />
              <span>
                <b>İlçe: </b>
              </span>
              <br />
              <span>
                <b>Posta Kodu: </b>
              </span>
              <br />
              <span>
                <b>Açık Adres: </b>
              </span>
              <br />
            </Col>
            <Col xs={17}>
              <br />
              <br />
              <span>{customerModalData.shipmentAddress.city}</span>
              <br />
              <span>{customerModalData.shipmentAddress.district}</span>
              <br />
              <span>{customerModalData.shipmentAddress.postalCode}</span>
              <br />
              <span>{customerModalData.shipmentAddress.fullAddress}</span>
              <br />
            </Col>
            <Col xs={24}>
              <Divider />
            </Col>
            <Col xs={7}>
              <p
                style={{
                  fontWeight: 700,
                  fontSize: 15,
                  textDecoration: "underline",
                }}
              >
                Fatura Bilgileri
              </p>
              <span>
                <b>Şehir: </b>
              </span>
              <br />
              <span>
                <b>İlçe: </b>
              </span>
              <br />
              <span>
                <b>Posta Kodu: </b>
              </span>
              <br />
              <span>
                <b>Açık Adres: </b>
              </span>
              <br />
              <span>
                <b>Firma: </b>
              </span>
              <br />
            </Col>
            <Col xs={17}>
              <br />
              <br />
              <span>{customerModalData.invoiceAddress.city}</span>
              <br />
              <span>{customerModalData.invoiceAddress.district}</span>
              <br />
              <span>{customerModalData.invoiceAddress.postalCode}</span>
              <br />
              <span>{customerModalData.invoiceAddress.fullAddress}</span>
              <br />
              <span>{customerModalData.invoiceAddress.company}</span>
              <br />
            </Col>
          </Row>
        )}
      </Modal>

      {/* ---------- ORDER STATUS MODAL ---------- */}
      <Modal
        title={"Sipariş Statüleri"}
        visible={isOrderStatusModalVisible}
        onCancel={() => setIsOrderStatusModalVisible(false)}
        footer={null}
      >
        {orderStatusModalData && (
          <>
            <Row style={{ marginBottom: 20 }}>
              <Col xs={24} align="center">
                <span style={{ fontWeight: 700, fontSize: 18 }}>
                  Sipariş Durumları
                </span>
              </Col>
            </Row>
            {orderStatusModalData.map((data, index) => {
              return (
                <Row key={index} style={{ borderBottom: "1px solid #000000" }}>
                  <Col xs={4}>
                    <span>Statü: </span>
                  </Col>
                  <Col xs={8}>
                    <span>{data.status}</span>
                  </Col>
                  <Col xs={4}>Tarih:</Col>
                  <Col xs={8}>
                    {moment(data.createdDate).format("DD.MM.YYYY HH:mm")}
                  </Col>
                </Row>
              );
            })}
          </>
        )}
      </Modal>
    </Card>
  );
};

export default OrderContent;
