import { Col, Row } from "antd";
import React from "react";
import Barcode from "react-barcode";

export const PrintSticker = React.forwardRef(({props}, ref) => {
  const current = new Date();
  const {cargoTrackingNumber, cargoProviderName, shipmentAddress, orderNo, customer  } = props;
  return (
    <div ref={ref} className="pagebreak" style={{ width: "500", padding: "20px" }}>
      <Row>
        <Col xs={12}>
          {current.toLocaleDateString()} {current.toLocaleTimeString()}
        </Col>
        <Col xs={12}>Trendyol Pazaryeri</Col>
        <Col xs={24}>
          <Barcode value={cargoTrackingNumber.toString()} width={3} height={150} />
        </Col>
        <Col xs={14}>
          <Row
            style={{ border: "1px solid #000", padding: "10px", marginBottom: "10px" }}
          >
            <Col xs={24}>
              <p>
                {shipmentAddress.address1.toUpperCase()} {shipmentAddress.address2.toUpperCase()}
              </p>
            </Col>
            <Col xs={24}>
            <p>
            <b>{shipmentAddress.district.toUpperCase()}/{shipmentAddress.city.toUpperCase()}</b>
            </p>
            </Col>
            <Col xs={24}>
              <p>PK: {shipmentAddress.postalCode}</p>
            </Col>
          </Row>
          <Row style={{ border: "1px solid #000", padding: "10px", marginBottom: "10px" }}>
            <Col xs={24} >
              <p>{ customer.toUpperCase() }</p>
            </Col>
          </Row>
        </Col>
        <Col xs={4}>
          <div style={{ transform: "rotate(90deg)", position:"absolute", left: "-65px", top: "65px" }}>
            <Barcode value={cargoTrackingNumber.toString()} displayValue={false} />
          </div>
        </Col>
        <Col xs={24}>
          <p>
            {orderNo}
            <br />
            {cargoTrackingNumber}
            <br />
           {cargoProviderName}
          </p>
        </Col>
      </Row>
    </div>
  );
});
