import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../../redux/hizlihesap/actions";
import { Button, Card, Checkbox, Col, Divider, Form, Input, Modal, Row, Space, Table, Tag } from 'antd'
import { createPassword, createUser, deleteUser, getBossesUsers, getPermission, getUser, updatePermission, updateUser } from '../api';
import { DeleteOutlined, EditOutlined, UserOutlined } from '@ant-design/icons';
import { showNotification } from '../../../utils/showNotification';
import { RandomString } from '../../../utils/StringMethods';


const { confirm } = Modal;

const UsersContent = (props) => {
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState({})
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [data, setData] = useState([]);
  const [updatedUser, setUpdatedUser] = useState();
  
  const [createForm] = Form.useForm()
  const [updateForm] = Form.useForm()


  const handleCreateUser = () => {
    if(user.profile.company === null) {
      showNotification('error', 'HATA!', 'Lütfen kullanıcı oluşturmadan önce firma bilgilerini doldurunuz!')
      return
    }
    createForm.resetFields()
    showModal(true)
  }

  const onFinish = async (values) => {
    props.setRedux({ loading: true })
    values.password = RandomString()
    const userPayload = {
      email: values.email,
      first_name: values.first_name,
      last_name: values.last_name,
      password: values.password,
    }
    delete values.email
    delete values.first_name
    delete values.last_name
    delete values.password

    const response = await createUser(userPayload)
    if (response.data.error) {
      showNotification('error', 'Kullanıcı Mevcut!', 'Belirtilen e-posta adresi ile kayıtlı kullanıcı mevcut!')
      props.setRedux({ loading: false })
      return
    }
    if (response.status === 201) {
      const res = await updatePermission(response.data.profile.permission.id, values)
      if (res.status === 200) {
        const sendMail = await createPassword({ email: response.data.email })
        if (sendMail.status === 200) {
          handleGetBossesUsers(user.id)
          showNotification('success', 'Kullanıcı Oluşturuldu!', 'Oluşturulan kullancının e-posta adresine şifre oluşturma maili gönderildi!')
        }
      }
    } else {
      showNotification('error', 'Uppss!', 'Bir hata meydana geldi!')
    }
    props.setRedux({ loading: false })
    showModal(false)
  }

  const handleGetUser = async (id) => {
    try {
      const response = await getUser(id)
      if (response.status === 200) {
        setUser(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetBossesUsers = async (bossId) => {
    setLoading(true)
    try {
      const response = await getBossesUsers(bossId)
      const tempArr = response.data.results.map((data, index) => {
        return {
          key: index,
          id: data.user.id,
          first_name: data.user.first_name,
          last_name: data.user.last_name,
          email: data.user.email,
          status: data.isActive,
          permissions_id: data.permissions.id
        }
      })
      setData(tempArr)
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }
  useEffect(() => {
    const user_ = JSON.parse(window.localStorage.getItem('user'))
    handleGetUser(user_.id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const user_ = JSON.parse(window.localStorage.getItem('user'))
    const boss = isBoss()
    if (boss === true) {
      handleGetBossesUsers(user_.id)
    } else {
      if(!user.email) return
      handleGetBossesUsers(user.profile.bossId)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const isBoss = () => user?.profile?.isBoss

  const showModal = (status) => {
    const boss = isBoss()
    if (!boss) {
      showNotification('error', 'Yetkiniz yok!', 'Bu alanda işlem yapmak için yetkiniz bulunmamakta!')
      return
    }
    setIsModalVisible(status);
  };

  const closeUpdateModal = () => {
    setIsUpdateModalVisible(false)
  }
  const showUpdateModal = async (info) => {
    const boss = isBoss()
    if (!boss) {
      showNotification('error', 'Yetkiniz yok!', 'Bu alanda işlem yapmak için yetkiniz bulunmamakta!')
      return
    }
    setUpdatedUser(info)
    const response = await getUser(info.id)
    const perms = await getPermission(info.permissions_id)
    console.log('PERMS: ', perms)
    if (response.status === 200) {
      updateForm.resetFields()
      updateForm.setFieldsValue({
        first_name: response.data.first_name,
        last_name: response.data.last_name,
        email: response.data.email,
        isIntegration: perms.data.isIntegration,
        isProductStockManager: perms.data.isProductStockManager,
        isOrderManager: perms.data.isOrderManager,
        isCalender: perms.data.isCalender,
        isReports: perms.data.isReports,
        isTaskManager: perms.data.isTaskManager,
        isSupport: perms.data.isSupport,
        isTrendyolManager: perms.data.isTrendyolManager,
        isHepsiburadaManager: perms.data.isHepsiburadaManager,
        isN11Manager: perms.data.isN11Manager,
        isCiceksepetiManager: perms.data.isCiceksepetiManager,
        isAmazonManager: perms.data.isAmazonManager,
        isEtsyManager: perms.data.isEtsyManager,
      })
      setIsUpdateModalVisible(true)
    }
  }

  const deleteUser_ = async (record) => {
    const boss = isBoss()
    if (!boss) {
      showNotification('error', 'Yetkiniz yok!', 'Bu alanda işlem yapmak için yetkiniz bulunmamakta!')
      return
    }
    confirm({
      content: "Seçtiğiniz kullanıcıyı silmek istediğinize emin misiniz?",
      okText: "Evet",
      cancelText: "Hayır",
      async onOk() {
        props.setRedux({ loading: true })
        const response = await deleteUser(record.id)
        if (response.status === 204) {
          handleGetBossesUsers(user.id)
          showNotification('success', 'Kullanıcı Silindi!')
        } else {
          showNotification('error', 'Hata!', 'Bir hata meydana geldi!')
        }
        props.setRedux({ loading: false })
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const onUpdateFinish = async (values) => {
    props.setRedux({ loading: true })
    values.password = RandomString()
    const userPayload = {
      email: values.email,
      first_name: values.first_name,
      last_name: values.last_name,
      password: values.password,
    }
    delete values.email
    delete values.first_name
    delete values.last_name
    delete values.password
    console.log('USER PAYLOAD: ', userPayload)
    console.log('VALUES: ', values)
    console.log('updatedUser: ', updatedUser)
    const response = await updateUser(updatedUser.id, values)
    if (response.status === 200) {
      const res = await updatePermission(updatedUser.permissions_id, values)
      console.log('RESPONSE: ', res)
      showNotification('success', 'Kullanıcı güncellendi!')
      closeUpdateModal()
    }
    if (response.status === 400) {
      if (response.data.email) {
        showNotification('error', 'Kullanıcı Ekle!', 'Girmiş olduğunuz e-posta adresi ile kullanıcı zaten mevcut!')
      }
    }
    props.setRedux({ loading: false })
  }

  const columns = [
    {
      title: 'Ad',
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: 'Soyad',
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: 'E-posta',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Statü',
      key: 'status',
      dataIndex: 'status',
      render: tags => {
        return (
          <Tag color={tags ? "#87d068" : "#f50"}>{tags ? 'Aktif' : 'Pasif'}</Tag>
        )
      }
    },
    {
      title: 'İşlem',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <EditOutlined className='cursor-p' onClick={() => showUpdateModal(record)} />
          <DeleteOutlined className='cursor-p' onClick={() => deleteUser_(record)} />
        </Space>
      ),
    },
  ];


  return (
    <Card>
      <Row gutter={[16, 24]}>
        <Col xs={24} align='right'>
          <Button onClick={handleCreateUser}>Kullanıcı Oluştur</Button>
        </Col>
        <Col xs={24}>
          <Table columns={columns} dataSource={data} size='small' loading={loading} />
        </Col>
      </Row>

      {/* CREATE MODAL */}
      <Modal title={'Kullanıcı Bilgileri'} visible={isModalVisible} onOk={() => {
        createForm.submit()
      }} onCancel={() => showModal(false)}>
        <Form
          name="profile-form"
          form={createForm}
          initialValues={{}}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          layout='vertical'
          autoComplete="off">
          <Row gutter={[0, 0]}>
            <Col xs={{ span: 24, offset: 0 }}>
              <Form.Item label="" name="first_name"
                rules={[{ required: true, message: 'Lütfen adınızı giriniz!' },]}>
                <Input suffix={<UserOutlined />} size='large' placeholder='Ad' />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24, offset: 0 }}>
              <Form.Item label="" name="last_name"
                rules={[{ required: true, message: 'Lütfen soyadınızı giriniz!' },]}>
                <Input suffix={<UserOutlined />} size='large' placeholder='Soyad' />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24, offset: 0 }}>
              <Form.Item label="" name="email"
                rules={[{ required: true, message: 'Lütfen soyadınızı giriniz!' }, { type: 'email', message: 'Lütfen geçerli bir e-posta giriniz!' }]}>
                <Input suffix={<UserOutlined />} size='large' placeholder='E-posta' />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Divider orientation='left' orientationMargin="0">Modüller</Divider>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item name="isIntegration" valuePropName="checked" initialValue={false}>
              <Checkbox>Entegrasyonlar</Checkbox>
            </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item name="isProductStockManager" valuePropName="checked" initialValue={false}>
              <Checkbox>Ürün-Stok Yönetimi</Checkbox>
            </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item name="isOrderManager" valuePropName="checked" initialValue={false}>
              <Checkbox>Sipariş Yönetimi</Checkbox>
            </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item name="isCalender" valuePropName="checked" initialValue={false}>
              <Checkbox>Ödeme Takvimi</Checkbox>
            </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item name="isReports" valuePropName="checked" initialValue={false}>
              <Checkbox>Raporlar</Checkbox>
            </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item name="isTaskManager" valuePropName="checked" initialValue={false}>
              <Checkbox>Görev Yönetimi</Checkbox>
            </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item name="isSupport" valuePropName="checked" initialValue={false}>
              <Checkbox>Destek</Checkbox>
            </Form.Item>
            </Col>
            <Col xs={24}>
              <Divider orientation='left' orientationMargin="0">Pazaryeri</Divider>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item name="isTrendyolManager" valuePropName="checked" initialValue={false}>
              <Checkbox>Trendyol</Checkbox>
            </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item name="isHepsiburadaManager" valuePropName="checked" initialValue={false}>
              <Checkbox>Hepsiburada</Checkbox>
            </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item name="isN11Manager" valuePropName="checked" initialValue={false}>
              <Checkbox>N11</Checkbox>
            </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item name="isCiceksepetiManager" valuePropName="checked" initialValue={false}>
              <Checkbox>Çiçek Sepeti</Checkbox>
            </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item name="isAmazonManager" valuePropName="checked" initialValue={false}>
              <Checkbox>Amazon</Checkbox>
            </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item name="isEtsyManager" valuePropName="checked" initialValue={false}>
              <Checkbox>Etsy</Checkbox>
            </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      {/* EDIT MODAL */}
      <Modal title={'Kullanıcı Bilgileri'} visible={isUpdateModalVisible} onOk={updateForm.submit} onCancel={closeUpdateModal}
        footer={[
          <Button key="back" onClick={closeUpdateModal}>
            İptal
          </Button>,
          <Button key="submit" type="primary" onClick={updateForm.submit}>
            Güncelle
          </Button>,
        ]}>
        <Form
          name="profile-form"
          form={updateForm}
          initialValues={{}}
          onFinish={onUpdateFinish}
          // onFinishFailed={onFinishFailed}
          layout='vertical'
          autoComplete="off">
          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24, offset: 0 }}>
              <Form.Item label="Ad" name="first_name"
                rules={[{ required: true, message: 'Lütfen adınızı giriniz!' },]}>
                <Input suffix={<UserOutlined />} size='large' placeholder='Ad' />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24, offset: 0 }}>
              <Form.Item label="Soyad" name="last_name"
                rules={[{ required: true, message: 'Lütfen soyadınızı giriniz!' },]}>
                <Input suffix={<UserOutlined />} size='large' placeholder='Soyad' />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24, offset: 0 }}>
              <Form.Item label="E-posta" name="email"
                rules={[{ required: true, message: 'Lütfen soyadınızı giriniz!' }, { type: 'email', message: 'Lütfen geçerli bir e-posta giriniz!' }]}>
                <Input suffix={<UserOutlined />} size='large' placeholder='E-posta' />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Divider orientation='left' orientationMargin="0">Modüller</Divider>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item name="isIntegration" valuePropName="checked" initialValue={false}>
              <Checkbox>Entegrasyonlar</Checkbox>
            </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item name="isProductStockManager" valuePropName="checked" initialValue={false}>
              <Checkbox>Ürün-Stok Yönetimi</Checkbox>
            </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item name="isOrderManager" valuePropName="checked" initialValue={false}>
              <Checkbox>Sipariş Yönetimi</Checkbox>
            </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item name="isCalender" valuePropName="checked" initialValue={false}>
              <Checkbox>Ödeme Takvimi</Checkbox>
            </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item name="isReports" valuePropName="checked" initialValue={false}>
              <Checkbox>Raporlar</Checkbox>
            </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item name="isTaskManager" valuePropName="checked" initialValue={false}>
              <Checkbox>Görev Yönetimi</Checkbox>
            </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item name="isSupport" valuePropName="checked" initialValue={false}>
              <Checkbox>Destek</Checkbox>
            </Form.Item>
            </Col>
            <Col xs={24}>
              <Divider orientation='left' orientationMargin="0">Pazaryeri</Divider>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item name="isTrendyolManager" valuePropName="checked" initialValue={false}>
              <Checkbox>Trendyol</Checkbox>
            </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item name="isHepsiburadaManager" valuePropName="checked" initialValue={false}>
              <Checkbox>Hepsiburada</Checkbox>
            </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item name="isN11Manager" valuePropName="checked" initialValue={false}>
              <Checkbox>N11</Checkbox>
            </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item name="isCiceksepetiManager" valuePropName="checked" initialValue={false}>
              <Checkbox>Çiçek Sepeti</Checkbox>
            </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item name="isAmazonManager" valuePropName="checked" initialValue={false}>
              <Checkbox>Amazon</Checkbox>
            </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
            <Form.Item name="isEtsyManager" valuePropName="checked" initialValue={false}>
              <Checkbox>Etsy</Checkbox>
            </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Card>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(UsersContent));