import React, { useEffect, useState } from 'react'
import { useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../../redux/hizlihesap/actions";
import { Button, Col, Form, Image, Input, Row } from 'antd'
import trendyolLogo from '../../../assets/images/logo/trendyol.jpeg'
import { createMarketPlace, getMarketPlaceApiByUserAndMarketPlace, updateMarketPlace, verifyTrendyol, trendyolSaveProducts, trendyolSaveOrders } from '../api';
import info from '../../../utils/marketplaces.json';
import { showNotification } from '../../../utils/showNotification';
import { isHaveAccess } from '../../../utils/Permission';

const TrendyolContent = (props) => {
  const [isConnected, setIsConnected] = useState(false)
  const [alreadyConnected, setAlreadyConnected] = useState(null)
  const [isActive, setIsActive] = useState(false)

  const [form] = Form.useForm()

  const history = useHistory()

  useEffect(() => {
    const user_id = JSON.parse(window.localStorage.getItem('user')).id
    const access = async () => {
      const perms = await isHaveAccess(user_id, "integration")
      if(!perms){
        history.push('/')
        showNotification('error', 'Yetkiniz Yok!', 'Gitmek istediğiniz sayfaya yetkiniz bulunmamaktadır!')
        return
      }
    }
    access()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDisconnect = async () => {
    props.setRedux({ loading: true })
    const response = await updateMarketPlace(alreadyConnected, {isActive: false})
    if(response.status === 200) {
      showNotification('success', 'Bağlantı kesildi!', 'Trendyol api bağlantınız kesilmiştir!')
      setIsConnected(false)
      setIsActive(false)
    }
    props.setRedux({ loading: false })
  }

  const onFinish = async (values) => {
    props.setRedux({ loading: true })
    values.marketPlace = info.marketplaces.Trendyol
    if (alreadyConnected === null) {
      try {
        const response = await createMarketPlace(values)
        if (response.status === 201) {
          const payload = {
            apiKey: values.apiKey,
            apiSecret: values.apiSecret,
            sellerId: values.customerID
          }
          const res = await verifyTrendyol(payload)
          if (res.data.trendyolApiStatus === 200) {
            const saveProducts = await trendyolSaveProducts(payload)
            if(saveProducts.data.trendyolApiStatus === 200){
              const saveOrders = await trendyolSaveOrders(payload)
              if(saveOrders.data.trendyolApiStatus === 200){
                showNotification('success', 'Bilgiler Kaydedildi!', 'Trendyol api bilgileriniz başarıyla kaydedildi ve bağlanıldı!')
                setIsActive(true)
                setIsConnected(true)
              }
            }
          } else {
            showNotification('error', 'HATA!', 'Lütfen bilgilerinizin doğruluğunu kontrol edip tekrar deneyin!')
          }
        } else {
          showNotification('error', 'HATA!', 'Bir hata meydana geldi lütfen tekrar deneyin!')
        }
      } catch (error) {

      }
    } else {
      const response = await updateMarketPlace(alreadyConnected, values)
      if (response.status === 200) {
        const payload = {
          apiKey: values.apiKey,
          apiSecret: values.apiSecret,
          sellerId: values.customerID
        }
        const res = await verifyTrendyol(payload)
        if (res.data.trendyolApiStatus === 200) {
          showNotification('success', 'Bilgiler Kaydedildi!', 'Trendyol api bilgileriniz başarıyla kaydedildi ve bağlanıldı!')
          setIsActive(true)
          setIsConnected(true)
        } else {
          showNotification('error', 'HATA!', 'Lütfen bilgilerinizin doğruluğunu kontrol edip tekrar deneyin!')
        }
      } else {
        showNotification('error', 'HATA!', 'Bir hata meydana geldi lütfen tekrar deneyin!')
      }
    }

    props.setRedux({ loading: false })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    const user = JSON.parse(window.localStorage.getItem('user'))
    checkIsConnected(user.id)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkIsConnected = async (id) => {
    try {
      const response = await getMarketPlaceApiByUserAndMarketPlace({ user_id: id, marketPlace_id: info.marketplaces.Trendyol })
      if (response.data.count === 0) {
        // Daha önce Bağlanmamış 
        return
      }
      setAlreadyConnected(response.data.results[0].id)
      if (response.data.results[0].isActive) {
        setIsActive(response.data.results[0].isActive)
        setIsConnected(true)
        form.setFieldsValue({
          customerID: response.data.results[0].customerID,
          apiKey: response.data.results[0].apiKey,
          apiSecret: response.data.results[0].apiSecret,
        })
      }
    } catch (error) {
      console.log('error : ', error)
    }
    props.setRedux({ loading: false })
  }


  return (
    <div style={{ paddingTop: 90 }}>
      <Row>
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 8, offset: 7 }} align='center'>
          <Row>
            <Col xs={24} style={{borderRadius: 8, backgroundColor: '#FFFFFF'}}>
              <Row>
                <Col xs={24} style={{  }}>
                  <Image preview={false} src={trendyolLogo} width={200} style={{
                    border: isActive ? '4px solid #87d068' : '4px solid #ff5c09',
                    borderRadius: 40,
                    boxShadow: '0 20px 30px #9d9d9d38',
                    zIndex: 5,
                    marginTop: '-80px'
                  }} />
                  <p className='font-w600 font-s32 my-20'>Trendyol API</p>
                  <span style={{ backgroundColor: isActive ? '#87d068' : '#ff5c09', color: '#FFFFFF', padding: '4px 8px', borderRadius: 10 }}>{isActive ? 'Aktif' : 'Pasif'}</span>
                  <br /><br />
                </Col>
                <Col xs={24} style={{ backgroundColor: '#F8FAFB', borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }} align='left'>
                  <Form
                    name="trendyol-form"
                    form={form}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout='vertical'
                    autoComplete="off">
                    <Row className='my-20'>
                      <Col xs={{ span: 18, offset: 3 }}>
                        <Form.Item label="Satıcı ID" name="customerID"
                          rules={[
                            { required: true, message: 'Lütfen satıcı id giriniz!' },]}>
                          <Input size='large' placeholder='Satıcı ID' disabled={isConnected} />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 18, offset: 3 }}>
                        <Form.Item label="API Key" name="apiKey"
                          rules={[{ required: true, message: 'Lütfen api key giriniz!' }]}>
                          <Input size='large' placeholder='API Key' disabled={isConnected} />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 18, offset: 3 }}>
                        <Form.Item label="API Secret" name="apiSecret"
                          rules={[{ required: true, message: 'Lütfen api secret giriniz!' }]}>
                          <Input.Password autoComplete="new-password" size='large' placeholder='API Secret' disabled={isConnected} />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 18, offset: 3 }}>
                        <Form.Item>
                          <Button block size='large' type="primary" onClick={() => {
                            if (isConnected) {
                              handleDisconnect()
                            } else {
                              form.submit()
                            }
                          }}>
                            {isConnected ? 'Bağlantıyı Kes' : 'Bağlan'}
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}


const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(TrendyolContent));