import { Button, Result } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'

const Page404 = () => {
  const history = useHistory()
  return (
    <Result
      status="404"
      title="404"
      subTitle="Üzgünüm, Aradığınız sayfa bulunamadı."
      extra={<Button type="primary" onClick={() => history.push('/')}>Anasayfaya Dön</Button>}
    />
  )
}

export default Page404
