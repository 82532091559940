import React, { useState, useEffect } from "react";
import { Button, Card, Col, Divider, Row, Table } from "antd";
import CompareReportChart from "./CompareReportChart";
import { MarketplaceDetails, ReportTypes } from "../../../utils/StaticInfo";
import { useHistory } from "react-router-dom";

const CompareReportCard = (props) => {
  const {marketplace, orderList, reportTypes, range} = props;
  const { reportType } = orderList;
  const [data, setData] = useState([]);
  // const [totalTurnover, setTotalTurnover] = useState(0);
  // const [orderCount, setOrderCount] = useState(0);

  const [reportText, setReportText] = useState("")
  const [reportSort, setReportSort] = useState("")
  const [reportQuantity, setReportQuantity] = useState("")

  const history = useHistory();


  useEffect(()=>{
    setReportSort(reportType.toString().slice(-1)[0] === "1" ? "ÇOK" : "AZ")
    setReportText(ReportTypes[reportType.toString().slice(0,1)[0] - 1])

  },[reportType])

  useEffect(() => {
    const tempArr = orderList?.orderList?.map((data, index)=> {
      let total = "";
      if (orderList?.reportType?.toString().slice(0,1) === "1") {
        total = data[1].productTotalPrice.toFixed(2) + "TL";
      } else if (orderList?.reportType?.toString().slice(0,1) === "2") {
        total = data[1].totalQuantity;
      } else if (orderList?.reportType?.toString().slice(0,1) === "3") {
        total = data[1].totalQuantity;
      } else if (orderList?.reportType?.toString().slice(0,1) === "4") {
        total = data[1].productTotalPrice.toFixed(2) + "TL";
      } 
      return {key: index + 1, productName: data[0].productName, total: total }
    });
    const totalPrice = tempArr?.reduce(
      (accumulator, currentValue) => accumulator + parseFloat(currentValue.total), 0);

    tempArr?.push({key: tempArr.length + 1, productName: "Genel Toplam", total: totalPrice});

    setData(tempArr);
  }, [orderList]);

  useEffect(() => {
    // setTotalTurnover(orderList?.totalPrice?.toFixed(2));
    // setOrderCount(orderList?.orderCount);
    if (orderList?.reportType?.toString().slice(0,1) === "1") {
      setReportQuantity(orderList?.totalPrice?.toFixed(2) + " TL");
    } else if (orderList?.reportType?.toString().slice(0,1) === "2") {
      setReportQuantity(orderList?.orderCount);
    } else if (orderList?.reportType?.toString().slice(0,1) === "3") {
      setReportQuantity(orderList?.orderCount);
    } else if (orderList?.reportType?.toString().slice(0,1) === "4") {
      setReportQuantity(orderList?.returnedTotalPrice?.toFixed(2) + " TL");
    } 
  }, [orderList]);
 
  const columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
      render: (text, record, index) => record.key === data.length ? "" : text,
    },
    {
      title: `EN ${reportSort} ${reportText} YAPAN ÜRÜNLER`,
      dataIndex: "productName",
      key: "productName",
      onCell: (record, index) => {
        if (index === data.length - 1) {
          return {className: "font-w700"}
        }
      }
    },
    {
      title: "TOPLAM",
      dataIndex: "total",
      key: "total",
      onCell: (record, index) => {
        if (index === data.length - 1) {
          return {className: "font-w700"}
        }
      }
    },
  ];

  const handleAddReport = (value) => {
    history.push(`/reports/compare?reportType=${[...reportTypes,value.reportType]}&marketplace=${marketplace}&startDate=${range[0].valueOf()}&endDate=${range[1].valueOf()}`)
  }

  return (
    <Card
      title={`TOPLAM ${reportText}`}
      className="border bg-white height-100 card-shadow"
    >
      <Row gutter={[24, 24]} align="center">
        <Col xs={24} align="center">
          <span className="page-title-style">
            TOPLAM {reportText}: <span className="text-orange">{reportQuantity}</span>
          </span>
        </Col>

        <Col xs={20}>
          <CompareReportChart orderList={orderList?.dayByDayOrderList} reportType={orderList?.reportType} marketPlaces={marketplace} />
        </Col>
        <Col xs={4} style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "100%",
              backgroundColor: MarketplaceDetails.filter(el => el.id === marketplace[0])[0]?.color,
              border: MarketplaceDetails.filter(el => el.id === marketplace[0])[0]?.color,
              borderRadius: 8,
              color: "#fff",
              textAlign: "center",
              padding: "4px 15px",
            }}
          >
            100%
          </div>
        </Col>
        <Col xs={24}>
          <Divider />
        </Col>
        <Col
          xs={24}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h4>{reportText} RAPORU</h4>
          <Button type="primary" className="download-report">{reportText.toLocaleLowerCase()} Raporu İndir</Button>
        </Col>
        <Col xs={24}>
          <Table
            className="report-table"
            dataSource={data}
            columns={columns}
            pagination={false}
            size="small"
          />
        </Col>
        <Row gutter={[8,8]}>
            <Col xs={24} lg={12}>
                <Row>
                    <Col xs={8}><Button size="small" block type="ghost" disabled={reportTypes?.includes(11)} onClick={()=>handleAddReport({reportType: 11})} className="first">Ciro Raporu Ekle</Button></Col>
                    <Col xs={8}><Button size="small" block type="ghost" disabled={reportTypes?.includes(11)} onClick={()=>handleAddReport({reportType: 11})} className="middle">En Çok Ciro Raporu Ekle</Button></Col>
                    <Col xs={8}><Button size="small" block type="ghost" disabled={reportTypes?.includes(12)} onClick={()=>handleAddReport({reportType: 12})} className="last">En Az Ciro Raporu Ekle</Button></Col>
                </Row>
            </Col>
            <Col xs={24} lg={12}>
                <Row>
                    <Col xs={8}><Button size="small" block type="ghost" disabled={reportTypes?.includes(21)} onClick={()=>handleAddReport({reportType: 21})} className="first">Adet Raporu Ekle</Button></Col>
                    <Col xs={8}><Button size="small" block type="ghost" disabled={reportTypes?.includes(21)} onClick={()=>handleAddReport({reportType: 21})} className="middle">En Çok Adet Raporu Ekle</Button></Col>
                    <Col xs={8}><Button size="small" block type="ghost" disabled={reportTypes?.includes(22)} onClick={()=>handleAddReport({reportType: 22})} className="last">En Az Adet Raporu Ekle</Button></Col>
                </Row>
            </Col>
            <Col xs={24} lg={12}>
                <Row>
                    <Col xs={8}><Button size="small" block type="ghost" disabled={reportTypes?.includes(31)} onClick={()=>handleAddReport({reportType: 31})} className="first">Kargo Raporu Ekle</Button></Col>
                    <Col xs={8}><Button size="small" block type="ghost" disabled={reportTypes?.includes(31)} onClick={()=>handleAddReport({reportType: 31})} className="middle">En Çok Kargo Raporu Ekle</Button></Col>
                    <Col xs={8}><Button size="small" block type="ghost" disabled={reportTypes?.includes(32)} onClick={()=>handleAddReport({reportType: 32})} className="last">En Az Kargo Raporu Ekle</Button></Col>
                </Row>
            </Col>
            <Col xs={24} lg={12}>
                <Row>
                    <Col xs={8}><Button size="small" block type="ghost" disabled={reportTypes?.includes(41)} onClick={()=>handleAddReport({reportType: 41})} className="first">İade Raporu Ekle</Button></Col>
                    <Col xs={8}><Button size="small" block type="ghost" disabled={reportTypes?.includes(41)} onClick={()=>handleAddReport({reportType: 41})} className="middle">En Çok İade Raporu Ekle</Button></Col>
                    <Col xs={8}><Button size="small" block type="ghost" disabled={reportTypes?.includes(42)} onClick={()=>handleAddReport({reportType: 42})} className="last">En Az İade Raporu Ekle</Button></Col>
                </Row>
            </Col>
        </Row>
      </Row>
    </Card>
  );
};

export default CompareReportCard;
