import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/hizlihesap/actions";
import { Carousel, Col, Image, Row } from 'antd'
import axios from 'axios'
import dolar from '../..//assets/images/icons/dolar.png'
import euro from '../../assets/images/icons/euro.png'
import gold from '../../assets/images/icons/gold.png'
import silver from '../../assets/images/icons/silver.png'
import { getExchange } from './api';



const titleStyle = {
  color: '#ff5c09',
  fontSize: 10,
  fontWeight: 600,
}
const infoStyle = {
  fontSize: 11,
}


const ExchangeCarousel = (props) => {
  const [exchange, setExchange] = useState([])

  useEffect(() => {
    if (exchange.length !== 0) return
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source()

    getExchangeData(source)
    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exchange])

  const getExchangeData = async (source) => {
    const response = await getExchange({ cancelToken: source.token })

    const exchange_ = {
      usd: response.data.KurBilgisi.filter((data) => data.Kod === 'USD')[0],
      euro: response.data.KurBilgisi.filter((data) => data.Kod === 'EUR')[0],
      gold: response.data.MadenFiyatlari[0].altin,
      silver: response.data.MadenFiyatlari[0].gumus,
    }
    setExchange(exchange_)
  }

  return (
    <Carousel dotPosition={"right"} autoplay dots={false} style={{ width: 285 }}>

      <div>
        <Row align='middle'>
          <Col xs={6}>
            <Image width={30} preview={false} src={dolar} />
          </Col>
          <Col xs={18} style={{marginTop: 10}}>
            <span style={titleStyle}>DOLAR</span><br/>
            <span style={infoStyle}><b>ALIŞ </b> {exchange?.usd?.ForexBuying} ₺</span>&nbsp;&nbsp;
            <span style={infoStyle}><b>SATIŞ </b> {exchange?.usd?.ForexSelling} ₺</span>
          </Col>
        </Row>
      </div>
      <div>
        <Row align='middle'>
          <Col xs={6}>
            <Image width={30} preview={false} src={euro} />
          </Col>
          <Col xs={18} style={{marginTop: 10}}>
            <span style={titleStyle}>EURO</span><br />
            <span style={infoStyle}><b>ALIŞ </b> {exchange?.euro?.ForexBuying} ₺</span>&nbsp;&nbsp;
            <span style={infoStyle}><b>SATIŞ </b> {exchange?.euro?.ForexSelling} ₺</span>
          </Col>
        </Row>
      </div>
      <div>
        <Row align='middle'>
          <Col xs={6}>
            <Image width={30} preview={false} src={gold} />
          </Col>
          <Col xs={18} style={{marginTop: 10}}>
            <span style={titleStyle}>ALTIN (ONS)</span><br />
            <span style={infoStyle}>{exchange?.gold} ₺</span>
          </Col>
        </Row>
      </div>
      <div>
        <Row align='middle'>
          <Col xs={6}>
            <Image width={30} preview={false} src={silver} />
          </Col>
          <Col xs={18} style={{marginTop: 12}}>
            <span style={titleStyle}>GÜMÜŞ (ONS)</span><br />
            <span style={infoStyle}>{exchange?.silver} ₺</span>
          </Col>
        </Row>
      </div>
    </Carousel>
  )
}


const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(ExchangeCarousel));