import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { getUser } from "../api";
import ContactPreferences from "./ContactPreferences";
import EmailPreferences from "./EmailPreferences";
import ProfileInfo from "./ProfileInfo";
import UpdatePassword from "./UpdatePassword";

const ProfileContent = () => {
  const [user, setUser] = useState({});

  useEffect(() => {
    const userId = JSON.parse(window.localStorage.getItem("user")).id;
    handleGetUser(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetUser = async (id) => {
    try {
      const response = await getUser(id);
      if (response.status === 200) {
        setUser(response.data);
        return;
      }
      console.log("error : ", response);
    } catch (error) {
      console.log("catch error : ", error);
    }
  };

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={14} >
          <ProfileInfo user={user} updateUser={handleGetUser} />
        </Col>
        <Col xs={24} lg={10}>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <UpdatePassword />
            </Col>
            <Col xs={24}>
              <ContactPreferences user={user} updateUser={handleGetUser} />
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <EmailPreferences user={user} updateUser={handleGetUser} />
        </Col>
      </Row>
    </div>
  );
};

export default ProfileContent;
