import { Card, Col, Row } from 'antd'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { getReportData } from './api'
import CityBarPlot from './CityBarPlot'
import CreateRaport from './CreateRaport'
import MostUsedReports from './MostUsedReports'
import TurkishMapChart from './TurkishMapChart'

const ReportsContent = () => {
  const [data, setData] = useState()
  const[startDate, setStartDate] = useState(moment().startOf('week').valueOf())
  const[endDate, setEndDate] = useState(moment().valueOf())


  useEffect(() => {
    
    const reportdata = async () => {
      try {
        const payload = {
          startDate: startDate,
          endDate: endDate,
        }
        const response = await getReportData(payload)
        setData(response.data)
      } catch (error) {
        console.log("ERROR: ", error)
      }
    }
    reportdata()
  }, [startDate, endDate])
  
  const getRangeDate = (rangeData) => {
    setStartDate(rangeData[0].valueOf())
    setEndDate(rangeData[1].valueOf())
  }

  return (
    <Card>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <CreateRaport rangeData={getRangeDate} />
        </Col>
        <Col xs={16}>
          <TurkishMapChart data={data} />
          <MostUsedReports />
        </Col>
        <Col xs={8}>
          <CityBarPlot data={data} />
        </Col>
      </Row>
    </Card>
  )
}

export default ReportsContent