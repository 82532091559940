import { protectedApi } from "../../services/axios";


export const getUser = async (id) => {
  try {
    const response = await protectedApi.get(`management/user/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const uploadFiles = async (payload) => {
  try {
    const response = await protectedApi.post(`management/files`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const getSupportsByCompany = async ({company_id, status, topicFilter, offset, limit}) => {
  let params = ""
  if(status.length !== 0){
    params = params + `&status__in=${status}`
  }
  if(topicFilter && topicFilter !== ""){
    params = params + `&search=${topicFilter}`
  }
  try {
    const response = await protectedApi.get(`management/supports?offset=${(offset-1)* limit}&limit=${limit}&createdBy__profile__company__id=${company_id}${params}`);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const createSupport = async (payload) => {
  try {
    const response = await protectedApi.post(`management/supports`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const getSupport = async (id) => {
  try {
    const response = await protectedApi.get(`management/support/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const updateSupport = async (id, payload) => {
  try {
    const response = await protectedApi.put(`management/support/${id}`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}


export const getCommentsBySupport = async (support_id) => {
  try {
    const response = await protectedApi.get(`management/supportcomments?support__id=${support_id}`);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const createSupportComment = async (payload) => {
  try {
    const response = await protectedApi.post(`management/supportcomments`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}