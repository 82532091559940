import React, { useEffect } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../../redux/hizlihesap/actions";
import { Card, Col, Row, Switch } from 'antd';
import { updateComChannel } from '../api';
import { showNotification } from '../../../utils/showNotification';

const ContactPreferences = (props) => {

  useEffect(() => {
    if (Object.keys(props.user).length === 0) return
  },[props.user])

  const onChange = async (checked) => {
    props.setRedux({loading: true})
    try {
      const response = await updateComChannel(props.user.profile.comChannels.id, {isPhone: checked})
      if (response.status === 200) {
        props.updateUser(props.user.id)
        showNotification('success', 'Güncellendi!', 'İletişim tercihleriniz başarıyla güncellendi!')
      }

    } catch (error) {
      console.log('error: ', error)
      showNotification('error', 'HATA!', 'Beklenmeyen bir hata gerçekleşti!')
    }
    props.setRedux({loading: false})
  }


  return (
    <Card>
      <Row>
        <Col xs={24} lg={{ span: 20, offset: 2 }}>
          <Row gutter={[24,24]}>
            <Col xs={24} align='left'>
              <span className='page-title-style'>İletişim Tercihleri</span>
            </Col>
            <Col xs={20} style={{height: "85px"}}>
            <span>Hızlı hesap üzerinde sizlerin <b>sorularınızı cevaplamak</b> ve <b>farklı süreçlerinizde yardımcı olmak</b> amacıyla size telefon aracılığıyla ulaşmak istiyoruz.</span>
            </Col>
            <Col xs={4} align='right'>
              <Switch checked={props.user?.profile?.comChannels.isPhone} onChange={onChange} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}
const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(ContactPreferences));