import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../../redux/hizlihesap/actions";
import { Button, Card, Col, Row, Table } from "antd";

import SubscriptionCard from "../../../components/account/SubscriptionCard";
import SubcriptedContent from "./SubcriptedContent";

const dummyData = [
  { id: 0, name: "", free: "Freemium", pre: "Premium", entr: "Enterprise" },
  { id: 1, name: "Özellikler", free: "", pre: "", entr: "" },
  { id: 2, name: "Kullanıcı Sayısı", free: ".", pre: ".", entr: "." },
  { id: 3, name: "Kullanıcı Sayısı", free: ".", pre: ".", entr: "." },
  { id: 4, name: "Kullanıcı Sayısı", free: "x", pre: "x", entr: "x" },
  { id: 5, name: "Kullanıcı Sayısı", free: "x", pre: "x", entr: "x" },
  { id: 6, name: "Kullanıcı Sayısı", free: "x", pre: "x", entr: "x" },
  { id: 7, name: "Kullanıcı Sayısı", free: "x", pre: "x", entr: "x" },
  { id: 8, name: "Kullanıcı Sayısı", free: "x", pre: "x", entr: "x" },
  { id: 9, name: "Kullanıcı Sayısı", free: "x", pre: "x", entr: "x" },
];
const columns = [
  {
    title: "Özellikler",
    dataIndex: "name",
    key: "name",
    onCell: (record, index) => {
      return { className: index === 0 ? "header" : index === 1 ? "th" : "" };
    },
  },
  {
    title: "Freemium",
    dataIndex: "free",
    key: "free",
    onCell: (record, index) => {
      return { className: index === 0 ? "header" : index === 1 ? "th" : "" };
    },
    render: (text, record, index) => (
      <Row>
        {index === 0 ? (
          <SubscriptionCard
            title="Freemium"
            description={
              <span className="description"
                style={{color: "#7F7F7F"}}
              >
                15 Gün boyunca
                <span style={{ color: "#40ce46" }}> ÜCRETSİZ </span>
                deneyin
              </span>
            }
            detail={<span className="detail">1 Kullanıcıya Kadar Raporlama<br/><br/><br/><br/></span>}
            price={
              <span className="price"
                style={{color: "#40ce46"}}
              >
                0₺ <span>/ AY</span>
              </span>
            }
            button="Ücretsiz Deneyin"
            color="#40ce46"
          />
        ) : (
          text
        )}
      </Row>
    ),
  },
  {
    title: "Premium",
    dataIndex: "pre",
    key: "pre",
    onCell: (record, index) => {
      return { className: index === 0 ? "header" : index === 1 ? "th" : "" };
    },
    render: (text, record, index) => (
      <Row>
        {index === 0 ? (
          <SubscriptionCard
            title="Premium"
            description={
              <span className="description"
                style={{color: "#ff5c09"}}
              >
                En Popüler
              </span>
            }
            detail={<span className="detail"><span className="text-orange">Yıllık Ödeyin + 2 Ay Ücretsiz Ödeyin</span><br/> Limitsiz Rapor Kullanımı<br/>2 Kullanıcıya Kadar<br/>Detaylı Raporlama</span>}
            price={
              <span className="price"
                style={{color: "#ff5c09"}}
              >
                149₺{" "}
                <span>/ AY</span>
              </span>
            }
            button="Satışlarınızı Arttırın"
            color="#ff5c09"
          />
        ) : (
          text
        )}
      </Row>
    ),
  },
  {
    title: "Enterprise",
    dataIndex: "entr",
    key: "entr",
    onCell: (record, index) => {
      return { className: index === 0 ? "header" : index === 1 ? "th" : "" };
    },
    render: (text, record, index) => (
      <Row>
        {index === 0 ? (
          <SubscriptionCard
            title="Enterprise"
            description={
              <span className="description"
                style={{color: "#42358D"}}
              >
                Size özel tasarlanmış
              </span>
            }
            detail={<span className="detail"><span className="text-orange">Yıllık Ödeyin + 2 Ay Ücretsiz Ödeyin</span><br/>Tüm İhtiyaçlarınıza Tıolu Çözüm<br/>5 Kullanıcıya Kadar<br/>Sipariş Yönetimi</span>}
            price={
              <span className="price"
                style={{color: "#42358D"}}
              >
                299₺{" "}
                <span>/ AY</span>
              </span>
            }
            button="Fark Yaratın"
            color="#42358D"
          />
        ) : (
          text
        )}
      </Row>
    ),
  },
];

const SubscriptionContent = () => {
  return (
    <>
      <Card className="subscription-content" style={{marginBottom: 50} /* subcripted content ayrı görünsün diye geçici alta margin verildi */}> 
        <Row gutter={[24, 8]}>
          <Col xs={24} lg={{ span: 22, offset: 2 }}>
            <Row gutter={[8, 8]}>
              <Col xs={24} className="title">
                <span>Tüm Fiyat ve Özellikler</span>
              </Col>
              <Col xs={24} className="content">
                <span>
                  Free planı 15 gün kullanabilirsiniz, özellikleri kısıtlı olarak
                  görebilirsiniz. <br />
                  Diğer paket özelliklerini aşağıdan inceleyebilirsiniz.
                </span>
              </Col>
              <Col xs={24} className="period">
                <Button className="active" size="small">
                  AYLIK
                </Button>
                <Button size="small">YILLIK</Button>
              </Col>
            </Row>
          </Col>
          <Col xs={24}>
            <Table
              id="subcription-packages"
              rowKey={(record) => record.id}
              showHeader={false}
              scroll={{ x: true }}
              columns={columns}
              pagination={false}
              dataSource={dummyData}
              size="small"
            />
          </Col>
        </Row>
      </Card>
      <SubcriptedContent />
    </>
  );
};

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(SubscriptionContent));
