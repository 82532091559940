import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../../redux/hizlihesap/actions";
import { UserOutlined } from '@ant-design/icons'
import { Button, Card, Col, Form, Image, Input, Row, Upload } from 'antd'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import tempImg from '../../../assets/images/icons/user.svg'
import { updateProfile, updateUser } from '../api';
import { showNotification } from '../../../utils/showNotification';
import ImgCrop from 'antd-img-crop';

const ProfileInfo = (props) => {
  const [isEditable, setIsEditable] = useState(false)
  const [user, setUser] = useState({})
  const [countryCode, setCountryCode] = useState("")


  const [form] = Form.useForm()



  const handleButton = () => {
    if (isEditable) {
      form.submit()
    } else {
      setIsEditable(true)
    }
  }

  const onFinish = async (values) => {
    props.setRedux({ loading: true })
    const userPayload = {
      first_name: values.first_name,
      last_name: values.last_name,
    }
    delete values.email
    delete values.first_name
    delete values.last_name
    values.isBoss = user.profile.isBoss
    values.countryCode = countryCode.toUpperCase()
    try {
      const response = await updateUser(user.id, userPayload)
      if (response.status === 200) {
        const profileUpdate = await updateProfile(user.id, values)
        if (profileUpdate.status === 200) {
          showNotification('success', 'Güncellendi!', 'Kullanıcı bilgileriniz başarıyla güncellendi!')
        } else {
          showNotification('error', 'HATA!', 'Beklenmeyen bir hata gerçekleşti!')
        }
        props.updateUser(user.id)
      } else {
        showNotification('error', 'HATA!', 'Beklenmeyen bir hata gerçekleşti!')
      }
    } catch (error) {

    }
    setIsEditable(false)
    props.setRedux({ loading: false })
  }


  const uploadImage = async (info) => {
    return
    // ANTD 4.20.0 sürümü ile birlikte gelen özellikler sebebiyle onChange üzerinden
    // yapılan geliştirmeler image-crop işlemi yapmamıza müsade etmiyordu.
    // Bu sorunu gidermek adına customRequest fonksiyonumuzda direkt servise giderek sorunu çözdük.
  }

  const beforeUpload = (file) => {
    return true
  }
  const customRequest = async (info) => {
    // const { onSuccess } = options;
    // onSuccess("Ok");

    if (!info.file.type.includes('image')) {
      showNotification('error', 'Geçersiz Dosya!', 'Lütfen yalnızca fotoğraf yükleyin!')
      return
    }
    props.setRedux({ loading: true })
    const fData = new FormData()
    fData.append('photo', info.file)
    fData.append('isBoss', user.profile.isBoss)
    try {
      const response = await updateProfile(user.id, fData)
      if (response.status === 200) {
        props.updateUser(user.id)
        props.setRedux({ reRender: true })
        setTimeout(() => { props.setRedux({ reRender: false }) }, 1000)
        showNotification('success', 'Güncellendi!', 'Profil fotoğrafınız başarıyla güncellendi!')
      }
    } catch (error) {
      console.log('error: ', error)
      showNotification('error', 'HATA!', 'Beklenmeyen bir hata gerçekleşti!')
    }
    props.setRedux({ loading: false })
  }
  useEffect(() => {
    const user_ = props.user
    if (Object.keys(user_).length === 0) return
    form.setFieldsValue({
      first_name: user_.first_name,
      last_name: user_.last_name,
      email: user_.email,
      phone: user_.profile?.phone,
      title: user_.profile?.title
    })
    setUser(user_)
  }, [form, props.user])
  return (
    <Card style={{height: "100%"}}>
      <Row>
        <Col xs={24} lg={{ span: 20, offset: 2 }}>
          <Form
            name="profile-form"
            form={form}
            initialValues={{}}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            layout='vertical'
            autoComplete="off">
            <Row>
              <Col xs={24} align='left'>
                <span className='page-title-style'>Profil Bilgileri</span>
              </Col>
             
              <Col xs={24}>
                <Image className={isEditable && 'cursor-p'} style={{ borderRadius: '50%' }} onClick={() => isEditable && document.getElementById('upload-image').click()} preview={false}
                  src={user.profile?.photo ? user.profile.photo : tempImg} width={150} />
                <div style={{ display: 'none' }}>
                  <ImgCrop rotate zoom shape={'round'} modalTitle={'Fotoğrafı Düzenle'} modalOk={'Tamam'} modalCancel={'İptal'}>
                    <Upload id='upload-image' onChange={uploadImage}
                      beforeUpload={beforeUpload}
                      customRequest={customRequest}>FOTOĞRAF YÜKLE</Upload>
                  </ImgCrop>
                </div>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Ad" name="first_name"
                  rules={[{ required: true, message: 'Lütfen adınızı giriniz!' },]}>
                  <Input suffix={<UserOutlined />} size='large' placeholder='Ad' disabled={!isEditable} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Soyad" name="last_name"
                  rules={[{ required: true, message: 'Lütfen soyadınızı giriniz!' },]}>
                  <Input suffix={<UserOutlined />} size='large' placeholder='Soyad' disabled={!isEditable} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                {user.profile !== undefined &&
                  <Form.Item label="Telefon" name="phone"
                    rules={[{ required: false, message: 'Lütfen telefon giriniz!' }]}>
                    <PhoneInput
                      country={"tr"}
                      onChange={(value, country) => { setCountryCode(country.countryCode) }}
                      masks={{ tr: '(...) ... .. ..' }}
                      inputClass={"phone-input"}
                      buttonClass={"phone-input-flag"}
                      disabled={!isEditable}
                    />
                  </Form.Item>
                }
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="E-posta" name="email">
                  <Input suffix={<UserOutlined />} disabled={true} size='large' placeholder='E-posta' />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Ünvan" name="title"
                  rules={[{ required: false, message: 'Lütfen ünvanınızı giriniz!' },]}>
                  <Input suffix={<UserOutlined />} size='large' placeholder='Ünvan' disabled={!isEditable} />
                </Form.Item>
              </Col>
              <Col xs={24} align='right'>
              <Form.Item>
                <Button  size='large' type="primary" onClick={handleButton}>
                  {isEditable ? 'Kaydet' : 'Düzenle'}
                </Button>
              </Form.Item>
            </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Card>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(ProfileInfo));