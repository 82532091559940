import { protectedApi } from '../../services/axios'


export const getReportData = async (payload) => {
  try {
    const response = await protectedApi.post(`management/reportdata`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const getCompareReportData = async (payload) => {
  try {
    const response = await protectedApi.post(`management/comparereport`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}
