import { publicApi } from '../../services/axios'

// Register API
export const signUp = async (payload) => {
  try {
    const response = await publicApi.post('/pass/register/', payload);
    return response;
  } catch (error) {
    return error.response;
  }
}

// Login API
export const logIn = async (payload) => {
  try {
    const response = await publicApi.post('/pass/login/', payload);
    return response;
  } catch (error) {
    return error.response;
  }
}

// Forgot Password API
export const forgotPassword = async (payload) => {
  try {
    const response = await publicApi.post('/pass/reset/', payload);
    return response;
  } catch (error) {
    return error.response;
  }
}

// Reset Password API
export const resetPassword = async (payload) => {
  try {
    const response = await publicApi.post(`/pass/reset_confirm/${payload.uid}/${payload.token}/`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}