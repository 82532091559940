import {
  FallOutlined,
  RiseOutlined,
  SwapRightOutlined,
} from "@ant-design/icons";
import { Card, Col, Progress, Row } from "antd";
import React from "react";

const InfoCard = (props) => {

  return (
    <Card className="card-shadow">
      <Row>
        <Col xs={24} md={12}>
          <Row gutter={[8, 8]}>
            <Col xs={24}>
              <b>
                <span>{props.title}</span>
              </b>
            </Col>
            <Col xs={24}>
              <span className="font-w700" style={{ fontSize: 22 }}>
                {props.value}
              </span>
            </Col>
            <Col xs={24}>
              <span>Haftalık Değişim</span>
            </Col>
            <Col xs={24}>
              {props.monthlyChange > 0 ? (
                <RiseOutlined style={{ fontSize: 20, color: "#09B66D" }} />
              ) : props.monthlyChange === 0 ? (
                <SwapRightOutlined style={{ fontSize: 20, color: "#09B66D" }} />
              ) : (
                <FallOutlined
                  style={{ fontSize: 20, color: "rgb(236, 2, 63)" }}
                />
              )}{" "}
              &nbsp; &nbsp;
              <span
                style={{
                  padding: "2px 6px",
                  borderRadius: 20,
                  backgroundColor: props.monthlyChange < 0 ? "rgb(236, 2, 63)" : "#09B66D" ,
                  color: "#FFFFFF",
                  fontSize: 12,
                }}
              >
                %{props.monthlyChange}
              </span>
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={12} align="middle">
          <Progress
            type="circle"
            percent={ props.dailyChange < 0 ? props.dailyChange * -1 : props.dailyChange }
            status={props.dailyChange === -100 ? "exception" : "normal"}
            strokeColor={ props.dailyChange < 0 ? "#ec023f" : "#09B66D" }
          />
          <br />
          <span style={{ fontSize: 14 }}>Günlük Değişim</span>
        </Col>
      </Row>
    </Card>
  );
};

export default InfoCard;
