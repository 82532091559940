import React, { useEffect, useState } from 'react'
import { useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../../redux/hizlihesap/actions";
import { Button, Col, Form, Image, Input, Row } from 'antd'
import logo from '../../../assets/images/logo/hepsiburada.png'
import { createMarketPlace, getMarketPlaceApiByUserAndMarketPlace } from '../api';
import { isHaveAccess } from '../../../utils/Permission';
import { showNotification } from '../../../utils/showNotification';

const HepsiburadaContent = (props) => {
  const [isConnected, setIsConnected] = useState(false)

  const [form] = Form.useForm()

  const history = useHistory()

  useEffect(() => {
    const user_id = JSON.parse(window.localStorage.getItem('user')).id
    const access = async () => {
      const perms = await isHaveAccess(user_id, "integration")
      if(!perms){
        history.push('/')
        showNotification('error', 'Yetkiniz Yok!', 'Gitmek istediğiniz sayfaya yetkiniz bulunmamaktadır!')
        return
      }
    }
    access()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFinish = async (values) => {
    props.setRedux({ loginLoading: true })
  values.user = JSON.parse(window.localStorage.getItem('user')).id
  values.marketPlace = 2 // id = 2  -> management/marketplace servisinde hepsiburada idsi
  console.log('VALUES : ', values)
  try {
    const response = await createMarketPlace(values)
    console.log('RESPONSE : ', response)
  } catch (error) {
    
  }

  props.setRedux({ loginLoading: false })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    const user = JSON.parse(window.localStorage.getItem('user'))
    checkIsConnected(user.id)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const checkIsConnected = async (id) => {
    try {
      const response = await getMarketPlaceApiByUserAndMarketPlace({user_id: id, marketPlace_id: 2})
      console.log('RESPONSE : ', response)
      if(response.data.count === 0){
        // Daha önce Bağlanmamış 
        return
      }
      setIsConnected(true)
      form.setFieldsValue({
        customerID: response.data.results[0].customerID,
        apiKey: response.data.results[0].apiKey,
        apiSecret: response.data.results[0].apiSecret,
      })
    } catch (error) {
      console.log('error : ', error)
    }
    props.setRedux({loading: false})
  }


  return (
    <div style={{ paddingTop: 90 }}>
      <Row>
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 8, offset: 7 }} align='center'>
          <Row>
            <Col xs={24} style={{borderRadius: 8, backgroundColor: '#FFFFFF'}}>
              <Row>
                <Col xs={24}>
                  <Image preview={false} src={logo} width={250} style={{
                    border: '4px solid #ff5c09',
                    borderRadius: 40,
                    boxShadow: '0 20px 30px #9d9d9d38',
                    zIndex: 5,
                    marginTop: '-70px'
                  }} />
                  <p className='font-w600 font-s32 my-20'>Hepsiburada API</p>
                  <span style={{ backgroundColor: '#ff5c09', color: '#FFFFFF', padding: '4px 8px', borderRadius: 10 }}>Pasif</span>
                  <br /><br />
                </Col>
                <Col xs={24} style={{ backgroundColor: '#F8FAFB', borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }} align='left'>
                  <Form
                    name="basic"
                    form={form}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout='vertical'
                    autoComplete="off">
                    <Row className='my-20'>
                      <Col xs={{ span: 18, offset: 3 }}>
                        <Form.Item label="Kullanıcı Adı" name="username"
                          rules={[
                            { required: true, message: 'Lütfen kullanıcı adı giriniz!' },]}>
                          <Input size='large' placeholder='Kullanıcı Adı' disabled={isConnected} />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 18, offset: 3 }}>
                        <Form.Item label="Şifre" name="password"
                          rules={[{ required: true, message: 'Lütfen şifre giriniz!' }]}>
                          <Input type='password' size='large' placeholder='Şifre' disabled={isConnected} />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 18, offset: 3 }}>
                        <Form.Item label="Merchant ID" name="merchantId"
                          rules={[{ required: true, message: 'Lütfen api secret giriniz!' }]}>
                          <Input size='large' placeholder='Merchant ID' disabled={isConnected} />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 18, offset: 3 }}>
                        <Form.Item>
                          <Button block size='large' type="primary" onClick={() => {
                            if (isConnected) {
                              setIsConnected(false)
                            } else {
                              form.submit()
                            }
                          }}>
                            {isConnected ? 'Düzenle' : 'Bağlan'}
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(HepsiburadaContent));