import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import { setRedux } from "../../../redux/hizlihesap/actions";
import { useHistory, withRouter } from 'react-router-dom'
import { Card, Col, Row, Tag } from 'antd'
import info from '../../../utils/marketplaces.json'
import { getMarketPlaceApiByUserAndMarketPlace } from '../api'
import Information from '../../../utils/Information'


// eslint-disable-next-line no-lone-blocks
{/* <span className='font-w700 cursor-p'>
<BsFillCircleFill size={24} style={{ borderRadius: '50%', color: 'red', verticalAlign: 'middle', marginTop: '-4px' }} />&nbsp;&nbsp;Trendyol</span> */}



const Integration = (props) => {
  const [connections, setConnections] = useState({
    trendyol: false,
    hepsiburada: false,
    n11: false,
    ciceksepeti: false,
    amazon: false,
    etsy: false,
  })
  const history = useHistory();

  useEffect(() => {
    const user = JSON.parse(window.localStorage.getItem('user'))
    checkIsConnected(user.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const checkIsConnected = async (user_id) => {
    const response = await getMarketPlaceApiByUserAndMarketPlace({ user_id: user_id, marketPlace_id: info.marketplaces.Trendyol })
    if (response.data.results.length === 0) return
    if (response.status !== 200) return
    let state = { ...connections }
    state.trendyol = response.data.results[0].isActive
    setConnections(state)
  }

  const goApiPage = (integration) => {
    history.push(`/integration/${integration}`)
  }

  

  return (
    <Card>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <span className='page-title-style'>Aktif Pazar Yerleri</span> &nbsp;&nbsp;&nbsp; <Information />
        </Col>
        <Col xs={12} lg={4}>
          <Row align='middle'>
            <Col xs={8} className='cursor-p' onClick={() => goApiPage('trendyol')}>
              {connections.trendyol ?
                <Tag style={{lineHeight: "22px"}} color={"#09B66D"}>Aktif</Tag>
                :
                <div className='circle-outer'>
                  <div className={"circle-gray"} />
                </div>
              }
            </Col>
            <Col xs={12}>
              <span className='font-w700 cursor-p cursor-p' onClick={() => goApiPage('trendyol')}>Trendyol</span>
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg={4}>
          <Row align='middle'>
            <Col xs={connections.hepsiburada ? 10 : 5} className='cursor-p' onClick={() => goApiPage('hepsiburada')}>
              {connections.hepsiburada ?
                <Tag color={"#09B66D"}>Aktif</Tag>
                :
                <div className='circle-outer'>
                  <div className='circle-gray' />
                </div>
              }
            </Col>
            <Col xs={12}>
              <span className='font-w700 cursor-p cursor-p' onClick={() => goApiPage('hepsiburada')}>Hepsiburada</span>
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg={4}>
          <Row align='middle'>
            <Col xs={5} className='cursor-p'>
              <div className='circle-outer'>
                <div className='circle-gray' />
              </div>
            </Col>
            <Col xs={12}>
              <span className='font-w700 cursor-p cursor-p'>Çiçeksepeti</span>
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg={4}>
          <Row align='middle'>
            <Col xs={5} className='cursor-p'>
              <div className='circle-outer'>
                <div className='circle-gray' />
              </div>
            </Col>
            <Col xs={12}>
              <span className='font-w700 cursor-p cursor-p'>Amazon</span>
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg={4}>
          <Row align='middle'>
            <Col xs={5} lg={{ span: 5, offset: 2 }} className='cursor-p'>
              <div className='circle-outer'>
                <div className='circle-gray' />
              </div>
            </Col>
            <Col xs={12}>
              <span className='font-w700 cursor-p cursor-p'>N11</span>
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg={4}>
          <Row align='middle'>
            <Col xs={5} className='cursor-p'>
              <div className='circle-outer'>
                <div className='circle-gray' />
              </div>
            </Col>
            <Col xs={12}>
              <span className='font-w700 cursor-p cursor-p'>Etsy</span>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(Integration));