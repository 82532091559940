import axios from "axios";
import { protectedApi } from "../../services/axios";

export const getExchange = async ({ cancelToken }) => {
  try {
    const response = await protectedApi.get("management/tcmb/", {
      cancelToken: cancelToken,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getBestSellers = async ({ cancelToken }) => {
  try {
    const response = await protectedApi.post("management/bestsellers", {
      cancelToken: cancelToken,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getBlogsData = async () => {
  try {
    const response = await axios.get(
      "https://www.hizlihesap.com/wp-json/wp/v2/posts"
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getBlogImg = async (id) => {
  try {
    if (id !== 0){
      const response = await axios.get(
        `https://www.hizlihesap.com/wp-json/wp/v2/media/${id}`
        );
        return response;
    } else {
      const response = {data:{link:""}};
      return response;
    }

  } catch (error) {
    return error.response;
  }
};
export const getBlogData = async (payload) => {
  try {
    // const response = await protectedApi.post(`management/blogdata`, payload);
    const response = await axios.get(
      `https://www.hizlihesap.com/wp-json/wp/v2/posts/${payload.blogId}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getMarketPlaceApiByUserAndMarketPlace = async ({
  user_id,
  marketPlace_id,
}) => {
  try {
    const response = await protectedApi.get(
      `management/marketplaceapis?marketPlace=${marketPlace_id}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
