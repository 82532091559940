import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../../../redux/hizlihesap/actions";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import { FaCcVisa } from "react-icons/fa";
import { SiMastercard } from "react-icons/si";
import { showNotification } from "../../../../utils/showNotification";
import { getUser } from "../../api";

import cities from "../../../../utils/cities.json";
import { Fragment } from "react";

const PurchaseContent = (props) => {
  const [isDiscountApplied, setIsDiscountApplied] = useState(false);
  const [couponCode, setCouponCode] = useState(null);

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    console.log("VALUES: ", values);
  };

  const handleApplyDiscount = () => {
    console.log("handleapply", couponCode);
    props.setRedux({ loading: true });
    if (couponCode === null) {
      showNotification("error", "Kupon Girin!", "Lütfen kupon kodu girin!");
      return;
    }
    setTimeout(() => {
      setIsDiscountApplied(true);
      props.setRedux({ loading: false });
    }, 1000);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    handleApplyDiscount();
    setIsModalOpen(false);
  };
  
  const cityOpts = cities.map((city) => {
    return (
      <Select.Option key={city.id} value={city.name}>
        {city.name}
      </Select.Option>
    );
  });


  useEffect(() => {
    const handleGetUser = async () => {
      const info = JSON.parse(window.localStorage.getItem("user"));
      const user = await getUser(info.id);

      form.setFieldsValue({
        first_name: user.data.first_name,
        last_name: user.data.last_name,
        email: user.data.email,
        phone: user.data.profile.phone,
      });
    };

    handleGetUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMaxLen = (event) => {
    const {maxLength, value} = event.target;
    if (value.length > maxLength) {
      event.target.value = value.slice(0, maxLength);
    }
    
  };

  return (
    <Form
      name="purchase-form"
      form={form}
      initialValues={{}}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
    >
      <Row gutter={[8, 8]} className="purchase-content">
        <Col xs={24}>
          <Card className="border bg-white height-100">
            <Row>
              <Col align="left">
                <span className="page-title">Ödeme</span>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} lg={16}>
          <Card className="border bg-white height-100">
            <Row>
              <Col xs={24} align="left">
                <span className="page-title-style">Fatura Detayları</span>
              </Col>
            </Row>
            <Row /* className="my-20" */ gutter={[24, 0]}>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item
                  label="Ad"
                  name="first_name"
                  rules={[{ required: true }]}
                >
                  <Input
                    suffix={<UserOutlined />}
                    size="large"
                    placeholder="Ad"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item
                  label="Soyad"
                  name="last_name"
                  rules={[{ required: true }]}
                >
                  <Input
                    suffix={<UserOutlined />}
                    size="large"
                    placeholder="Soyad"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item
                  label="TC Kimlik Numarası (isteğe bağlı)"
                  name="idNumber"
                  rules={[{ required: false, }]}
                >
                  <Input
                    size="large"
                    placeholder="TC Kimlik Numarası"
                    maxLength={11}
                    type= "number"
                    onInput={handleMaxLen}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item
                  label="Firma Adı (isteğe bağlı)"
                  name="companyName"
                  rules={[{ required: false }]}
                >
                  <Input size="large" placeholder="Firma Adı" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item
                  label="Şehir"
                  name="city"
                  rules={[{ required: true }]}
                >
                  <Select size="large" placeholder="Şehir">
                    {cityOpts}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item
                  label="İlçe"
                  name="district"
                  rules={[{ required: true }]}
                >
                  <Input size="large" placeholder="İlçe" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item
                  label="Mahalle"
                  name="region"
                  rules={[{ required: true }]}
                >
                  <Input size="large" placeholder="Mahalle" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item
                  label="Posta Kodu"
                  name="postCode"
                  rules={[{ required: true }]}
                >
                  <Input size="large" placeholder="Posta Kodu" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={24}>
                <Form.Item
                  label="Açık Adres"
                  name="fullAddress"
                  rules={[{ required: true }]}
                  labelCol={4}
                  wrapperCol={20}
                >
                  <Input.TextArea
                    rows={2}
                    placeholder="Lütfen açık adresinizi girin"
                    maxLength={250} autoSize
                  />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item
                  label="Telefon"
                  name="phone"
                  rules={[{ required: true }]}
                >
                  <PhoneInput
                    country={"tr"}
                    regions={["europe"]}
                    masks={{ tr: "(...) ... .. .." }}
                    inputClass={"phone-input"}
                    buttonClass={"phone-input-flag"}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item
                  label="E-posta"
                  name="email"
                  rules={[{ required: true }]}
                >
                  <Input size="large" placeholder="E-posta" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item
                  label="Vergi Dairesi (isteğe bağlı)"
                  name="taxAdministration"
                  rules={[{ required: false }]}
                >
                  <Input size="large" type="number" placeholder="Vergi Dairesi" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item
                  label="Vergi Numarası (isteğe bağlı)"
                  name="taxNo"
                  rules={[{ required: false }]}
                >
                  <Input size="large" placeholder="Vergi Numarası" />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} lg={8}>
          <Card className="border bg-white height-100">
            <Row gutter={[0, 8]}>
              <Col xs={24} align="left">
                <p className="page-title-style">Siparişiniz</p>
              </Col>
              <Row
                style={{
                  border: "1px solid #f0f0f0",
                  borderRadius: "8px",
                  padding: "10px 0",
                }}
              >
                <Col xs={10} style={{ paddingLeft: "10px" }}>
                  <span>
                    <b>ÜRÜN</b>
                  </span>
                </Col>
                <Col xs={14}>
                  <span>
                    <b>ARA TOPLAM</b>
                  </span>
                </Col>
                <Col
                  xs={10}
                  style={{ background: "#f0f0f0", paddingLeft: "10px" }}
                >
                  <span>Premium - Aylık</span>
                </Col>
                <Col xs={14} style={{ background: "#f0f0f0" }}>
                  <span>800 ₺ / Ay (KDV dahil)</span>
                </Col>
                <Col xs={10} style={{ paddingLeft: "10px" }}>
                  <span>
                    <b>TOPLAM</b>
                  </span>
                </Col>
                <Col xs={14}>
                  <span
                    style={
                      isDiscountApplied
                        ? {
                            textDecoration: "line-through",
                            color: "rgba(0, 0, 0, 0.5)",
                          }
                        : {}
                    }
                  >
                    800 ₺ / Ay (KDV dahil)
                  </span>
                  <br />
                  <span>ilk yenileme 10 Ekim</span>
                </Col>
                {isDiscountApplied && (
                  <Fragment>
                    <Col xs={10} style={{ paddingLeft: "10px" }}>
                      <span>
                        <b>İNDİRİM TUTARI</b>
                      </span>
                    </Col>
                    <Col xs={14}>
                      <span>80 ₺</span>
                    </Col>
                    <Col xs={10} style={{ paddingLeft: "10px" }}>
                      <span>
                        <b>TOPLAM TUTAR</b>
                      </span>
                    </Col>
                    <Col xs={14}>
                      <span>
                        <b>720 ₺ / Ay</b> (KDV dahil)
                      </span>
                    </Col>
                  </Fragment>
                )}
              </Row>
            </Row>
            <Row className="my-20" gutter={[24, 24]}>
              <Col xs={24} lg={10}>
                <span>Kart Bilgileri</span>
              </Col>
              <Col xs={24} lg={14}>
                <Space>
                  <FaCcVisa size={24} />
                  <SiMastercard size={24} />
                </Space>
              </Col>
              <Divider style={{ borderTop: "1px solid #FF5C09", margin: 0 }} />
              <Col xs={24}>
                <div
                  style={{
                    border: "1px solid lightgray",
                    padding: 16,
                    borderRadius: 8,
                  }}
                >
                  <Row gutter={[24, 0]}>
                    <Col xs={{ span: 24, offset: 0 }}>
                      <Form.Item
                        label="Kart Numarası"
                        name="cardNumber"
                        rules={[
                          {
                            required: true,
                            message: "Lütfen kart numarasını giriniz!",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="**** **** **** ****"
                          maxLength={16}
                          type="text"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24, offset: 0 }} lg={12}>
                      <Space size={"small"}>
                        <Form.Item
                          name="mounth"
                          rules={[
                            {
                              required: true,
                              message: "Lütfen bir Ay seçiniz",
                            },
                          ]}
                        >
                          <Select
                            size="large"
                            allowClear
                            style={{ width: "100%" }}
                            placeholder="Ay"
                          >
                            <Select.Option value={1}>1</Select.Option>
                            <Select.Option value={2}>2</Select.Option>
                            <Select.Option value={3}>3</Select.Option>
                            <Select.Option value={4}>4</Select.Option>
                            <Select.Option value={5}>5</Select.Option>
                            <Select.Option value={6}>6</Select.Option>
                            <Select.Option value={7}>7</Select.Option>
                            <Select.Option value={8}>8</Select.Option>
                            <Select.Option value={9}>9</Select.Option>
                            <Select.Option value={10}>10</Select.Option>
                            <Select.Option value={11}>11</Select.Option>
                            <Select.Option value={12}>12</Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="year"
                          rules={[
                            {
                              required: true,
                              message: "Lütfen bir Yıl seçiniz",
                            },
                          ]}
                        >
                          <Select
                            size="large"
                            allowClear
                            style={{ width: "100%" }}
                            placeholder="Yıl"
                          >
                            <Select.Option value={22}>22</Select.Option>
                            <Select.Option value={23}>23</Select.Option>
                            <Select.Option value={24}>24</Select.Option>
                            <Select.Option value={25}>25</Select.Option>
                            <Select.Option value={26}>26</Select.Option>
                            <Select.Option value={27}>27</Select.Option>
                            <Select.Option value={28}>28</Select.Option>
                            <Select.Option value={29}>29</Select.Option>
                            <Select.Option value={30}>30</Select.Option>
                            <Select.Option value={31}>31</Select.Option>
                            <Select.Option value={32}>32</Select.Option>
                            <Select.Option value={33}>33</Select.Option>
                            <Select.Option value={34}>34</Select.Option>
                          </Select>
                        </Form.Item>
                      </Space>
                    </Col>
                    <Col xs={{ span: 24, offset: 0 }} lg={12}>
                      <Form.Item
                        name="cvc"
                        rules={[
                          {
                            required: true,
                            message:
                              "Lütfen kartın arkasındaki cvc kodu girin!",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="CVC" type={"number"} />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="termsAndConditions"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error("Şartları kabul etmelisiniz!")
                            ),
                    },
                  ]}
                >
                  <Checkbox>
                    Web sitesinin
                    <a
                      className="login-link"
                      target="_self"
                      href="/"
                      rel="noreferrer"
                    >
                      {" "}
                      şartlar ve koşullar
                    </a>{" "}
                    sayfasını okudum ve kabul ediyorum
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col xs={24} align="right">
                <Button type="primary" htmlType="submit">
                  Ödeme Yap
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} lg={16}>
          <Card>
            <Row className="my-20" gutter={[24, 24]}>
              <Col xs={24}>
                Kuponunuz var mı?{" "}
                <Link
                  to="#"
                  className="text-orange"
                  onClick={(e) => showModal(e)}
                >
                  Kupon kodunuzu girmek için buraya tıklayın.
                </Link>
              </Col>
              <Modal
                title="Kupon"
                visible={isModalOpen}
                onOk={handleOk}
                onCancel={closeModal}
              >
                <Row className="my-20" gutter={[24, 24]}>
                  <Col xs={14}>
                    <Input
                      id="coupon"
                      size="medium"
                      placeholder="Kupon girin"
                      onChange={(event) => setCouponCode(event.target.value)}
                    />
                  </Col>
                </Row>
              </Modal>
            </Row>
          </Card>
        </Col>
      </Row>
    </Form>
  );
};

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(PurchaseContent));
