import React from 'react'
import { Button, Col, Row } from 'antd'
import { useHistory } from 'react-router-dom'

const SubscriptionCard = (props) => {

  const history = useHistory()




  return (
    <div key={props.title} style={{
      backgroundColor: '#FFFFFF',
      backgroundImage: "none",
      background: 'transparent'
    }}>
      <Row gutter={[0, 8]} /* align='middle' */ className="subscription-card">
        <Col xs={24} align='center'>
          <span className='title'>{props.title}</span>
          {props.description}<br />
        </Col>
        <Col xs={24} align='center'>
          {props.price}
        </Col>
        <Col xs={24} align='center'>
          <Button className='purchase' onClick={() => history.push('/account/subscriptions/purchase')}
            style={{
              borderColor: props.color,
              color: props.color
            }}>{props.button}</Button>
        </Col>
        <Col xs={24} align='center'>
          {props.detail}
        </Col>
      </Row>
    </div>
  )
}

export default SubscriptionCard