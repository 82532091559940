import trendyolImg from "../assets/images/logo/trendyol.png";
import hepsiburadaImg from "../assets/images/logo/hepsiburada.png";
import n11Img from "../assets/images/logo/n11.png";
import cicekSepetiImg from "../assets/images/logo/cicek-sepeti.png";
import amazonImg from "../assets/images/logo/amazon.png";
import etsyImg from "../assets/images/logo/etsy.png";

export const MarketplaceDetails = [
    {id: 1, img: trendyolImg, color: "#ff5c09"},
    {id: 2, img: hepsiburadaImg, color: "#fad000"},
    {id: 3, img: n11Img, color: "#00c609"},
    {id: 4, img: cicekSepetiImg, color: "#008add"},
    {id: 5, img: amazonImg, color: "#41358d"},
    {id: 6, img: etsyImg, color: "#bbbbbb"},
]

export const ReportTypes = [
     "CİRO",
     "ADET",
     "KARGO",
     "İADE",
]