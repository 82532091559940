import { protectedApi } from '../../services/axios'


export const getMyProducts = async ({ user_id, offset, limit, brandsFilter, categoriesFilter, stocksFilter, searchKeyword, cancelToken }) => {
  try {
    const response = await protectedApi.get(`trendyol/products?limit=${limit}&offset=${(offset-1)* limit}&brand__in=${brandsFilter}&categoryName__in=${categoriesFilter}&alarmValue__in=${stocksFilter}&search=${searchKeyword}`, { cancelToken: cancelToken });
    return response;
  } catch (error) {
    return error.response;
  }
}

export const getAlarmSettings = async ({ productId }) => {
  try {
    const response = await protectedApi.get(`management/alarmsettings?productId=${productId}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const createAlarmSettings = async (payload) => {
  try {
    const response = await protectedApi.post(`management/alarmsettings`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const deleteAlarmSetting = async (id) => {
  try {
    const response = await protectedApi.put(`management/alarmsetting/${id}`, {isDeleted: true});
    return response;
  } catch (error) {
    return error.response;
  }
}

