import { protectedApi } from '../../services/axios'


export const getMarketPlaceApiByUserAndMarketPlace = async ({ user_id, marketPlace_id, cancelToken }) => {
  try {
    const response = await protectedApi.get(`management/marketplaceapis?marketPlace=${marketPlace_id}`, { cancelToken: cancelToken });
    return response;
  } catch (error) {
    return error.response;
  }
}

export const createMarketPlace = async (payload) => {
  try {
    const response = await protectedApi.post(`management/marketplaceapis`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const updateMarketPlace = async (id, payload) => {
  try {
    const response = await protectedApi.put(`management/marketplaceapi/${id}`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const verifyTrendyol = async (payload) => {
  try {
    const response = await protectedApi.post(`trendyol/verify`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const trendyolSaveProducts = async (payload) => {
  try {
    const response = await protectedApi.post(`trendyol/saveproducts`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const trendyolSaveOrders = async (payload) => {
  try {
    const response = await protectedApi.post(`/trendyol/saveorders`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}



