import { getUser } from "./api";

export const isHavePermission = (user, key) => {
  if (key.includes('integration')) {
    return user.profile.permissions.isIntegration;
  } else if (key === "product-stock-management") {
    return user.profile.permissions.isProductStockManager;
  } else if (key === "order-management") {
    return user.profile.permissions.isOrderManager;
  } else if (key === "payment-calendar") {
    return user.profile.permissions.isCalender;
  } else if (key === "reports") {
    return user.profile.permissions.isReports;
  } else if (key === "support") {
    return user.profile.permissions.isSupport;
  }

  return true
}

export const isHaveAccess = async (user_id, key) => {
  const response = await getUser(user_id);
  const user = response.data

  if (key === 'integration') {
    return user.profile.permissions.isIntegration;
  } else if (key === "product-stock-management") {
    return user.profile.permissions.isProductStockManager;
  } else if (key === "order-management") {
    return user.profile.permissions.isOrderManager;
  } else if (key === "payment-calendar") {
    return user.profile.permissions.isCalender;
  } else if (key === "reports") {
    return user.profile.permissions.isReports;
  } else if (key === "support") {
    return user.profile.permissions.isSupport;
  }
  return true
}