import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../../redux/hizlihesap/actions";
import React from 'react'
import { Button, Card, Col, Form, Input, Row } from 'antd'
import { strongRegex } from '../../../utils/Constants'
import { updatePassword } from "../api";
import { showNotification } from "../../../utils/showNotification";

const UpdatePassword = (props) => {


  const onFinish = async (values) => {
    props.setRedux({loading: true})
    try {
      const response = await updatePassword(values)
      console.log('RESPONSE : ', response)
      if(response.status === 200){
        showNotification('success', 'Güncellendi!', 'Şifreniz başarıyla güncellenmiştir!')
      }else if(response.status === 400){
        showNotification('error', 'HATA!', 'Eski şifreniz yanlış lütfen tekrar deneyiniz!')
      }
    } catch (error) {console.log(error)}

    props.setRedux({loading: false})
  }

  return (
    <Card>
      <Row>
        <Col xs={24} lg={{ span: 20, offset: 2 }}>
          <Form
            name="update-password-form"
            initialValues={{}}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            layout='vertical'
            autoComplete="off">
            <Row>
              <Col xs={24} align='left'>
                <span className='page-title-style'>Şifre Güncelle</span>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={{ span: 24, offset: 0 }} lg={18}>
                <Form.Item label="Eski Şifre" name="old_password"
                  rules={[{ required: true, message: 'Lütfen şifre giriniz!' }]}>
                  <Input.Password size='large' placeholder='Şifre' />
                </Form.Item>
              </Col>
              <Col lg={12} />
              <Col xs={{ span: 24, offset: 0 }} lg={{span:18, offset: 0}}>
              <Form.Item label="Yeni Şifre" name="new_password1"
                rules={[
                  { required: true, message: 'Lütfen şifre giriniz!' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && strongRegex.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Şifre Min 8 karakter olmalı ve en az 1 küçük ve büyük harfe (A-Z), bir sayıya(0-9), bir sembol(!,+,% vb.) içermeli!'));
                    },
                  }),]}>
                <Input.Password size='large' placeholder='Şifre' />
              </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={18}>
              <Form.Item label="Yeni Şifre Tekrar" name="new_password2"
                rules={[
                  { required: true, message: 'lütfen şifreyi tekrar giriniz!' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('new_password1') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Şifreler eşleşmiyor!'));
                    },
                  }),]}>
                <Input.Password size='large' placeholder='Şifre' />
              </Form.Item>
              </Col>
              <Col xs={24} lg={6} align='right' style={{display:"flex",alignItems:"flex-end"}}>
                <Form.Item>
                  <Button size='large' type="primary" htmlType='submit'>
                    Güncelle
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Card>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(UpdatePassword));