import { WarningOutlined } from "@ant-design/icons";
import { Col, Image, Row } from "antd";
import React from "react";
import Barcode from "react-barcode/lib/react-barcode";
import trendyol from "../../assets/images/logo/trendyol.jpg";
import trendyolReturn from "../../assets/images/print/trendyol_return.png";

import suratCargo from "../../assets/images/logo/surat.svg";
import yurticiCargo from "../../assets/images/logo/yurtici.svg";
import pttCargo from "../../assets/images/logo/ptt.svg";
import mngCargo from "../../assets/images/logo/mng.svg";
import arasCargo from "../../assets/images/logo/aras.svg";

export const PrintTicket = React.forwardRef(({ props }, ref) => {
  const current = new Date();
  const {
    cargoTrackingNumber,
    cargoProviderName,
    shipmentAddress,
    orderNo,
    customer,
    lines,
  } = props;
  const cargo =
    cargoProviderName.split(" ")[0] === "Sürat"
      ? suratCargo
      : cargoProviderName.split(" ")[0] === "Aras"
      ? arasCargo
      : cargoProviderName.split(" ")[0] === "Yurtiçi"
      ? yurticiCargo
      : cargoProviderName.split(" ")[0] === "Ptt"
      ? pttCargo
      : mngCargo;
  return (
    <div ref={ref} className="print-ticket pagebreak" style={{ padding: "20px" }}>
      <Row gutter={[16, 8]}>
        <Col xs={12}>
          {current.toLocaleDateString()} {current.toLocaleTimeString()}
        </Col>
        <Col xs={12}>Trendyol Pazaryeri</Col>
        <Col
          xs={24}
          style={{
            border: "1px solid #f0f0f0",
            borderRadius: "10px",
          }}
        >
          <p>
            <WarningOutlined /> Kargo şirketinin dikkatine, bu bir trendyol.com
            gönderisidir. Trendyol anlaşmasına uygun işlem yapabilirsiniz.
          </p>
        </Col>
        <Col xs={4}>
          <Image src={trendyol} />
          </Col>
        <Col xs={{span: 6, offset: 14}}>
          <Image src={cargo} />
        </Col>
        <Col
          xs={12}
          style={{
            border: "1px solid #f0f0f0",
            borderRadius: "10px",
          }}
        >
          <Row>
            <Col xs={24}>
              <p>
                <b>Alıcı Bilgileri</b>
              </p>
            </Col>
            <Col xs={24}>
              <Row>
                <Col xs={7}>
                  <b>Sipariş No</b>
                </Col>
                <Col xs={1}>:</Col>
                <Col xs={16}>
                  <p>{orderNo}</p>
                </Col>
              </Row>
            </Col>
            <Col xs={24}>
              <Row>
                <Col xs={7}>
                  <b>Ad - Soyad</b>
                </Col>
                <Col xs={1}>:</Col>
                <Col xs={16}>
                  <p>{customer}</p>
                </Col>
              </Row>
            </Col>
            <Col xs={24}>
              <Row>
                <Col xs={7}>
                  <b>Adres</b>
                </Col>
                <Col xs={1}>:</Col>
                <Col xs={16}>
                  <p>
                    {shipmentAddress.address1} {shipmentAddress.address2}
                  </p>
                  <p>
                    {shipmentAddress.district}/{shipmentAddress.city}
                  </p>
                  <p>{shipmentAddress.postalCode}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col
          xs={12}
          style={{
            border: "1px solid #f0f0f0",
            borderRadius: "10px",
          }}
        >
          <p>
            <b>Kargo Barkodu</b>
          </p>
          <Barcode
            value={cargoTrackingNumber.toString()}
            width={3}
            height={150}
          />
        </Col>

        <Col
          xs={24}
          style={{
            border: "1px solid #f0f0f0",
            borderRadius: "10px",
          }}
        >
          <p>
            <b>4 Adımda Kolay İade Nasıl Yapılır?</b>
          </p>
          <Row>
            <Col xs={24}>
              <Image src={trendyolReturn} />
            </Col>
          </Row>
        </Col>
        <Col
          xs={24}
          style={{
            border: "1px solid #f0f0f0",
            borderRadius: "10px",
          }}
        >
          <p>
            <b>Ürün Bilgileri</b>
          </p>
          {lines.map((value, index) => {
            return (
              <Row key={index} gutter={[16, 16]}>
                <Col xs={4}>
                  <span
                    style={{
                      border: "1px solid #000",
                      borderRadius: "50%",
                      padding: "0 5px",
                    }}
                  >
                    {index + 1}
                  </span>
                </Col>
                <Col xs={20}>
                  <Row>
                    <p>{value.productName}</p>
                    {
                      <table style={{ textAlign: "left" }}>
                        <thead>
                          <tr>
                            <th width="100">Adet</th>
                            <th width="100">Renk</th>
                            <th width="100">Beden</th>
                            <th width="100">Barkod</th>
                            <th width="100">Stok Kodu</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{value.quantity} Adet</td>
                            <td>--- </td>
                            <td>{value.productSize}</td>
                            <td>{value.barcode}</td>
                            <td>{value.merchantSku}</td>
                          </tr>
                        </tbody>
                      </table>
                    }
                  </Row>
                </Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    </div>
  );
});
