import React, { useState } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/hizlihesap/actions";
import { Button, Col, Form, Input, Modal, Popover, Radio, Row, Select, Space, Switch, Upload } from 'antd'
import { getApiBaseClear } from '../../services/config'
import { createTask, uploadFiles } from './api';
import { RetweetOutlined, UploadOutlined } from '@ant-design/icons';
import { showNotification } from '../../utils/showNotification'
import moment from 'moment';
import { useEffect } from 'react';


const DAYS = [0, 1, 2, 3, 4, 5, 6]
const DAYS_NAME = ['P', 'S', 'Ç', 'P', 'C', 'C', 'P']

const dayArray = Array.from({ length: 20 }, (_, i) => i + 1)
const weekArr = Array.from({ length: 2 }, (_, i) => i + 1)
const mountArr = Array.from({ length: 3 }, (_, i) => i + 1)

const CreateTask = (props) => {
  const [repeatValue, setRepeatValue] = useState(1)
  const [repeatDays, setRepeatDays] = useState([])
  const [isRepeat, setIsRepeat] = useState(false)
  const [mountValue, setMountValue] = useState(1)

  const [form] = Form.useForm()


  useEffect(() => {
    form.resetFields()
    setIsRepeat(false)
    setRepeatDays([])

  }, [form, props.isTaskModalVisible])

  const closeTaskModal = () => props.setIsTaskModalVisible(false)

  // Create task form submit
  const onFinish = async (values) => {
    if (repeatValue === 2 && repeatDays.length === 0) {
      showNotification('error', 'Gün seçin!', 'Haftalık tekrar eden bir görev için gün seçmek zorunludur!')
      return
    }
    props.setLoading(true)
    if (values.files === undefined) {
      values.files = []
    }

    const payload = {
      description: values.description,
      dueDate: "",
      repeatDate: "",
      assignee: values.assignee,
      note: values.note || "",
      files: [],
      isActive: true
    }



    const fileUrls = []
    const promise = values.files.map(async (file) => {
      if (file.originFileObj === undefined) return
      const fData = new FormData()
      fData.append("file", file.originFileObj)
      try {
        const res = await uploadFiles(fData);
        if (res.status === 201) {
          const fileUrl = getApiBaseClear() + res.data.file;
          fileUrls.push(fileUrl)
        }
      } catch (error) {
        console.log('error: ', error)
      }
    })

    await Promise.all(promise);
    payload.files = [...fileUrls]


    if (!isRepeat) {
      payload.repeatDate = moment().format("YYYY-MM-DD")
      payload.dueDate = values.dueDate
      payload.requrrenceDate = 0
    } else {
      payload.requrrenceDate = repeatValue
      payload.requrrenceFrequency = values.requrrenceFrequency
      payload.requrrenceEndDate = values.requrrenceEndDate
      payload.dueDate = moment().format("YYYY-MM-DD")
      payload.requrrenceWeekDays = repeatDays
    }

    if(repeatValue === 3 && mountValue === 2){
      payload.dueDate = moment().clone().startOf('month').format('YYYY-MM-DD');
    }

    try {
      await createTask(payload)
      props.setUpdateCount(props.updateCount + 1)
      showNotification('success', 'Görev oluşturuldu!', '')
      props.setLoading(false)
      closeTaskModal()
    } catch (error) {
      console.log(error)
      showNotification('error', 'Uppss!', 'Bir hata meydana geldi!')
    }
    props.setLoading(false)
  }

  // when repeat and week selected, handle the days that selected
  const handleAddDay = (day) => {
    const temp = [...repeatDays];
    if (temp.filter((day_) => day_ === day).length === 0) {
      temp.push(day)
      setRepeatDays(temp)
      return
    }
    const newList = temp.filter((day_) => day_ !== day)
    setRepeatDays(newList)
  }

  const renderDays = DAYS.map((day, index) => {
    const isSelected = repeatDays.filter((day_) => day === day_).length !== 0
    return (
      <div key={index} style={{
        backgroundColor: isSelected ? '#ff5c09' : '#f1f3f4',
        color: isSelected ? '#FFFFFF' : '#000000',
        borderRadius: '50%',
        width: 25,
        height: 25,
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
      }} onClick={() => handleAddDay(day)}>{DAYS_NAME[index]}</div>
    )
  })


  const handleMinDate = (event, name) => {
    if (moment(event.target.value).diff(moment(), "days") < 0) {
      form.setFieldsValue({
        [name]: moment().format('YYYY-MM-DD')
      })
    }
  }

  const requrrenceFrequencyOptions = (repeatValue === 1 ? dayArray : repeatValue === 2 ? weekArr : mountArr).map((dt, index) => (
    <Select.Option key={index} value={dt}>{dt}</Select.Option>
  ))

  return (
    <Modal title={'Görev Ekle'} visible={props.isTaskModalVisible} onOk={() => form.submit()} onCancel={closeTaskModal}>
      <Form
        name="task-management-form"
        form={form}
        initialValues={{}}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        layout='horizontal'
        autoComplete="off">
        <Row gutter={[24, 8]}>
          <Col xs={24} align="right">
            <Popover content={<div>Yineleme Ekle</div>} title="">
              <Switch checkedChildren={<RetweetOutlined />} unCheckedChildren={<RetweetOutlined />} checked={isRepeat} onChange={setIsRepeat} />
            </Popover>
          </Col>
          <Col xs={{ span: 24, offset: 0 }}>
            <Form.Item label="" name="description"
              rules={[{ required: true, message: 'Lütfen görev tanımı girin!' },]}>
              <Input placeholder='Görev Tanımı' />
            </Form.Item>
          </Col>
          {!isRepeat ?
            <Col xs={{ span: 24, offset: 0 }}>
              <Form.Item label={""}
                name="dueDate" rules={[{ required: true, message: 'Lütfen görev bitiş tarihi girin!' },]}>
                <Input type={'text'} placeholder="Bitiş Tarihi"
                  min={moment().format('YYYY-MM-DD')}
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => {
                    if (!e.target.value) { e.target.type = "text" }
                    handleMinDate(e, "dueDate")
                  }} />
              </Form.Item>
            </Col>
            :
            <>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="Tekrar Sıklığı" name="requrrenceFrequency" initialValue={1}
                  rules={[{ required: true, message: 'Lütfen yineleme sıklığı girin!' },]}>
                  {/* <Input type={'number'} placeholder="" /> */}
                  <Select allowClear style={{ width: '100%' }} placeholder="">
                    {requrrenceFrequencyOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label={""} name="requrrenceDate" initialValue={1}
                  rules={[{ required: false, message: '' },]}>
                  <Select allowClear style={{ width: '100%' }} onChange={setRepeatValue}
                    placeholder="Tekrar Sıklığı">
                    <Select.Option value={1}>Günde bir</Select.Option>
                    <Select.Option value={2}>Haftada bir</Select.Option>
                    <Select.Option value={3}>Ayda bir</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              {repeatValue === 2 &&
                <Col xs={{ span: 24, offset: 0 }} align='center' style={{marginBottom: 20}}>
                  <Space>
                    {renderDays}
                  </Space>
                </Col>
              }
              {repeatValue === 3 &&
                <Col xs={{ span: 24, offset: 0 }} align='center' style={{marginBottom: 20}}>
                  <Radio.Group onChange={(e) => setMountValue(e.target.value)} value={mountValue}>
                    <Radio value={1}>Oluşturma tarihi</Radio>
                    <Radio value={2}>Ayın ilk günü</Radio>
                  </Radio.Group>
                </Col>
              }
              <Col xs={{ span: 24, offset: 0 }} lg={12}>
                <Form.Item label="" name="requrrenceEndDate"
                  rules={[{ required: true, message: 'Lütfen yineleme bitiş tarihi girin!' },]}>
                  <Input
                    type={'text'} placeholder="Yineleme Bitiş Tarihi"
                    min={moment().format('YYYY-MM-DD')}
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => {
                      if (!e.target.value) { e.target.type = "text" }
                      handleMinDate(e, "requrrenceEndDate")
                    }} />
                </Form.Item>
              </Col>
            </>
          }
          <Col xs={{ span: 24, offset: 0 }}>
            <Form.Item label={null} name="files" valuePropName='fileList' getValueFromEvent={props.normFile}
              rules={[{ required: false, message: '' },]}>
              <Upload beforeUpload={() => false}>
                <Button icon={<UploadOutlined />}>Dosya yükle</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24, offset: 0 }}>
            <Form.Item label="" name="note"
              rules={[{ required: false, message: '' },]}>
              <Input.TextArea rows={3} placeholder="Not" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24, offset: 0 }}>
            <Form.Item label="Atanan Kişi" name="assignee" initialValue={props.reduxState?.user?.id}
              rules={[{ required: false, message: '' },]}>
              <Select style={{ width: '100%' }} >
                {props.asigneeOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(CreateTask));