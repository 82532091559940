import { protectedApi } from '../../services/axios'

export const createUser = async (payload) => {
  try {
    const response = await protectedApi.post(`management/users`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const deleteUser = async (id) => {
  try {
    const response = await protectedApi.delete(`management/user/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const getUser = async (id) => {
  try {
    const response = await protectedApi.get(`management/user/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const getBossesUsers = async (boosId) => {
  try {
    const response = await protectedApi.get(`management/profiles?bossId=${boosId}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const createPassword = async (payload) => {
  try {
    const response = await protectedApi.post(`api/password_reset/`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const updatePassword = async (payload) => {
  try {
    const response = await protectedApi.post(`/pass/password/change/`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const updateUser = async (user_id, payload) => {
  try {
    const response = await protectedApi.put(`/management/user/${user_id}`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const updateProfile = async (user_id, payload) => {
  try {
    const response = await protectedApi.put(`/management/profile/${user_id}`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const getPermission = async (id) => {
  try {
    const response = await protectedApi.get(`/management/permission/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const updatePermission = async (id, payload) => {
  try {
    const response = await protectedApi.put(`/management/permission/${id}`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const updateComChannel = async (id, payload) => {
  try {
    const response = await protectedApi.put(`/management/comchannel/${id}`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const updateComCategory = async (id, payload) => {
  try {
    const response = await protectedApi.put(`/management/comcategory/${id}`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}


export const getCompany = async (id) => {
  try {
    const response = await protectedApi.get(`management/company/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const createCompany = async (payload) => {
  try {
    const response = await protectedApi.post(`/management/companies`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const updateCompany = async (id, payload) => {
  try {
    const response = await protectedApi.put(`/management/company/${id}`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const getCountries = async () => {
  try {
    const response = await protectedApi.get(`/cities_light/countries/`);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const getRegions = async (id) => {
  try {
    const response = await protectedApi.get(`/cities_light/regions/?country_id=${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const getCities = async (id) => {
  try {
    const response = await protectedApi.get(`/cities_light/cities/?country_id=${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
}