import { Card, Col, Row } from "antd";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { getCompareReportData } from "../api";
import ComparedMarketplaces from "./ComparedMarketplaces";
import CompareReportCard from "./CompareReportCard";
import ReportFilter from "./ReportFilter";

const CompareReportsContent = () => {
  // const [data, setData] = useState()
  const [marketPlaces, setMarketPlaces] = useState([]);
  const [orderLists, setOrderLists] = useState([]);
  const [range, setRange] = useState([
    moment().startOf("week").valueOf(),
    moment().valueOf(),
  ]);
  const [reportTypes, setReportTypes] = useState([11]);
  const { search } = useLocation();

  useEffect(() => {
    const temp = [];
    search
      .slice(1)
      .split("&")
      .map((value) => (temp[value.split("=")[0]] = value.split("=")[1]));
    if (temp["marketplace"]) {
      setMarketPlaces(
        temp["marketplace"].split(",").map((value) => parseInt(value))
      );
      setRange([parseInt(temp["startDate"]), parseInt(temp["endDate"])]);
      setReportTypes(
        temp["reportType"].split(",").map((value) => parseInt(value))
      );
    }
  }, [search]);

  const rangeValue = (rangeValue) => {
    setRange(rangeValue);
  };

  useEffect(() => {
    const handleGetCompareData = async () => {
      setOrderLists([]);
      let tempArr = [];
      reportTypes.map(async (reportType) => {
        try {
          const payload = {
            startDate: range[0],
            endDate: range[1],
            reportType: parseInt(reportType?.toString().slice(0, 1)),
            orderType: parseInt(reportType?.toString().slice(-1)),
            marketplace: marketPlaces,
          };
          const response = await getCompareReportData(payload);
          tempArr = [...tempArr,  {...response.data, reportType: reportType}]
          setOrderLists(tempArr);
        } catch (error) {
          console.log("ERROR:", error);
        }
      });
    };
    handleGetCompareData();
  }, [range, reportTypes, marketPlaces]);

  return (
    <Card>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <ReportFilter rangeFilter={rangeValue} />
        </Col>
        <Col xs={24}>
          <ComparedMarketplaces marketplace={marketPlaces} />
        </Col>
        {orderLists?.map((orderList, index) => {
          return (
            <Col key={index} xs={24}>
              <CompareReportCard
                orderList={orderList}
                range={range}
                reportTypes={reportTypes}
                marketplace={marketPlaces}
              />
            </Col>
          );
        })}
      </Row>
    </Card>
  );
};

export default CompareReportsContent;
