import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/hizlihesap/actions";
import { Button, Card, Col, Divider, Image, Input, List, Radio, Row, Select, Space } from 'antd'
import moment from 'moment'
import profileImg from '../../assets/images/icons/user.svg'
import { CalendarOutlined, CloseOutlined, DownOutlined, LoadingOutlined, UpOutlined } from '@ant-design/icons';
import { BsArrowRepeat } from "react-icons/bs"
import { getMyCompanyTasks, getMyCompanyUsers, updateTask } from './api';
import UpdateTask from './UpdateTask';
import CreateTask from './CreateTask';
import { getApiBaseClear } from '../../services/config';
import { showNotification } from '../../utils/showNotification';


const StatusSelect = (props) => {
  const handleOnChange = async (value) => {
    props.setLoading(true)
    try {
      await updateTask(props.item.id, { taskStatus: value })
      props.setUpdateCount(props.updateCount + 1)
      props.setUpdateTaskData(null)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Select size='large' style={{ width: '100%' }} onChange={handleOnChange}
      className={props.item.taskStatus === 1 ? "task-status-1-select" : props.item.taskStatus === 2 ? "task-status-2-select" : "task-status-3-select"}
      defaultValue={props.item.taskStatus}>
      <Select.Option value={1}>Yeni</Select.Option>
      <Select.Option value={2}>Devam Ediyor</Select.Option>
      <Select.Option value={3}>Tamamlandı</Select.Option>
    </Select>
  )
}

const TaskManagementContent = (props) => {
  const [isTaskModalVisible, setIsTaskModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [activeTasks, setActiveTasks] = useState([])
  const [finishedTasks, setFinishedTasks] = useState([])
  const [myCompanyUsers, setMyCompanyUsers] = useState([])
  const [updateCount, setUpdateCount] = useState(0)
  const [updateTaskData, setUpdateTaskData] = useState(null)
  const [taskFilter, setTaskFilter] = useState(0)
  const [ordering, setOrdering] = useState("")
  const [search, setSearch] = useState("")
  const [isTaskColOpen, setIsTaskColOpen] = useState(false)
  const [isFinishedTaskListOpen, setIsFinishedTaskListOpen] = useState(false)
  



  const handleUpdateInfo = (item) => {
    setUpdateTaskData(item)
    setIsTaskColOpen(true)
  }

  const openTaskModal = () => {
    setIsTaskModalVisible(true)
  }

  const handleSearch = (e) => {
    if(e.target.value.length < 3) {
      setSearch("")
      return
    }
    setSearch(e.target.value)
  }


  useEffect(() => {
    const myCompanyId = props.reduxState?.user?.profile.company
    if (!myCompanyId) return

    setLoading(true)
    const getMyCompanyTasks_ = async () => {
      let dateGte = null
      let dateLte = null

      if (taskFilter === 0) {
        dateGte = moment().startOf('week').format('YYYY-MM-DD')
        dateLte = moment().endOf('week').format('YYYY-MM-DD')
      }
      if (taskFilter === 1) {
        dateGte = moment().startOf('month').format('YYYY-MM-DD')
        dateLte = moment().endOf('month').format('YYYY-MM-DD')
      }
      if (taskFilter === 2) {
        dateGte = moment().startOf('year').format('YYYY-MM-DD')
        dateLte = moment().endOf('year').format('YYYY-MM-DD')
      }

      try {
        const response = await getMyCompanyTasks({ company_id: myCompanyId, dateGte: dateGte, dateLte: dateLte, ordering: ordering, search: search })
        const openTasks = response.data.results.filter((data) => data.taskStatus === 1 || data.taskStatus === 2)
        const closeTasks = response.data.results.filter((data) => data.taskStatus === 3)
        const openTasksTempArr = openTasks.map((data, index) => {
          return {
            id: data.id,
            groupId: data.groupId,
            description: data.description,
            note: data.note,
            assignee: data.assignee,
            files: data.files,
            dueDate: data.dueDate,
            taskDate: data.repeatDate,
            isRepeat: data.isRepeat,
            taskStatus: data.taskStatus,
            isOpen: true
          }
        })
        setActiveTasks(openTasksTempArr)


        const closeTasksTempArr = closeTasks.map((data, index) => {
          return {
            id: data.id,
            description: data.description,
            note: data.note,
            assignee: data.assignee,
            files: data.files,
            dueDate: data.dueDate,
            taskDate: data.repeatDate,
            isRepeat: data.isRepeat,
            taskStatus: data.taskStatus,
            isOpen: false
          }
        })
        setFinishedTasks(closeTasksTempArr)

      } catch (error) {
        console.log(error)
      }
      setLoading(false)
    }

    getMyCompanyTasks_()
    setIsTaskColOpen(false)
  }, [props.reduxState?.user?.profile.company, updateCount, taskFilter, ordering, search])

  useEffect(() => {
    const getMyCompanyUsers_ = async () => {
      const myCompanyId = props.reduxState?.user?.profile.company
      if (!myCompanyId) return
      try {
        const response = await getMyCompanyUsers(myCompanyId)
        setMyCompanyUsers(response.data.results)
      } catch (error) {
        console.log(error)
      }
    }

    getMyCompanyUsers_()
  }, [props.reduxState?.user?.profile.company])


  const asigneeOptions = myCompanyUsers.map((profile, index) => {
    return (
      <Select.Option value={profile.user.id} key={index}>
        <Space>
          <Image preview={false} src={profile.photo || profileImg} width={25} style={{ borderRadius: '100%' }} />
          <span>{profile.user.first_name + " " + profile.user.last_name}</span>
        </Space>
      </Select.Option>
    )
  })


  const normFile = (e) => {

    const typeList = ["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingm", "application/msword", "image", "application/excel", "application/vnd.ms-excel", "application/x-excel", "application/x-msexcel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]
    const isAcceptableType = typeList.map(type => e.file.type.includes(type)).filter(d => d === true).length > 0
    const isLt2M = e.file.size / 1024 / 1024 < 5;


    const filteredFileArray = e.fileList.filter(file => file.name !== e.file.name)
    if (!isAcceptableType || e.file.type === "") {
      showNotification('error', 'Uygun olmayan dosya!', 'Lütfen geçerli bir dosya yükleyin! (.pdf, .docx, .xlsx veya görsel)')
      return filteredFileArray
    }
    if (!isLt2M) {
      showNotification('error', 'Dosya çok büyük!', 'Lütfen 5MB veya daha küçük boyutlarda dosya ekleyin!')
      return filteredFileArray
    }

    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };


  return (
    <Row gutter={[24, 24]}>
      <Col xs={24} lg={isTaskColOpen ? 16 : 24}>
        <Card>
          <Row gutter={[24, 24]} align='middle'>
            <Col xs={24} lg={isTaskColOpen ? 9 : 6} align='left'>
              <Radio.Group onChange={(event) => setTaskFilter(event.target.value)} defaultValue={0}>
                <Radio value={0}>Bu Hafta</Radio>
                <Radio value={1}>Bu Ay</Radio>
                <Radio value={2}>Bu Yıl</Radio>
              </Radio.Group>
            </Col>
            <Col xs={isTaskColOpen ? 7 : 6}>
              <Select size='medium' style={{ width: '100%' }} placeholder="Sıralama" onChange={setOrdering}>
                <Select.Option value={"description"}>Konuya Göre Artan</Select.Option>
                <Select.Option value={"-description"}>Konuya Göre Azalan</Select.Option>
                <Select.Option value={"assignee__first_name"}>Atanan Kişiye Göre Artan</Select.Option>
                <Select.Option value={"-assignee__first_name"}>Atanan Kişiye Göre Azalan</Select.Option>
              </Select>
            </Col>
            <Col xs={isTaskColOpen ? 8 : 7}>
              <Input placeholder="Aramak için kelime girin" onChange={handleSearch} />
            </Col>
            <Col xs={isTaskColOpen ? 24 : 5} align='right'>
              <Button type='primary' onClick={openTaskModal}>Yeni Görev</Button>
            </Col>
            <Col xs={24}>
              <Divider orientation="left" orientationMargin="0">Açık Görevler</Divider>
              <div style={{ maxHeight: 400, overflowY: 'auto', overflowX: 'hidden' }}>
                <List
                  itemLayout="horizontal" loading={{ spinning: loading, indicator: <LoadingOutlined /> }}
                  dataSource={activeTasks}
                  renderItem={(item) => (
                    <List.Item className={updateTaskData?.id === item.id ? 'task-list task-list-border' : 'task-list'}>
                      <List.Item.Meta
                        avatar={null}
                        title={
                          <Row style={{ fontWeight: 300 }} align='middle'>
                            <Col xs={19} onClick={() => handleUpdateInfo(item)}>
                              <Row style={{ fontWeight: 300 }} align='middle'>
                                <Col xs={24}>
                                  <span>{item.description}</span>
                                </Col>
                                <Col xs={24} style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                                  <Space>
                                    <CalendarOutlined className='vertical-middle' />
                                    <span>{moment(item.dueDate).format("DD.MM.YYYY")}</span>
                                    {item.isRepeat && <BsArrowRepeat className='vertical-middle' />}
                                  </Space>
                                </Col>
                              </Row>
                            </Col>
                            <Col xs={5}>
                              <Row align='middle'>
                                <Col xs={8} onClick={() => handleUpdateInfo(item)}>
                                  <Image preview={false} src={item.assignee.photo ? (getApiBaseClear() + item.assignee.photo) : profileImg} width={36}
                                    style={{ borderRadius: '100%' }} />
                                </Col>
                                <Col xs={16} align='center'>
                                  <StatusSelect item={item} setLoading={setLoading} setUpdateTaskData={setUpdateTaskData}
                                    updateCount={updateCount} setUpdateCount={setUpdateCount} />
                                </Col>
                              </Row>
                            </Col>
                          </Row>} />
                    </List.Item>
                  )}
                />
              </div>
            </Col>
            <Col xs={24}>
              <Divider orientation="left" orientationMargin="0">
                Tamamlanmış Görevler &nbsp;&nbsp;&nbsp;&nbsp;
                {isFinishedTaskListOpen ?
                  <UpOutlined className='hoverable-text' onClick={() => setIsFinishedTaskListOpen(!isFinishedTaskListOpen)} />
                  :
                  <DownOutlined className='hoverable-text' onClick={() => setIsFinishedTaskListOpen(!isFinishedTaskListOpen)} />
                }
              </Divider>
              <div style={{ maxHeight: isFinishedTaskListOpen ? 400 : 0, overflowY: 'auto', overflowX: 'hidden', transition: 'all 0.4s' }}>
                <List
                  itemLayout="horizontal" loading={{ spinning: loading, indicator: <LoadingOutlined /> }}
                  dataSource={finishedTasks}
                  renderItem={(item) => (
                    <List.Item className={updateTaskData?.id === item.id ? 'task-list task-list-border' : 'task-list'}>
                      <List.Item.Meta
                        avatar={null}
                        title={<Row style={{ fontWeight: 300 }} align='middle'>
                          <Col xs={19} onClick={() => handleUpdateInfo(item)}>
                            <Row>
                              <Col xs={24}>
                                <span>{item.description}</span>
                              </Col>
                              <Col xs={24} style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                                <Space>
                                  <CalendarOutlined className='vertical-middle' />
                                  <span>{moment(item.dueDate).format("DD.MM.YYYY")}</span>
                                  {item.isRepeat && <BsArrowRepeat className='vertical-middle' />}
                                </Space>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={5}>
                            <Row align='middle'>
                              <Col xs={8} onClick={() => handleUpdateInfo(item)}>
                                <Image preview={false} src={item.assignee.photo ? (getApiBaseClear() + item.assignee.photo) : profileImg} width={36}
                                  style={{ borderRadius: '100%' }} />
                              </Col>
                              <Col xs={16} align='center'>
                                <StatusSelect item={item} setLoading={setLoading} setUpdateTaskData={setUpdateTaskData}
                                  updateCount={updateCount} setUpdateCount={setUpdateCount} />
                              </Col>
                            </Row>
                          </Col>
                        </Row>}
                      />
                    </List.Item>
                  )}
                />
              </div>
            </Col>
          </Row>
          {isTaskModalVisible &&
            <CreateTask
              isTaskModalVisible={isTaskModalVisible}
              setIsTaskModalVisible={setIsTaskModalVisible}
              setLoading={setLoading}
              updateCount={updateCount}
              setUpdateCount={setUpdateCount}
              asigneeOptions={asigneeOptions}
              normFile={normFile} />
          }


        </Card>
      </Col>

      <Col xs={24} lg={8}>
        {isTaskColOpen &&
          <Card>
            <div style={{ display: 'flex', justifyContent: "space-between", verticalAlign: "middle" }}>
              <span className='page-title-style'>Görev</span>
              <CloseOutlined className='link-style' style={{ fontSize: 18 }} onClick={() => setIsTaskColOpen(false)} />
            </div>
            {updateTaskData &&
              <UpdateTask
                asigneeOptions={asigneeOptions}
                normFile={normFile}
                data={updateTaskData}
                setUpdateCount={setUpdateCount}
                setIsTaskColOpen={setIsTaskColOpen}
                updateCount={updateCount} />
            }
          </Card>
        }
      </Col>
    </Row>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(TaskManagementContent));