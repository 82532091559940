import { Card, Col, Divider, Radio, Row, Table } from "antd";
import React from "react";

const SubcriptedContent = () => {
  const packageContent = [
    { id: 1, name: "Paket", value: "Paket" },
    { id: 2, name: "Fiyat", value: "Fiyat" },
    { id: 3, name: "Audit", value: "Audit" },
    { id: 4, name: "7/24 Assist", value: "7/24 Assist" },
    { id: 5, name: "Al Writer", value: "Al Writer" },
    { id: 6, name: "Al Reviews", value: "Al Reviews" },
    { id: 7, name: "Fiyat Asistanı", value: "Fiyat Asistanı" },
    {
      id: 8,
      name: "Hızlı Hesap Dedektifleri",
      value: "Hızlı Hesap Dedektifleri",
    },
    { id: 9, name: "Rakip Ürün Takipleri", value: "Rakip Ürün Takipleri" },
    { id: 10, name: "Kullanıcı Sayısı", value: "Kullanıcı Sayısı" },
  ];
  const cols = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "",
      dataIndex: "value",
      key: "value",
    },
  ];

  return (
    <Row gutter={[8, 8]} className="subscripted-content">
      <Col xs={24}>
        <Card className="border bg-white height-100">
          <Row>
            <Col align="left" xs={24} lg={6}>
              <span className="page-title">Abonelik Bilgileri</span>
            </Col>
            <Col xs={24} lg={18} className="page-title-explanation">
              <span>
                Deneme sürenizin bitmesine{" "}
                <span className="text-orange">15 gün</span> kaldı
              </span>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xs={24}>
        <Card className="border bg-white height-100">
          <Row gutter={[16,8]}>
            <Col align="left" xs={24} lg={24}>
              <span className="page-title-style">Abonelik Olunan Paket</span>
            </Col>
            <Divider style={{margin: 0}} />
            <Col xs={24} style={{marginBottom:10}}>
              <Radio.Group defaultValue={0}>
                <Radio value={0}>Premium Aylık</Radio>
                <Radio value={1}>Premium Yıllık (%20 Yıllık)</Radio>
              </Radio.Group>
            </Col>
            <Col xs={24}>
              <Table
                id="subcripted-packages"
                rowKey={(record) => record.id}
                showHeader={false}
                scroll={{ x: true }}
                columns={cols}
                pagination={false}
                dataSource={packageContent}
                size="small"
                bordered
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default SubcriptedContent;
