import { Button, Col, Row } from 'antd'
import React, { useState } from 'react'
import Login from './Login';
import Register from './Register';


const LoginContent = () => {
  const [activeTab, setActiveTab] = useState(1)

  const setTab = (value) => {
    setActiveTab(value)
  }




  const activeBtnStyle = {
    borderRadius: 0,
    borderTop: "1px solid #ff5c09",
    borderRight: "1px solid #ff5c09",
    borderBottom: 0,
    borderLeft: "1px solid #ff5c09",
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    boxShadow: "none",
    paddingTop: 16,
    paddingBottom: 8,
    fontSize: 18,
    backgroundColor: '#FFFFFF',
    color: '#ff5c09',
    height: 65
  }
  const passiveBtnStyle = {
    borderRadius: 0,
    borderTop: 0,
    borderRight: "1px solid #e1e1e1",
    borderBottom: "1px solid #ff5c09",
    borderLeft: 0,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    boxShadow: "none",
    paddingTop: 16,
    paddingBottom: 8,
    fontSize: 18,
    backgroundColor: '#e1e1e1',
    color: '#7f7f7f',
    height: 65
  }

  return (
    <div>
      <Row className='my-20'>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 8, offset: 8 }}>
          <Row gutter={[0, 0]} className='login-card'>
            <Col xs={12}>
              <Button onClick={() => setTab(1)} size='large' style={activeTab === 1 ? activeBtnStyle : passiveBtnStyle} block={true}>Giriş Yap</Button>
            </Col>
            <Col xs={12}>
              <Button onClick={() => setTab(2)} size='large' style={activeTab === 2 ? activeBtnStyle : passiveBtnStyle} block={true}>Kayıt Ol</Button>
            </Col>
            <Col xs={24} className="login-card-body" >
              {activeTab === 1 ?
                <Login setTab={setTab} /> : <Register setTab={setTab} />
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default LoginContent