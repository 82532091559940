import { useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../../redux/hizlihesap/actions";
import { Button, Card, Col, Divider, Row } from 'antd'
import moment from 'moment'
import 'moment/locale/tr'
import React, { useEffect, useState } from 'react'
import InfoCard from '../../../components/homepage/InfoCard'
import EndorsementCard from './EndorsementCard'
import SaleOrderQuantityCard from './SaleOrderQuantityCard'
import Information from '../../../utils/Information'

const MountlyBrief = (props) => {
  const { data } = props;
  const [totalPriceWeekChange, setTotalPriceWeekChange] = useState(0)
  const [returnedWeekChange, setReturnedWeekChange] = useState(0)
  const [cargoWeekChange, setCargoWeekChange] = useState(0)
  const [totalPriceDailyChange, setTotalPriceDailyChange] = useState(0)
  const [returnedDailyChange, setReturnedDailyChange] = useState(0)
  const [cargoDailyChange, setCargoDailyChange] = useState(0)

  const history = useHistory();

  useEffect(() => {
    const turnOverWeekDiff = ((data.totalPrice / data.lastWeekTotalPrice) -1) * 100;
    isNaN(turnOverWeekDiff) ? setTotalPriceWeekChange(0) : setTotalPriceWeekChange(turnOverWeekDiff.toFixed())
    
    const returnedWeekDiff = ((data.returnedOrderCount / data.lastWeekReturnedOrderCount) -1) * 100;
    isNaN(returnedWeekDiff) ? setReturnedWeekChange(0) : setReturnedWeekChange(returnedWeekDiff.toFixed())
    
    const cargoWeekDiff = ((data.orderCount / data.lastWeekOrderCount) -1) * 100;
    isNaN(cargoWeekDiff) ? setCargoWeekChange(0) : setCargoWeekChange(cargoWeekDiff.toFixed())

    
    const turnOverDailyDiff = ((data.todayTotalPrice / data.yesterdayTotalPrice) -1) * 100;
    isNaN(turnOverDailyDiff) ? setTotalPriceDailyChange(0) : setTotalPriceDailyChange(turnOverDailyDiff.toFixed());
    
    const returnedDailyDiff = ((data.todayReturnedOrderCount / data.yesterdayReturnedOrderCount) -1) * 100;
    isNaN(returnedDailyDiff) ? setReturnedDailyChange(0) : setReturnedDailyChange(returnedDailyDiff.toFixed());
    
    const cargoDailyDiff = ((data.todayOrderCount / data.yesterdayOrderCount) -1) * 100;
    isNaN(cargoDailyDiff) ? setCargoDailyChange(0) : setCargoDailyChange(cargoDailyDiff.toFixed());
  }, [data])
  


  return (
    <Card className="mountly-brief-card">
      <Row align='middle'>
        <Col xs={24} lg={6}>
          <span className='font-w700 page-title-style'>Haftalık Sipariş Özetiniz</span>
        </Col>
        {props.reduxState?.innerWidth >= 768 &&
          <Col xs={2}>
            <Divider type='vertical' style={{ backgroundColor: '#ff5c09', height: "2rem" }} />
          </Col>
        }
        <Col xs={12}>
          <span>{moment().locale('tr').startOf('week').format('LLLL')} | {moment().locale('tr').format('LLLL')}</span> &nbsp;&nbsp;&nbsp; <Information />
        </Col>
        <Col xs={12} lg={4} align='right'>
          <Button className="classic-btn" onClick={()=>history.push("/reports")}>Raporlara Git</Button>
        </Col>
      </Row>

      <Row className='my-20' gutter={[24, 24]}>
        <Col xs={24} md={8} >
          <InfoCard title='Toplam Ciro' value={`${data.totalPrice?.toFixed(2)} TL`} monthlyChange={totalPriceWeekChange} dailyChange={totalPriceDailyChange} />
        </Col>
        <Col xs={24} md={8}>
          <InfoCard title='Toplam İade' value={data.returnedOrderCount} monthlyChange={returnedWeekChange} dailyChange={returnedDailyChange} />
        </Col>
        <Col xs={24} md={8}>
          <InfoCard title='Toplam Kargo' value={data.orderCount} monthlyChange={cargoWeekChange} dailyChange={cargoDailyChange} />
        </Col>
      </Row>
      <Row className='my-20' gutter={[24, 24]}>
        <Col xs={24} lg={16}>
          <SaleOrderQuantityCard data={data} />
        </Col>
        <Col xs={24} lg={8}>
          <EndorsementCard data={data} />
        </Col>
      </Row>
    </Card>
  )
}


const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(MountlyBrief));