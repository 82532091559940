import { protectedApi } from "../../services/axios";

export const getMyOrders = async ({
  user_id,
  offset,
  limit,
  customerName = "",
  productName = "",
  orderNumber = "",
  packageNumber = 0,
  cargoProviderName = "",
  status = "",
  barcode = "",
  orderDate__lte = 0,
  orderDate__gte = 0,
  status__in = "",
  cancelToken,
}) => {
  try {
    const response = await protectedApi.get(
      `trendyol/orders?limit=${limit}&offset=${
        (offset - 1) * limit
      }&customerFullName__icontains=${customerName}&trendyolId__icontains=${packageNumber}&orderNumber__icontains=${orderNumber}&cargoProviderName__icontains=${cargoProviderName}&status__iexact=${status}&barcodes__icontains=${barcode}&orderDate__lte=${orderDate__lte}&orderDate__gte=${orderDate__gte}&productNames__icontains=${productName}&status__in=${status__in}`,
      { cancelToken: cancelToken }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
