import { Card, Col, Row, Table, Tag } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const BestSellerProducts = (props) => {
  const { data, loading } = props
  const columns = [
    {
      title: 'ÜRÜN BARKODU',
      dataIndex: 'productBarcode',
      key: 'productBarcode',
      align: "center",
    },
    {
      title: 'ÜRÜN ADI',
      dataIndex: 'productName',
      key: 'productName',
      align: "center",
      width: 400,
      render: (tag) => {
        return(
          <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 400}}>
            {tag}
          </div>
        )
      }
    },
    {
      title: 'SATIŞ ADEDİ',
      dataIndex: 'salesQuantity',
      key: 'salesQuantity',
      align: "center",
      render: (tag) => {
        return(
          <Tag color="#09b66e">{tag}</Tag>
        )
      }
    },
    {
      title: 'PAZARYERİ',
      dataIndex: 'marketplace',
      key: 'marketplace',
      align: "center",
    },
  ];
  return (
    <Card className='border bg-white height-100'>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <span className='page-title-style'>En Çok Satan Ürünler</span>
        </Col>
        <Col xs={24}>
          <Table id="best-sellers" className='small-table' columns={columns} dataSource={data} size='small' pagination={false}
          loading={{ spinning: loading, indicator: <LoadingOutlined /> }} />
        </Col>
      </Row>
    </Card>
  )
}

export default BestSellerProducts