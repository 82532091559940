import { FilePdfOutlined } from '@ant-design/icons'
import { Card } from 'antd'
import React from 'react'
import Information from '../../utils/Information'

const MostUsedReports = () => {

  const dummyReportsArray = [
    {
      id: 1,
      fileName: "05.10.2022 Ciro Raporu",
    },
    {
      id: 2,
      fileName: "05.10.2022 Satış Raporu",
    },
    {
      id: 3,
      fileName: "05.10.2022 İade Raporu",
    },
    {
      id: 4,
      fileName: "05.10.2022 Kargo Raporu",
    },
    {
      id: 4,
      fileName: "01.11.2022 Ciro Raporu",
    },
  ]

  const displayFiles = dummyReportsArray.map((d, i) => {
    return (
      <div className='file-card-container' key={i} >
        <FilePdfOutlined style={{ fontSize: 100, color: "#ff5c09" }} />
        <div className='file-card-label'>{d.fileName}</div>
      </div>
    )
  })

  return (
    <Card className='card-shadow' style={{ marginTop: 16 }}>
      <span style={{ fontSize: 20, fontWeight: 600 }}>En Sık Kullanılan Raporlar</span>&nbsp;&nbsp;&nbsp; <Information />
      <div xs={24} className='file-list'>
        {displayFiles}
      </div>
    </Card>
  )
}

export default MostUsedReports