import { Card, Col, Divider, Image, Row } from "antd";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getBlogData, getBlogImg } from "../dashboard/api";

const BlogContent = () => {
  const { id } = useParams();
  const [blogData, setBlogData] = useState();

  useEffect(() => {
    const handleGetBlogData = async () => {
      getBlogData({blogId: id}).then(response => {
        const data = response.data
        let media = "";
        const temp = getBlogImg(data.featured_media).then(res => {
          media = res.data.link;
          return ({
            ...data,
            media: media
          })
        }).catch(err => {
          console.log('ERROR: ', err)
        })
        temp.then((data)=> setBlogData(data))
      })
    };
    handleGetBlogData();
  }, [id]);

  return (
    <Card>
      <Row gutter={[24, 24]}>
        <Col xs={24} lg={{ span: 20, offset: 2 }} align="center">
            <Image preview={false} width="70%" src={blogData?.media !== '' ? blogData?.media  : "https://www.hizlihesap.com/wp-content/uploads/2022/10/1920x1080.png"}  />
              <div>
                  <h5 style={{margin: "2rem 0"}} className="font-s24 font-w700 text-orange">{blogData?.title.rendered}</h5>
                  <span className='font-w500' style={{ display: "block", textAlign: "left", fontSize: 12 }}>{moment(blogData?.date).format("L")}</span>
                  <Divider />
                  <p style={{textAlign: "justify"}} dangerouslySetInnerHTML={{__html: blogData?.content.rendered}}>
                  </p>
              </div>
        </Col>
      </Row>
    </Card>
  );
};

export default BlogContent;
