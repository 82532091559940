export const RandomString = () => {
  let result = [];
  
  let strLength = 16;
  const charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789.!+-#+$&';
  
  while (strLength--) { // (note, fixed typo)
      result.push(charSet.charAt(Math.floor(Math.random() * charSet.length)));
  }
  
  return result.join('');
}

export const toTitleCase = (str) => {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}