import { notification } from 'antd';


export const showNotification = (type, title, description, style, icon, duration) => {
    notification[type]({
        message: title,
        description: description,
        className: 'custom-class',
        style: style ? style : null,
        icon: icon ? icon : null,
    });
};
