import React, { useEffect } from 'react'
import { Button, Card, Col, DatePicker, Divider, Row, Select } from 'antd'
import Information from '../../../utils/Information'
import moment from 'moment';

const { RangePicker } = DatePicker;

const ReportFilter = (props) => {
  const { rangeFilter } = props;

  useEffect(() => {
    var divElem = document.getElementById("range-picker-container")
    var inputElements = divElem.querySelectorAll("input");
    inputElements[0].id = "range-start-date"
    inputElements[1].id = "range-end-date"

    const dateChange = (e, index) => {
      const value = e.target.value
      if (value.length === 2 && e.inputType !== "deleteContentBackward") {
        inputElements[index].value = value + "."
      } else if (value.length === 5 && e.inputType !== "deleteContentBackward") {
        inputElements[index].value = value + "."
      }
    }

    inputElements[0].addEventListener("input", (e) =>dateChange(e, 0))
    inputElements[1].addEventListener("input", (e) =>dateChange(e, 1))

    return () => {
      inputElements[0].removeEventListener("input", (e) =>dateChange(e, 0))
      inputElements[1].removeEventListener("input", (e) =>dateChange(e, 1))
    }
  }, [])

  const handleChangeFilter = (fType, value) => {
    if (fType === "range") {
      props.rangeFilter([value[0].valueOf(),value[1].valueOf()])
    }
};

  return (
    <Card className='card-shadow'>
      <Row gutter={[16, 16]}>
        <Col xs={12}>
          <span style={{ fontSize: 12, textDecoration: 'underline' }}>En fazla 3 pazar yeri karşılaştırabilirsiniz</span>&nbsp;&nbsp;&nbsp; <Information />
        </Col>
        <Col xs={12} align='right' id="range-picker-container">
          <RangePicker
            allowClear={false}
            bordered={false}
            showTime
            value={[moment(moment(rangeFilter[0]).format('YYYY-MM-DD HH:mm'), "YYYY-MM-DD HH:mm"), moment(moment(rangeFilter[1]).format('YYYY-MM-DD HH:mm'), "YYYY-MM-DD HH:mm")]}
            onChange={(value)=>handleChangeFilter("range", value)}
            format={"DD.MM.YYYY HH:mm"} />
        </Col>
        <Col xs={3}>
          <Select
            style={{ width: '100%' }}
            placeholder="Kategori Seç">
            <Select.Option value={1}>Kategori 1</Select.Option>
          </Select>
        </Col>
        <Col xs={3}>
          <Select
            style={{ width: '100%' }}
            placeholder="Alt Kategori Seç">
            <Select.Option value={1}>Alt Kategori 1</Select.Option>
          </Select>
        </Col>
        <Col xs={3}>
          <Select
            style={{ width: '100%' }}
            placeholder="Marka Seç">
            <Select.Option value={1}>Marka 1</Select.Option>
          </Select>
        </Col>
        <Col xs={3}>
          <Select
            style={{ width: '100%' }}
            placeholder="Cinsiyet Seç">
            <Select.Option value={1}>Kadın</Select.Option>
            <Select.Option value={2}>Erkek</Select.Option>
          </Select>
        </Col>
        <Col xs={3}>
          <Button block>Ürün Ara</Button>
        </Col>
        <Col xs={1} align='center'>
          <Divider type="vertical" style={{ height: "100%" }} />
        </Col>
        <Col xs={4} align='right'>
          <Button block>Rapor Karşılaştır</Button>
        </Col>
        <Col xs={4} align='right'>
          <Button type='primary' block>Rapor İndir</Button>
        </Col>
      </Row>
    </Card>
  )
}

export default ReportFilter;