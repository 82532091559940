import React, { useEffect } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/hizlihesap/actions";
import { Col, Row } from 'antd';
import MountlyBrief from './modules/MountlyBrief';
import Integration from './modules/Integration';
import BestSellerProducts from './modules/BestSellerProducts';
import IndustryNews from './modules/IndustryNews';
import axios from 'axios';
import { getBestSellers } from './api';
import { useState } from 'react';

const DashboardContent = () => {
  const [loading, setLoading] = useState(false)
  const [bestSeller, setBestSeller] = useState([]);
  const [data, setData] = useState([]);



  useEffect(()=>{
    setLoading(true)
    const handleGetBestSellers = async () => {
      try {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source()

        const response = await getBestSellers({source});
        setData(response.data)
        
        const tempArr = response.data.orderList.map((data, index) => {
          const productBarcode = data[0].barcode;
          const productName = data[0].productName;
          const salesQuantity = data[data.length -1].totalQuantity;
          return {
            key: index,
            productBarcode: productBarcode,
            productName: productName,
            salesQuantity: salesQuantity,
            marketplace: "Trendyol",
          };
      
        });
        setBestSeller(tempArr)
        setLoading(false)

      } catch (error) {}
    };
    handleGetBestSellers()
  },[])

  return (
    <div>
      <Row>
        <Col xs={24}>
          <Integration />
        </Col>
      </Row>
      <Row className='my-20'>
        <Col xs={24}>
          <MountlyBrief data={data} />
        </Col>
      </Row>
      <Row className='my-20' gutter={[24, 24]}>
        <Col xs={24}>
          <BestSellerProducts data={bestSeller} loading={loading} />
        </Col>
      </Row>
      <Row className='my-20' gutter={[24, 24]}>
        <Col xs={24}>
          <IndustryNews />
        </Col>
      </Row>
    </div>
  )
}


const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(DashboardContent));