import React from 'react'
import { Route, Switch, useLocation } from "react-router-dom";
import PrivateRoute from "./routes/PrivateRoute.js";
import MainLayout from './components/layout/MainLayout'
import Page404 from "./pages/404/404";
import LoginLayout from "./pages/login/LoginLayout";
import ChangePassword from "./pages/public/ChangePassword";
import CreatePassword from "./pages/public/CreatePassword";
import DashboardContent from "./pages/dashboard/DashboardContent";
import TrendyolContent from "./pages/integration/trendyol/TrendyolContent";
import HepsiburadaContent from "./pages/integration/hepsiburada/HepsiburadaContent";
import ProductStockManagementContent from "./pages/productStockManagement/ProductStockManagementContent";
import OrderContent from "./pages/order/OrderContent";
import PaymentCalendarContent from "./pages/paymentCalendar/PaymentCalendarContent";
import SupportContent from "./pages/support/SupportContent";
import NewRequestContent from "./pages/support/newRequest/NewRequestContent";
import RequestContent from "./pages/support/request/RequestContent";
import FaqContent from "./pages/faq/FaqContent";
import ProfileContent from "./pages/account/profile/ProfileContent";
import CompanyContent from "./pages/account/company/CompanyContent";
import UsersContent from "./pages/account/users/UsersContent";
import SubscriptionContent from "./pages/account/subscription/SubscriptionContent";
import PurchaseContent from "./pages/account/subscription/purchase/PurchaseContent";
import TaskManagementContent from "./pages/taskManagement/TaskManagementContent";
import { useEffect } from 'react';
import { useState } from 'react';
import ReportsContent from './pages/reports/ReportsContent.jsx';
import CompareReportsContent from './pages/reports/compare/CompareReportsContent.jsx';
import BlogContent from './pages/blog/BlogContent.jsx';

const Main = () => {
  const [pathname, setPathname] = useState(undefined)
  const location = useLocation()

  useEffect(() => {
    setPathname(location.pathname)
  }, [location.pathname])

  return (
    <>
      {pathname === undefined ? null : pathname.includes('/login') || pathname.includes('/authentication') ?
        <Switch>
          <Route exact path="/login" component={LoginLayout} />
          <Route exact path="/authentication/reset/:uid/:token" component={ChangePassword} />
          <Route exact path="/authentication/create-password/:token" component={CreatePassword} />
        </Switch> :
        <MainLayout page='Anasayfa' content={
          <Switch>
            <PrivateRoute exact path="/" component={DashboardContent} />
            <PrivateRoute exact path="/integration" component={DashboardContent} />
            <PrivateRoute exact path="/integration/trendyol" component={TrendyolContent} />
            <PrivateRoute exact path="/integration/hepsiburada" component={HepsiburadaContent} />
            <PrivateRoute exact path="/product-stock-management" component={ProductStockManagementContent} />
            <PrivateRoute exact path="/order-management" component={OrderContent} />
            <PrivateRoute exact path="/payment-calendar" component={PaymentCalendarContent} />
            <PrivateRoute exact path="/reports" component={ReportsContent} />
            <PrivateRoute exact path="/reports/compare" component={CompareReportsContent} />
            <PrivateRoute exact path="/task-management" component={TaskManagementContent} />
            <PrivateRoute exact path="/support" component={SupportContent} />
            <PrivateRoute exact path="/support/new-request" component={NewRequestContent} />
            <PrivateRoute exact path="/support/request/:id" component={RequestContent} />
            <PrivateRoute exact path="/faq" component={FaqContent} />
            <PrivateRoute exact path="/account/profile" component={ProfileContent} />
            <PrivateRoute exact path="/account/companysettings" component={CompanyContent} />
            <PrivateRoute exact path="/account/users" component={UsersContent} />
            <PrivateRoute exact path="/account/subscriptions" component={SubscriptionContent} />
            <PrivateRoute exact path="/account/subscriptions/purchase" component={PurchaseContent} />
            <PrivateRoute exact path="/blog/:id" component={BlogContent} />
            <Route path="*">
              <Page404 />
            </Route>
          </Switch>
        } />
      }
    </>
  )
}

export default Main