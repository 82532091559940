import React, { useState, useEffect } from 'react'
import { Card, Col, Input, Row } from 'antd'
import axios from 'axios';
import { getFaqs, getFaqSections } from './api';

import { SearchOutlined } from '@ant-design/icons';
import bgFaq from '../../assets/images/backgrounds/sss-bg.png'



const FaqContent = () => {
  const [selectedSection, setSelectedSection] = useState(1)
  const [sections, setSections] = useState([])
  const [faqs, setFaqs] = useState([])


  useEffect(() => {
    const getSections = async (source) => {
      try {
        const response = await getFaqSections({ cancelToken: source.token })
        console.log('RESPONSE: ', response)
        setSections(response.data.results)
      } catch (error) {
        console.log('ERROR: ', error)
      }
    }

    const getFaqs_ = async (source) => {
      try {
        const response = await getFaqs({ cancelToken: source.token })
        console.log('RESPONSE: ', response)
        setFaqs(response.data.results)
      } catch (error) {
        console.log('ERROR: ', error)
      }
    }

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source()

    getSections(source)
    getFaqs_(source)
    
    return () => {
      source.cancel()
    }
  }, [])



  const faqSections = sections?.map((res, index) => {
    return (
      <tr key={index} className='hoverable-text'>
        <td className={selectedSection === res.id ? "faq-section selected" : "faq-section" } style={{
          /* border: '1px solid #dddddd',
          textAlign: 'left',
          padding: 6,
          cursor: 'pointer',
          textDecoration: selectedSection === res.id ? 'underline' : undefined,
          fontSize: selectedSection === res.id ? 18 : 14,
          color: selectedSection === res.id ? '#ff5c09' : undefined */
        }} onClick={() => setSelectedSection(res.id)}>{res.title}</td>
      </tr>
    )
  })

  const qaa = faqs?.filter(r => r.section === selectedSection).map((res, index) => {
    return (
      <div key={index} className='p-10'>
        <span className='font-w700 font-s18'>{res.faQuestion}</span><br />
        <span>{res.faAnswer}</span>
      </div>
    )
  })

  return (
    <Card>
      <Row gutter={[24, 24]} style={{backgroundImage: `url(${bgFaq})`, backgroundPosition: "right", backgroundRepeat: "no-repeat" }}>
      <Col xs={24} lg={16}>
        <Input prefix={<SearchOutlined />} />
      </Col>
      <Col lg={8}></Col>
        <Col xs={24} lg={{ span: 4, offset: 0 }} align='center'>
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <tbody>
              {faqSections}
            </tbody>
          </table>
        </Col>
        <Col xs={24} lg={{ span: 12, offset: 0 }} align='left'>
          {qaa}
        </Col>
      </Row>
    </Card>
  )
}

export default FaqContent