import { QuestionCircleOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import React from 'react'

// Bu component sayfalardaki ? iconunu ve işlevlerini tutacak.

const Information = (props) => {


  const content = () => {

    return (
      <div style={{ minWidth: 150, minHeight: 150 }}></div>
    )
  }

  return (
    <Popover placement="rightTop" title={""} content={content} trigger="click">
      <QuestionCircleOutlined className='info-icon' style={{ fontSize: 18 }} />
    </Popover>
  )
}

export default Information