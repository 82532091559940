import React, { useEffect, useState } from 'react'
import { useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/hizlihesap/actions";
import { Button, Card, Col, Form, Image, Input, Modal, Pagination, Row, Select, Space, Table } from 'antd';
import { GiAlarmClock } from 'react-icons/gi'
import info from '../../utils/marketplaces.json'
import axios from 'axios';
import { createAlarmSettings, deleteAlarmSetting, getAlarmSettings, getMyProducts } from './api';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { showNotification } from '../../utils/showNotification';
import { isHaveAccess } from '../../utils/Permission';
import Information from '../../utils/Information'

const { confirm } = Modal;

const ProductStockManagementContent = (props) => {
  const [loading, setLoading] = useState(false)
  const [marketPlace, setMarketPlace] = useState([])
  const [data, setData] = useState([]);
  const [marketPlaceOptions, setMarketPlaceOptions] = useState([])
  const [brands, setBrands] = useState([])
  const [categories, setCategories] = useState([])
  const [brandsFilter, setBrandsFilter] = useState([])
  const [categoriesFilter, setCategoriesFilter] = useState([])
  const [stocksFilter, setStocksFilter] = useState([])
  const [searchKeyword, setSearchKeyword] = useState("")
  const [toggleKeywordSearch, setToggleKeywordSearch] = useState(false)
  const [isAlarmModalVisible, setIsAlarmModalVisible] = useState(false)
  const [productionCount, setProductionCount] = useState(null)
  const [paginationInfo, setPaginationInfo] = useState({ offset: 1, limit: 10 })
  const [alarmData, setAlarmData] = useState([]);
  const [rowData, setRowData] = useState({});
  const [isQuantity, setIsQuantity] = useState(true);
  
  const [form] = Form.useForm()

  const history = useHistory()

  useEffect(() => {
    const user_id = JSON.parse(window.localStorage.getItem('user')).id
    const access = async () => {
      const perms = await isHaveAccess(user_id, "product-stock-management")
      if (!perms) {
        history.push('/')
        showNotification('error', 'Yetkiniz Yok!', 'Gitmek istediğiniz sayfaya yetkiniz bulunmamaktadır!')
        return
      }
    }
    access()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeAlarmModal = () => {
    setIsAlarmModalVisible(false)
    form.resetFields();
  }


  const handleTablePagination = (offset, limit) => {
    // Bütün veriyi direkt çekmekten yerine her tabloda gösterilen kadar veri çekmek için
    // tablo pagination'ın onchange fonksiyonunu kullanacağız.
    setPaginationInfo(prevState => ({
      ...prevState,
      offset: offset,
      limit: limit
    }))
  }

  // Handle alarm checkbox that filters the table with alarm status
  const handleStockFilter = (checked, type) => {
    if(checked) {
      const state = [...stocksFilter]
      state.push(type)
      setStocksFilter(state)
    } else {
      const oldState = [...stocksFilter]
      const state = oldState.filter((val) => val !== type)
      setStocksFilter(state)
    }
  }

  // Whenever pagination is changed send request to api to get products
  // This useEffect will include all the filters to
  // Whenever a filter is set, this useEffect will work
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source()
    const info = paginationInfo
    info.source = source
    info.brandsFilter = brandsFilter.join()
    info.categoriesFilter = categoriesFilter.join()
    info.stocksFilter = stocksFilter.join()
    info.searchKeyword = searchKeyword;
    handleGetMyProducts(info)
    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInfo, brandsFilter, categoriesFilter, stocksFilter, toggleKeywordSearch])

  // Get my products
  const handleGetMyProducts = async ({ offset, limit, brandsFilter, categoriesFilter, stocksFilter, searchKeyword, source }) => {
    setLoading(true)
    let user = JSON.parse(window.localStorage.getItem('user'))
    try {
      const response = await getMyProducts(
        {
          user_id: user.id,
          offset: offset,
          limit: limit,
          brandsFilter: brandsFilter,
          categoriesFilter: categoriesFilter,
          stocksFilter: stocksFilter,
          searchKeyword: searchKeyword,
          cancelToken: source.token
        })
      setProductionCount(response.data.count)
      setBrands(response.data.distinctBrand)
      setCategories(response.data.distinctCategories)
      const tempArr = response.data.results.map((data, index) => {
        return {
          key: index,
          id: data.id,
          marketplace: "Trendyol",
          productMainId: data.productMainId,
          productCode: data.productCode,
          title: data.title,
          categoryName: data.categoryName,
          brand: data.brand,
          image: data.images,
          quantity: data.quantity,
          alarmValue: data.alarmValue
        }
      })
      setData(tempArr)
    } catch (error) {

    }
    setLoading(false)
  }



  // ALARM FUNCTIONS
  const showAlarmModal = async (info) => {
    setRowData(info)
    setIsAlarmModalVisible(true)
  }

  // Get alarm settings when product is selected
  useEffect(() => {
    if (rowData.id === undefined) return
    handleGetAlarmSettings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData])

  // Get selected product alarms
  const handleGetAlarmSettings = async () => {
    const response = await getAlarmSettings({ productId: rowData.id })
    const tempArr = response.data.results.map((data, index) => {
      return {
        key: index,
        id: data.id,
        percent: data.percent,
        alarmType: data.alarmType === 1 ? 'İyi' : data.alarmType === 2 ? 'Orta' : "Kritik",
        quantity: data.quantity,
      }
    })
    setAlarmData(tempArr)
  }

  // Add new alarm
  const onFinish = async (values) => {
    if (values.isQuantity) {
    } else {
      values.quantity = Math.ceil(rowData.quantity * (Number(values.percent) / 100))
    }
    delete values.isQuantity
    values.quantity = Number(values.quantity)
    values.percent = values.percent ? Number(values.percent) : null
    values.marketPlace = info.marketplaces.Trendyol
    values.productId = rowData.id

    try {
      await createAlarmSettings(values);
      handleGetAlarmSettings()
    } catch (error) {
      console.log(error)
    }
  }

  const deleteAlarm = async (info) => {
    props.setRedux({ loading: true })
    confirm({
      content: `Seçtiğiniz alarmı silmek istediğinize emin misiniz?`,
      okText: "Evet",
      cancelText: "Hayır",
      async onOk() {
        await deleteAlarmSetting(info.id)
        handleGetAlarmSettings()
        showNotification('success', 'Silindi!', `Seçtiğiniz alarm silindi!`)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
    props.setRedux({ loading: false })
  }

  // Get marketplaces for options
  useEffect(() => {
    const tempArr = []
    const marketPlaceTemp = []
    for (const [key, value] of Object.entries(info.marketplaces)) {
      const obj = {
        id: value,
        name: key,
      }
      tempArr.push(obj)
      marketPlaceTemp.push(value)
    }
    setMarketPlace(marketPlaceTemp)
    setMarketPlaceOptions(tempArr)
  }, [])
  // Handle Marketplace options select change
  const handleMarketplaceSelect = (value) => {
    if (value.includes("all")) {
      setMarketPlace(marketPlaceOptions.map(r => r.id))
    } else {
      setMarketPlace(value)
    }
  }
  const marketPlaceSelectOptions = marketPlaceOptions.map((res, index) => (
    <Select.Option key={index} value={res.id}>{res.name}</Select.Option>
  )
  )

  const brandOptions = brands.map((res, index) => (
    <Select.Option key={index} value={res}>{res}</Select.Option>
  ))
  const categoryOptions = categories.map((res, index) => (
    <Select.Option key={index} value={res}>{res}</Select.Option>
  ))


  const alarmColumns = [
    {
      title: 'Alarm Tipi',
      dataIndex: 'alarmType',
      key: 'alarmType',
    },
    {
      title: 'Yüzde',
      dataIndex: 'percent',
      key: 'percent',
    },
    {
      title: 'Adet',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'İşlem',
      key: 'action',
      render: (text, record) => (
        <DeleteOutlined className='cursor-p' onClick={() => deleteAlarm(record)} />
      ),
    },
  ]

  const productColumns = [
    {
      title: 'Alarm',
      key: 'action',
      render: (text, record) => (
        <Space>
          <GiAlarmClock size={14} className='cursor-p' onClick={() => showAlarmModal(record)} />
          {record.alarmValue !== null &&
            <div style={{
              width: 14,
              height: 14,
              borderRadius: 4,
              backgroundColor: record.alarmValue === 1 ? "rgba(82, 196, 26, 0.7)" :
                record.alarmValue === 2 ? "rgba(250, 173, 21, 0.7)" : "rgba(245, 35, 45, 0.7)"
            }}></div>

          }
        </Space>
      ),
    },
    {
      title: 'Pazaryeri',
      dataIndex: 'marketplace',
      key: 'marketplace',
    },
    {
      title: 'Ürün ID',
      dataIndex: 'productMainId',
      key: 'productMainId',
    },
    {
      title: 'Ürün Kodu',
      dataIndex: 'productCode',
      key: 'productCode',
    },
    {
      title: 'Ürün Adı',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Ürün Kategorisi',
      dataIndex: 'categoryName',
      key: 'categoryName',
    },
    {
      title: 'Ürün Görseli',
      dataIndex: 'image',
      key: 'image',
      render: (text, record) => {
        const images = text.map((data, index) => <Image src={data.url} width={15} key={index} />)
        return (
          <Space>
            {images}
          </Space>
        )
      }
    },
    {
      title: 'Marka',
      dataIndex: 'brand',
      key: 'brand',
    },
    // {
    //   title: 'Ürün Açıklaması',
    //   dataIndex: 'description',
    //   key: 'description',
    // },
    {
      title: 'Stok Adedi',
      dataIndex: 'quantity',
      key: 'quantity',
    },
  ];
  return (
    <Card>
      <Row gutter={[24, 24]}>
        {/* <Col xs={24} align='left'>
          <span className='page-title-style'>Ürün-Stok Yönetimi</span>
        </Col> */}
        <Col xs={24}>
          <Row gutter={[16, 16]} align='middle'>
            <Col xs={24} lg={12} align=''>
              <Input placeholder='Ürün araması yap' onChange={(e) => setSearchKeyword(e.target.value)} suffix={<Button style={{ position: 'absolute', right: 0 }} onClick={() => setToggleKeywordSearch(!toggleKeywordSearch)} type='primary'>Arama Yap</Button>} />
            </Col>
            <Col xs={24} lg={12} align='left'>
              <Row align='middle'>
                <Col xs={6} lg={{span: 6, offset: 2}}>
                  <label className="product-stock-container" style={{ marginTop: 12 }}>İyi
                    <input type="checkbox" defaultChecked={false} onChange={(event) => handleStockFilter(event.target.checked, 1)} />
                    <span className="checkmark checkmark-green"></span>
                  </label>
                </Col>
                <Col xs={6}>
                  <label className="product-stock-container" style={{ marginTop: 12 }}>Orta
                    <input type="checkbox" defaultChecked={false} onChange={(event) => handleStockFilter(event.target.checked, 2)} />
                    <span className="checkmark checkmark-orange"></span>
                  </label>
                </Col>
                <Col xs={6}>
                  <label className="product-stock-container" style={{ marginTop: 12 }}>Kritik
                    <input type="checkbox" defaultChecked={false} onChange={(event) => handleStockFilter(event.target.checked, 3)} />
                    <span className="checkmark checkmark-red"></span>
                  </label>
                </Col>
                <Col xs={4}>
                  <Information />
                </Col>
              </Row>
            </Col>
            <Col xs={24} lg={8}>
              <Select
                mode="multiple"
                maxTagCount={2}
                allowClear
                dropdownClassName='product-filter'
                value={brandsFilter}
                onChange={setBrandsFilter}
                style={{ width: '100%' }}
                placeholder="Lütfen marka seçin">
                {brandOptions}
              </Select>
            </Col>
            <Col xs={24} lg={8}>
              <Select
                mode="multiple"
                maxTagCount={2}
                allowClear
                dropdownClassName='product-filter'
                style={{ width: '100%' }}
                placeholder="Lütfen pazaryeri seçin"
                onChange={handleMarketplaceSelect}
                value={marketPlace}>
                <Select.Option value={'all'}>Hepsi</Select.Option>
                {marketPlaceSelectOptions}
              </Select>
            </Col>
            <Col xs={24} lg={8}>
              <Select
                mode="multiple"
                maxTagCount={2}
                allowClear
                dropdownClassName='product-filter'
                value={categoriesFilter}
                onChange={setCategoriesFilter}
                style={{ width: '100%' }}
                placeholder="Kategori">
                {categoryOptions}
              </Select>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Table className='small-table colorfull-table' rowClassName={(record) => record.alarmValue === 1 ? 'border-green table-border' : record.alarmValue === 2 ? 'border-orange table-border' : record.alarmValue === 3 ? 'border-red table-border' : undefined}
            columns={productColumns} pagination={false} dataSource={data} size='small' loading={{ spinning: loading, indicator: <LoadingOutlined /> }} />
        </Col>
        <Col xs={12} align='left'>
          Toplam: {productionCount} Kayıt
        </Col>
        <Col xs={12} align='right'>
          <div className='product-stock-management-pagination-container'>
            <Pagination className='product-stock-management-pagination' locale={{ items_per_page: 'Ürün' }} size='small' style={{ fontSize: 12 }} onChange={handleTablePagination}
              current={paginationInfo.offset} defaultCurrent={1} total={productionCount} />
          </div>
        </Col>
      </Row>


      {/* Alarm Modal */}
      <Modal title={'Alarm'} visible={isAlarmModalVisible} onOk={closeAlarmModal} onCancel={closeAlarmModal}
        footer={[
          <Button key="back" onClick={closeAlarmModal}>
            İptal
          </Button>,
          <Button key="submit" type="primary" onClick={() => form.submit()}>
            Alarm Ekle
          </Button>,
        ]}>
        <Form
          name="profile-form"
          form={form}
          initialValues={{}}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          layout='vertical'
          autoComplete="off">
          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24, offset: 0 }} lg={8}>
              <Form.Item label="Alarm Tipi" name="alarmType"
                rules={[{ required: true, message: 'Lütfen alarm tipi seçiniz!' },]}>
                <Select size='large'
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Lütfen alarm tipi seçin">
                  <Select.Option value={1}>İyi</Select.Option>
                  <Select.Option value={2}>Orta</Select.Option>
                  <Select.Option value={3}>Kritik</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24, offset: 0 }} lg={8}>
              <Form.Item label="Adet Birimi" name="isQuantity" initialValue={isQuantity}
                rules={[{ required: true, message: 'Lütfen adet birimi seçiniz!' }]}>
                <Select size='large' style={{ width: '100%' }}
                  placeholder="Lütfen adet birimi seçin" value={isQuantity} onChange={setIsQuantity}>
                  <Select.Option value={true}>Adet</Select.Option>
                  <Select.Option value={false}>Yüzde</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            {isQuantity ?
              <Col xs={{ span: 24, offset: 0 }} lg={8}>
                <Form.Item label="Net Adet" name="quantity"
                  rules={[{ required: true, message: 'Lütfen alarm için adet girin!' },]}>
                  <Input size='large' placeholder='Adet' type='number' min={0} />
                </Form.Item>
              </Col> :
              <Col xs={{ span: 24, offset: 0 }} lg={8}>
                <Form.Item label="Net Yüzde" name="percent"
                  rules={[{ required: true, message: 'Lütfen alarm için yüzde girin!' },]}>
                  <Input size='large' placeholder='Yüzde' type='number' min={0} suffix={'%'} />
                </Form.Item>
              </Col>
            }
          </Row>
        </Form>

        <Table columns={alarmColumns} dataSource={alarmData} size='small' loading={{ spinning: loading, indicator: <LoadingOutlined /> }} />
      </Modal>
    </Card>
  )
}


const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(ProductStockManagementContent));