/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { CalendarOutlined, UploadOutlined, ExclamationCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, Modal, Row, Select, Space, Upload } from 'antd'
import moment from 'moment'
import { getApiBaseClear } from '../../services/config'
import { showNotification } from '../../utils/showNotification'
import { updateTask, updateTaskGroup, uploadFiles } from './api'

const { confirm } = Modal;

const UpdateTask = (props) => {
  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)

  const openConfirmModal = () => setConfirmModalVisible(true)
  const closeConfirmModal = () => setConfirmModalVisible(false)

  const openDeleteModal = () => setDeleteModalVisible(true)
  const closeDeleteModal = () => setDeleteModalVisible(false)

  const [updateForm] = Form.useForm()

  const handleDeleteTask = () => {
    if (props.data.groupId === null) {
      handleSingleDelete()
      return
    }
    openDeleteModal()
  }

  const handleGroupDelete = async () => {
    const payload = {
      groupId: props.data.groupId,
      description: props.data.description,
      files: props.data.files,
      note: props.data.note,
      assignee: props.data.assignee.id,
      isDeleted: true
    }

    confirm({
      content: "Tekrar eden görevleri topluca silmek istediğinize emin misiniz?",
      okText: "Evet",
      cancelText: "Hayır",
      async onOk() {
        try {
          await updateTaskGroup(payload)
          closeDeleteModal()
          props.setUpdateCount(props.updateCount + 1)
          props.setIsTaskColOpen(false)
          showNotification('success', 'Görev silindi!', 'Seçmiş olduğunuz tekrar eden görevlerin tamamı silindi!')
        } catch (error) {
          console.log(error)
          showNotification('error', 'Hmm', 'Bir hata meydana geldi. Lütfen tekrar deneyin!')
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const handleSingleDelete = async () => {
    // Taskı tek başına silecek şekilde güncelle
    confirm({
      content: "Seçtiğiniz görevi silmek istediğinize emin misiniz?",
      okText: "Evet",
      cancelText: "Hayır",
      async onOk() {
        await updateTask(props.data.id, { isDeleted: true })
        props.setUpdateCount(props.updateCount + 1)
        props.setIsTaskColOpen(false)
        showNotification('success', 'Görev silindi!', 'Seçmiş olduğunuz görev silindi!')
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }


  const handleUpdateTask = () => {
    if (props.data.groupId === null) {
      updateForm.submit()
      return
    }
    openConfirmModal()
  }

  const handleGroupUpdate = async () => {
    const fileUrls = []
    props.data.files.map(data => {
      fileUrls.push(data)
      return null
    })

    const promise = updateForm.getFieldValue("files").map(async (file) => {
      if (file.originFileObj === undefined) return
      const fData = new FormData()
      fData.append("file", file.originFileObj)
      try {
        const res = await uploadFiles(fData);
        if (res.status === 201) {
          const fileUrl = getApiBaseClear() + res.data.file;
          fileUrls.push(fileUrl)
        }
      } catch (error) {
        console.log('error: ', error)
      }
    })

    await Promise.all(promise);

    const payload = {
      groupId: props.data.groupId,
      description: updateForm.getFieldValue("description"),
      files: fileUrls,
      note: updateForm.getFieldValue("note"),
      assignee: updateForm.getFieldValue("assignee"),
      isDeleted: false
    }
    try {
      await updateTaskGroup(payload)
      closeConfirmModal()
      props.setUpdateCount(props.updateCount + 1)
      props.setIsTaskColOpen(false)
      showNotification('success', 'Görev Güncellendi!', 'Seçmiş olduğunuz tekrar eden görevlerin tamamı güncellendi!')
    } catch (error) {
      console.log(error)
      showNotification('error', 'Hmm', 'Bir hata meydana geldi. Lütfen tekrar deneyin!')
    }
  }

  const onFinish = async (values) => {
    const fileUrls = []
    props.data.files.map(data => {
      fileUrls.push(data)
      return null
    })
    const promise = values.files.map(async (file) => {
      if (file.originFileObj === undefined) return
      const fData = new FormData()
      fData.append("file", file.originFileObj)
      try {
        const res = await uploadFiles(fData);
        if (res.status === 201) {
          const fileUrl = getApiBaseClear() + res.data.file;
          fileUrls.push(fileUrl)
        }
      } catch (error) {
        console.log('error: ', error)
      }
    })

    await Promise.all(promise);
    delete values.files
    values.files = fileUrls


    try {
      await updateTask(props.data.id, values)
      props.setUpdateCount(props.updateCount + 1)
      props.setIsTaskColOpen(false)
      showNotification('success', 'Görev Güncellendi!', 'Seçmiş olduğunuz görev güncellendi!')
    } catch (error) {
      console.log(error)
      showNotification('error', 'Uppss!', 'Bir hata meydana geldi!')
    }
  }
  useEffect(() => {
    if (props.data === null) return

    const api_base = getApiBaseClear() + "/media/upload/"

    const files = props.data.files.map((data, index) => {
      return {
        uid: index,
        name: data.slice(api_base.length),
        type: "image/png",
        status: "done",
        url: data
      }
    })
    updateForm.setFieldsValue({
      description: props.data.description,
      dueDate: moment(props.data.dueDate).format("YYYY-MM-DD"),
      note: props.data.note,
      assignee: props.data.assignee.id,
      files: files,
      taskStatus: props.data.taskStatus
    })
  }, [props.data])


  return (
    <Form
      name="task-management-form"
      form={updateForm}
      initialValues={{}}
      onFinish={onFinish}
      // onFinishFailed={onFinishFailed}
      layout='vertical'
      autoComplete="off">
      <Row gutter={[24, 0]} style={{ marginTop: 30 }}>
        <Col xs={{ span: 24, offset: 0 }}>
          <Form.Item label="Açıklama" name="description"
            rules={[{ required: false, message: '' },]}>
            <Input disabled={!props.data.isOpen} />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24, offset: 0 }}>
          <Form.Item label={
            <Space>
              <CalendarOutlined className='vertical-middle' />
              <span>Bitiş Tarihi</span>
            </Space>}
            name="dueDate" rules={[{ required: false, message: '' },]}>
            <Input type={'date'} min={moment().format('YYYY-MM-DD')} disabled={!props.data.isOpen} />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24, offset: 0 }}>
          <Form.Item label="Dosyalar" name="files" valuePropName='fileList' getValueFromEvent={props.normFile}
            rules={[{ required: false, message: '' },]}>
            <Upload beforeUpload={() => false}>
              <Button icon={<UploadOutlined />} disabled={!props.data.isOpen}>Dosya yükle</Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col xs={{ span: 24, offset: 0 }}>
          <Form.Item label="Not" name="note"
            rules={[{ required: false, message: '' },]}>
            <Input.TextArea rows={3} disabled={!props.data.isOpen} />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24, offset: 0 }}>
          <Form.Item label="Atanan Kişi" name="assignee"
            rules={[{ required: false, message: '' },]}>
            <Select style={{ width: '100%' }} disabled={!props.data.isOpen}
              placeholder="">
              {props.asigneeOptions}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={{ span: 24, offset: 0 }}>
          <Form.Item label="Görev Durumu" name="taskStatus" initialValue={1}
            rules={[{ required: false, message: '' },]}>
            <Select style={{ width: '100%' }} disabled={!props.data.isOpen}
              placeholder="">
              <Select.Option value={1}>Yeni</Select.Option>
              <Select.Option value={2}>Devam Ediyor</Select.Option>
              <Select.Option value={3}>Tamamlandı</Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} align='right'>
          <Space>
            <Button type='' icon={<DeleteOutlined />} onClick={handleDeleteTask} disabled={!props.data.isOpen}>Sil</Button>
            <Button type='primary' icon={<EditOutlined />} onClick={handleUpdateTask} disabled={!props.data.isOpen}>Güncelle</Button>
          </Space>
        </Col>
      </Row>

      {/* Toplu güncelle veya tekli güncelle için modal */}
      <Modal className='' width={400}
        title={""} visible={confirmModalVisible} onCancel={closeConfirmModal}
        footer={[
          <Button key="1" onClick={closeConfirmModal}>Vazgeç</Button>,
          <Button key="2" onClick={updateForm.submit}>Güncelle</Button>,
          <Button key="3" onClick={handleGroupUpdate} type="primary">Toplu Güncelle</Button>
        ]}>
        <Row gutter={[24, 24]}>
          <Col xs={4} align='center'>
            <ExclamationCircleOutlined style={{ fontSize: 36, color: "#ff5c09" }} />
          </Col>
          <Col xs={20} align='left'>
            <p style={{ fontWeight: 700 }}>Güncelleme tekrar eden bütün tasklarda geçerli olsun mu?</p>
            <p style={{ fontWeight: 400, color: "rgba(0, 0, 0, 0.45)" }}>Toplu güncellemelere statü ve bitiş tarihleri dahil değildir!</p>
          </Col>
        </Row>
      </Modal>
      {/* Toplu sil veya tekli sil için modal */}
      <Modal className='' width={400}
        title={""} visible={deleteModalVisible} onCancel={closeDeleteModal}
        footer={[
          <Button key="1" onClick={closeDeleteModal}>Vazgeç</Button>,
          <Button key="2" onClick={handleSingleDelete}>Sil</Button>,
          <Button key="3" onClick={handleGroupDelete} type="primary">Toplu Sil</Button>
        ]}>
        <Row gutter={[24, 24]}>
          <Col xs={4} align='center'>
            <ExclamationCircleOutlined style={{ fontSize: 36, color: "#ff5c09" }} />
          </Col>
          <Col xs={20} align='left'>
            <p style={{ fontWeight: 700 }}>Silme işlemi tekrar eden bütün tasklarda geçerli olsun mu?</p>
            <p style={{ fontWeight: 400, color: "rgba(0, 0, 0, 0.45)" }}>Toplu silme işlemi tekrar eden bütün taskları silecektir!</p>
          </Col>
        </Row>
      </Modal>
    </Form>
  )
}

export default UpdateTask