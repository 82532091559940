import { Card, Tooltip } from 'antd'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import TurkeyMap from 'turkey-map-react'
import Information from '../../utils/Information'
import { toTitleCase } from '../../utils/StringMethods'

const TurkishMapChart = (props) => {
  const  { data } = props;
  const [arr, setArr] = useState([])

  useEffect(() => {
    if (!data) return
    setArr(data?.deliveredOrderList)
  }, [data])
  
  const renderCity = (cityComponent, cityData) => {

    const isIncludeCity = arr.find((c) => parseInt(c.cityCode) === cityData.plateNumber);
    cityComponent.props.key = cityData.id;
    cityComponent.props['data-is-include-city'] = !!isIncludeCity;
    
    const handleTitle = () => {
      if (isIncludeCity){
        return (
          <div>
            <span><b>{isIncludeCity.city}</b></span><br/>
            <span><b>Ciro: </b> {parseFloat(isIncludeCity.totalCityPrice).toFixed(2)} ₺</span><br/>
            <span><b>Adet: </b> {isIncludeCity.totalCityOrderCount}</span>
          </div>
        )
      }
      else{
        return(
          toTitleCase(cityData.id)
        )
      }
    }

    return( 
      <Tooltip title={handleTitle} style={{textTransform: "capitalize"}} key={cityData.id}> 
          {cityComponent}
      </Tooltip>
    )
  };
 

  return (
  <Card className='card-shadow'>
    <span style={{ fontSize: 20, fontWeight: 600 }}>Türkiye Ciro Grafiği</span>&nbsp;&nbsp;&nbsp; <Information />
    <TurkeyMap cityWrapper={renderCity} />
  </Card>
  )
}

export default TurkishMapChart