import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'antd'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getBlogImg, getBlogsData } from '../api';
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const NewsCard = (props) => {
  const { data } = props;
  const history = useHistory();
  return (
    <div onClick={() => history.push(`./blog/${data.id}`)} className='height-100 news-carousel-card' style={{ marginRight: 10, padding: 24, textAlign: 'center' }} >
      <div style={{ height: 150 }}><img style={{ height: "100%", width: "100%", objectFit: "cover" }} alt="" src={data.media || "https://www.hizlihesap.com/wp-content/uploads/2022/10/1920x1080.png"} /></div>
      <span className='font-w500' style={{ display: "block", textAlign: "left", fontSize: 12 }}>{moment(data.date).format("L")}</span><br />
      <span className='font-w700'>{data.title.rendered}</span>
    </div>
  )
}

const IndustryNews = () => {
  const [blogdata, setBlogData] = useState([]);

  useEffect(() => {
    const handleGetBlogData = async () => {
      getBlogsData().then(response => {
        const data = response.data
        const tempArr = data.map( async (data) => {
          let media = "";
          return getBlogImg(data.featured_media).then(res => {
            media = res.data.link;
            return ({
              ...data,
              media: media
            })
          }).catch(err => {
            console.log('ERROR: ', err)
          })
        })
        Promise.all(tempArr).then((values)=> {
          setBlogData(values)
        })
      })
    }
    handleGetBlogData()
  }, [])


  const newsCards = blogdata?.map((data, index) => {
    if (data) {
      return (<NewsCard key={index} data={data} />)
    }
    return <div key={index}></div>
  })


  return (
    <Card className='border bg-white height-100'>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <span className='page-title-style'>Sektörel Haberler</span>
        </Col>
        <Col xs={24}>
          <Carousel
            additionalTransfrom={0}
            arrows
            autoPlay
            autoPlaySpeed={5000}
            centerMode={false}
            className=""
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024
                },
                items: 4,
                partialVisibilityGutter: 40
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0
                },
                items: 1,
                partialVisibilityGutter: 30
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464
                },
                items: 2,
                partialVisibilityGutter: 30
              }
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={2}
            swipeable
          >
            {newsCards}
          </Carousel>
        </Col>
      </Row>
    </Card>
  )
}

export default IndustryNews