/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/hizlihesap/actions";
import { Spin, Layout, Menu, Row, Col, Image, Space } from "antd";
import { LoadingOutlined, UserOutlined, MenuUnfoldOutlined, MenuFoldOutlined, HomeOutlined, ReloadOutlined, PieChartOutlined, CalendarOutlined, IdcardOutlined, QuestionOutlined, BellOutlined, SettingOutlined, LogoutOutlined, StockOutlined, ShoppingCartOutlined, CheckSquareOutlined } from '@ant-design/icons';
import { CgShapeCircle } from 'react-icons/cg'
import { RiGroupLine, RiSecurePaymentLine, RiUserSettingsLine } from 'react-icons/ri'

import logo from '../../assets/images/hizlihesap-logo/logo.png'
import logoText from '../../assets/images/hizlihesap-logo/logo-text.png'
import tempImg from '../../assets/images/icons/user.svg'
import bodyBg from '../../assets/images/backgrounds/body-bg.png'
import { useHistory } from 'react-router-dom';
import { getUser, isTokenValid } from './api';
import { isHavePermission } from '../../utils/Permission';
import { showNotification } from '../../utils/showNotification';
import ExchangeCarousel from './ExchangeCarousel';
import { UnderConstruction } from '../../utils/UnderConstruction';


const { Header, Content, Sider } = Layout;




const MainLayout = (props) => {
  const [user, setUser] = useState({})
  const [siderCollapsed, setSiderCollapsed] = useState(false)
  const [siderSelectedKey, setSiderSelectedKey] = useState('')

  const history = useHistory();

  useEffect(() => {
    props.setRedux({ innerWidth: window.innerWidth })
  }, [window.innerWidth])

  useEffect(() => {
    props.setRedux({ loading: false })
  }, [])

  useEffect(() => {
    if (Object.keys(user).length !== 0) return
    handleGetUser()
  }, [user])

  useEffect(() => {
    if (!props.reduxState?.reRender) return
    handleGetUser()
  }, [props.reduxState?.reRender])

  useEffect(() => {
    checkTokenValid()
  }, [])

  const checkTokenValid = async () => {
    const info = JSON.parse(window.localStorage.getItem('user'))

    const isValid = await isTokenValid(info.access_token)
    if (isValid) return
    handleLogout()
  }

  const handleGetUser = async () => {
    const info = JSON.parse(window.localStorage.getItem('user'))
    const user_ = await getUser(info.id)
    setUser(user_.data)
    props.setRedux({ user: user_.data })
  }

  const handleRouting = (info) => {
    if (!isHavePermission(user, info.key)) {
      showNotification('error', 'Yetkiniz Yok!', 'Gitmek istediğiniz sayfaya yetkiniz bulunmamaktadır!')
      return
    } else {
      props.setRedux({ loading: true })
      if (info.key === 'dashboard') {
        history.push(`/`)
        props.setRedux({ loading: false })
        setSiderSelectedKey(info.key)
        return
      }
      history.push(`/${info.key}`)
      setSiderSelectedKey(info.key)
      props.setRedux({ loading: false })
    }
  }

  const handleLogout = async () => {
    props.setRedux({ loading: true })
    window.localStorage.removeItem('user')
    history.push('/login')
    showNotification('info', 'Çıkış!', 'Oturumunuz sonlandı! Yeniden giriş yapmanız gerekmektedir.')
    // window.location.reload()
    props.setRedux({ loading: false })
  }

  useEffect(() => {
    const parsedUrl = new URL(window.location.href);
    const pathname = parsedUrl.pathname
    const clearPath = pathname.substring(1)
    if (clearPath === '') {
      setSiderSelectedKey('dashboard')
    } else if (clearPath === 'login') {
      // window.location.reload()
    } else if (clearPath.includes('support')) {
      setSiderSelectedKey('support')
    } else {
      setSiderSelectedKey(clearPath)
    }
  }, [window.location.href])

  return (
    <div>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={props.reduxState?.loading}>
        <Layout>
          <Sider width={230} className='layout-sider' trigger={null} collapsible collapsed={siderCollapsed}
            style={{
              overflow: 'auto',
              height: '100vh',
              position: 'sticky',
              left: 0,
              top: 0,
              bottom: 0,
            }} >
            <Row>
              <Col className='my-10' xs={22} align='center'>
                <Space className='cursor-p' onClick={() => history.push('/')}>
                  <Image width={47} preview={false} src={logo} />
                  {!siderCollapsed &&
                    <Image width={80} preview={false} src={logoText} />
                  }
                </Space>
              </Col>
            </Row>
            {siderSelectedKey !== '' &&
              <Menu className='font-w600' theme="light" mode="inline" selectedKeys={[siderSelectedKey]}
                defaultOpenKeys={siderSelectedKey.includes('integration') ? ['integration'] : siderSelectedKey.includes('account') ? ['account'] : []}
                items={[
                  {
                    key: 'dashboard',
                    icon: <HomeOutlined />,
                    label: 'Anasayfa',
                    onClick: handleRouting
                  },
                  {
                    key: 'integration',
                    icon: <ReloadOutlined />,
                    label: 'Entegrasyonlar',
                    className: 'menu-list',
                    id: "menu-integration",
                    // onTitleClick: handleRouting,
                    children: [
                      {
                        key: 'integration/trendyol',
                        icon: <CgShapeCircle />,
                        label: 'Trendyol API',
                        id: "menu-item-trendyol",
                        onClick: handleRouting
                      },
                      {
                        key: 'integration/hepsiburada',
                        icon: <CgShapeCircle />,
                        label: 'Hepsiburada API',
                        onClick: handleRouting
                      },
                      {
                        key: 'integration/n11',
                        icon: <CgShapeCircle />,
                        label: 'N11 API',
                        onClick: UnderConstruction,
                      },
                      {
                        key: 'integration/ciceksepeti',
                        icon: <CgShapeCircle />,
                        label: 'Çiçeksepeti API',
                        onClick: UnderConstruction,
                      },
                      {
                        key: 'integration/amazon',
                        icon: <CgShapeCircle />,
                        label: 'Amazon API',
                        onClick: UnderConstruction,
                      },
                      {
                        key: 'integration/etsy',
                        icon: <CgShapeCircle />,
                        label: 'Etsy API',
                        onClick: UnderConstruction,
                      },
                     /*  {
                        key: 'integration/ebay',
                        icon: <CgShapeCircle />,
                        label: 'eBay API',
                        onClick: UnderConstruction,
                      }, */
                    ]
                  },
                  {
                    key: 'product-stock-management',
                    icon: <StockOutlined />,
                    label: 'Ürün-Stok Yönetimi',
                    onClick: handleRouting
                  },
                  {
                    key: 'order-management',
                    icon: <ShoppingCartOutlined />,
                    label: 'Sipariş Yönetimi',
                    onClick: handleRouting
                  },
                  {
                    key: 'payment-calendar',
                    icon: <CalendarOutlined />,
                    label: 'Ödeme Takvimi',
                    onClick: handleRouting
                  },
                  {
                    key: 'reports',
                    icon: <PieChartOutlined />,
                    label: 'Raporlar',
                    onClick: handleRouting,
                  },
                  {
                    key: 'task-management',
                    icon: <CheckSquareOutlined />,
                    label: 'Görev Yönetimi',
                    onClick: handleRouting
                  },
                  {
                    key: 'support',
                    icon: <IdcardOutlined />,
                    label: 'Destek',
                    onClick: handleRouting
                  },
                  {
                    key: 'faq',
                    icon: <QuestionOutlined />,
                    label: 'SSS',
                    onClick: handleRouting
                  },
                  {
                    key: 'account',
                    icon: <UserOutlined />,
                    label: 'Hesabım',
                    className: 'menu-list',
                    children: [
                      {
                        key: 'account/profile',
                        icon: <CgShapeCircle />,
                        label: 'Profil Ayarları',
                        onClick: handleRouting,
                      },
                      {
                        key: 'account/companysettings',
                        icon: <CgShapeCircle />,
                        label: 'Firma Ayarları',
                        onClick: handleRouting,
                      },
                      {
                        key: 'account/users',
                        icon: <CgShapeCircle />,
                        label: 'Kullanıcılar',
                        onClick: handleRouting,
                      },
                      {
                        key: 'account/subscriptions',
                        icon: <CgShapeCircle />,
                        label: 'Abonelikler',
                        onClick: handleRouting,
                      },
                      {
                        key: 'account/paymentmethods',
                        icon: <CgShapeCircle />,
                        label: 'Ödeme Yöntemleri',
                        onClick: UnderConstruction,
                      },
                      {
                        key: 'account/logout',
                        icon: <CgShapeCircle />,
                        label: 'Çıkış',
                        onClick: handleLogout
                      },
                    ]
                  },
                ]}
              />
            }
          </Sider>
          <Layout>
            <Header className="layout-header" style={{background: "#FFFFFF"}}>
              {React.createElement(siderCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                style: { fontSize: 18, marginTop: 20, marginLeft: 10, color: "#FFFFFF" },
                onClick: () => setSiderCollapsed(!siderCollapsed),
              })}
              <div style={{
                position: 'absolute',
                left: 70,
                top: 0,
                pointerEvents: "none",
                fontSize: 28,
                color: "#FFFFFF"
              }}>
                {(siderSelectedKey === "dashboard" || siderSelectedKey === "") ?
                  <Space size="large">
                    <HomeOutlined />
                    <span>Ana Sayfa</span>
                  </Space> : siderSelectedKey === "integration/trendyol" ?
                  <Space size="large">
                    <ReloadOutlined />
                    <span>Trendyol API</span>
                  </Space> : siderSelectedKey === "integration/hepsiburada" ?
                  <Space size="large">
                    <ReloadOutlined />
                    <span>Hepsiburada API</span>
                  </Space> : siderSelectedKey === "product-stock-management" ?
                  <Space size="large">
                    <StockOutlined />
                    <span>Ürün-Stok Yönetimi</span>
                  </Space> : siderSelectedKey === "order-management" ?
                  <Space size="large">
                    <ShoppingCartOutlined />
                    <span>Sipariş Yönetimi</span>
                  </Space> : siderSelectedKey === "payment-calendar" ?
                  <Space size="large">
                    <CalendarOutlined />
                    <span>Ödeme Takvimi</span>
                  </Space> : siderSelectedKey === "task-management" ?
                  <Space size="large">
                    <CheckSquareOutlined />
                    <span>Görev Yönetimi</span>
                  </Space> : siderSelectedKey === "support" ?
                  <Space size="large">
                    <IdcardOutlined />
                    <span>Destek</span>
                  </Space> : siderSelectedKey === "faq" ?
                  <Space size="large">
                    <QuestionOutlined />
                    <span>Sıkça Sorulan Sorular</span>
                  </Space> : siderSelectedKey === "account/profile" ?
                  <Space size="large">
                    <UserOutlined />
                    <span>Profil Ayarları</span>
                  </Space> : siderSelectedKey === "account/companysettings" ?
                  <Space size="large">
                    <UserOutlined />
                    <span>Firma Ayarları</span>
                  </Space> : siderSelectedKey === "account/users" ?
                  <Space size="large">
                    <UserOutlined />
                    <span>Kullanıcılar</span>
                  </Space> : siderSelectedKey === "account/subscriptions" ?
                  <Space size="large">
                    <UserOutlined />
                    <span>Abonelikler</span>
                  </Space> : null
                  }
              </div>
              {props.reduxState?.innerWidth >= 768 &&
                <div className='sticy-display'>
                  <Space size='large'>
                    <ExchangeCarousel />
                    <BellOutlined className='notification-bell' />
                    <Menu style={{ height: '53px', background: "transparent" }} mode="horizontal" items={[
                      {
                        key: '2',
                        icon: <Image src={user?.profile?.photo !== null ? user?.profile?.photo : tempImg} width={50} preview={false} style={{ borderRadius: '50%' }} />,
                        // label: '',,
                        children: [
                          {
                            key: 'account/profile',
                            icon: <RiUserSettingsLine />,
                            label: 'Profil Ayarları',
                            onClick: handleRouting,
                          },
                          {
                            key: 'account/companysettings',
                            icon: <SettingOutlined />,
                            label: 'Firma Ayarları',
                            onClick: handleRouting,
                          },
                          {
                            key: 'account/users',
                            icon: <RiGroupLine />,
                            label: 'Kullanıcılar',
                            onClick: handleRouting,
                          },
                          {
                            key: 'account/subscriptions',
                            icon: <CgShapeCircle />,
                            label: 'Abonelikler'
                          },
                          {
                            key: 'account/paymentmethods',
                            icon: <RiSecurePaymentLine />,
                            label: 'Ödeme Yöntemleri'
                          },
                          {
                            key: '22',
                            icon: <LogoutOutlined />,
                            label: 'Çıkış',
                            onClick: handleLogout
                          },
                        ]
                      },
                    ]}>
                    </Menu>
                  </Space>
                </div>
              }
            </Header>
            <Content
              style={{
                margin: props.reduxState?.innerWidth >= 768 ? '48px 0px 0px 0px' : '48px 0px 0px 0px',
                padding: props.reduxState?.innerWidth >= 768 ? '24px 12px 0px 12px' : '24px 10px 24px 10px',
                minHeight: 150, backgroundImage: `url(${bodyBg})`
              }}>
              {(props.reduxState?.innerWidth <= 768 && !siderCollapsed) ? null : props.content}
            </Content>
          </Layout>
        </Layout>
      </Spin>
    </div >
  );
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(MainLayout));