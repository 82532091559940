import React, { useEffect } from 'react'
import { Card } from 'antd'
import Information from '../../utils/Information'
import { Bar } from '@ant-design/plots';
import { useState } from 'react';

const CityBarPlot = (props) => {
  const [style, setStyle] = useState({})
  const [data, setData] = useState([])

  useEffect(()=>{
    const tempArr = props.data?.deliveredOrderList.map((value, index)=>{
      if (index > 9) return {}
      return {
        city: value.city,
        turnover: parseFloat(value.totalCityPrice),
      }
    })
    if (!tempArr) return
    setData(tempArr)
  },[props])



  /* const data = ([
    {
      type: 'İstanbul',
      sales: 30,
    },
    {
      type: 'Ankara',
      sales: 15,
    },
    {
      type: 'İzmir',
      sales: 10,
    },
    {
      type: 'Adana',
      sales: 7,
    },
    {
      type: 'Bursa',
      sales: 6,
    },
    {
      type: 'Antalya',
      sales: 6,
    },
    {
      type: 'Konya',
      sales: 5,
    },
    {
      type: 'Tekirdağ',
      sales: 6,
    },
    {
      type: 'Eskişehir',
      sales: 6,
    },
    {
      type: 'Adana',
      sales: 4,
    },
    {
      type: 'Samsun',
      sales: 2,
    },
    {
      type: 'Ordu',
      sales: 1,
    },
    {
      type: 'Kahramanmaraş',
      sales: 3,
    },
    {
      type: 'Mersin',
      sales: 1,
    },
  ]) */
  const config = {
    data,
    xField: 'turnover',
    yField: 'city',
    meta: {
      city: {
        alias: 'as',
      },
      turnover: {
        alias: 'Ciro',
        formatter: (value) => `${value?.toFixed(2)} ₺`
      },
    },
    /* tooltip: {
      customItems: (originalItems) => {
        // process originalItems, 
        return originalItems;
      },
    }, */
    minBarWidth: 20,
    maxBarWidth: 20,
    color: '#ff5c09',
  }

  useEffect(() => {
    const height = document.getElementById("myCard").offsetHeight - 150
    setStyle({height: height})
  }, [])

  return (
    <Card id="myCard" className='card-shadow height-100' bodyStyle={style}>
      <span style={{ fontSize: 20, fontWeight: 600 }}>İllere Göre Dağılımı</span>&nbsp;&nbsp;&nbsp; <Information />
        <Bar {...config} />
    </Card>
  )
}

export default CityBarPlot