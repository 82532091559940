import React from 'react'
import { Card, Col, Row } from 'antd'
import EndorsementChart from './EndorsementChart'

const EndorsementCard = (props) => {
  return (
    <Card className='border bg-white height-100 card-shadow'>
      <Row gutter={[0,0]}>
        <Col xs={24}>
          <span className='page-title-style'>Ciro Dağılımı</span>
        </Col>
        <Col xs={24}>
          <EndorsementChart data={props.data} />
        </Col>
      </Row>
    </Card>
  )
}

export default EndorsementCard