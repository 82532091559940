import { DownOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Input,
  Menu,
  Row,
  Select,
  Space,
} from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import info from "../../utils/marketplaces.json";

const OrderFilter = (props) => {
  const [filterData, setFilterData] = useState({
    customerName: "",
    orderNumber: "",
    packageNumber: 0,
    orderDateStart: "",
    orderDateEnd: "",
    cargoProviderName: "",
    status: "",
    barcode: "",
    productName: "",
    status_in: [
      "Created",
      "Delivered",
      "Picking",
      "Shipped",
      "Unpacked",
      "Repack",
    ],
  });
  const [toggle, setToggle] = useState(false);
  const [marketPlace, setMarketPlace] = useState([]);
  const [marketPlaceOptions, setMarketPlaceOptions] = useState([]);

  const handlePrintTicket = useReactToPrint({
    content: () => {
      const elemPrint = document.createElement("div");
      props.selectedRows.map((key) => {
        return elemPrint.appendChild(props.ticketRef[key]);
      });
      return elemPrint;
    },
  });
  const handlePrintSticker = useReactToPrint({
    content: () => {
      const elemPrint = document.createElement("div");
      props.selectedRows.map((key) => {
        return elemPrint.appendChild(props.stickerRef[key]);
      });
      return elemPrint;
    },
  });

  useEffect(() => {
    props.filterData({ data: filterData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData.status_in, toggle]);

  const handleSetStatus = (checked, value) => {
    const temp = [...filterData.status_in];
    if (checked) {
      temp.push(value);
      setFilterData({ ...filterData, status_in: temp });
    } else {
      const popOut = temp.filter((data) => data !== value);
      setFilterData({ ...filterData, status_in: popOut });
    }
  };

  const { RangePicker } = DatePicker;
  const batchOperation = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <span style={{ display: "block" }} onClick={handlePrintTicket}>
              A4 Etiketi Yazdır
            </span>
          ),
        },
        {
          key: "2",
          label: (
            <span style={{ display: "block" }} onClick={handlePrintSticker}>
              Sticker Etiketi Yazdır
            </span>
          ),
        },
      ]}
    />
  );

  useEffect(() => {
    const tempArr = [];
    const marketPlaceTemp = [];
    for (const [key, value] of Object.entries(info.marketplaces)) {
      const obj = {
        id: value,
        name: key,
      };
      tempArr.push(obj);
      marketPlaceTemp.push(value);
    }
    setMarketPlace(marketPlaceTemp);
    setMarketPlaceOptions(tempArr);
  }, []);
  // Handle Marketplace options select change
  const handleMarketplaceSelect = (value) => {
    if (value.includes("all")) {
      setMarketPlace(marketPlaceOptions.map((r) => r.id));
    } else {
      setMarketPlace(value);
    }
  };
  const marketPlaceSelectOptions = marketPlaceOptions.map((res, index) => (
    <Select.Option key={index} value={res.id}>
      {res.name}
    </Select.Option>
  ));

  return (
    <Row gutter={[16, 16]}>
      <Col xs={12} lg={6}>
        <Input
          placeholder="Müşteri Adı"
          onChange={(e) =>
            setFilterData({ ...filterData, customerName: e.target.value })
          }
        />
      </Col>
      <Col xs={12} lg={6}>
        <Input
          placeholder="Sipariş Numarası"
          onChange={(e) =>
            setFilterData({ ...filterData, orderNumber: e.target.value })
          }
        />
      </Col>
      <Col xs={12} lg={6}>
        <Input
          placeholder="Paket Numarası"
          onChange={(e) =>
            setFilterData({ ...filterData, packageNumber: e.target.value })
          }
        />
      </Col>
      <Col xs={12} lg={6} align={"right"}>
        <RangePicker
          format="DD.MM.YYYY"
          className="range-picker"
          onChange={(values) => {
            values !== null
              ? setFilterData({
                  ...filterData,
                  orderDateStart: values[0].valueOf(),
                  orderDateEnd: values[1].valueOf(),
                })
              : setFilterData({
                  ...filterData,
                  orderDateStart: "",
                  orderDateEnd: "",
                });
          }}
        />
      </Col>
      <Col xs={12} lg={6}>
        <Input
          placeholder="Kargo Firması"
          onChange={(e) =>
            setFilterData({ ...filterData, cargoProviderName: e.target.value })
          }
        />
      </Col>
      <Col xs={12} lg={6}>
        <Select
          allowClear
          dropdownClassName="product-filter"
          style={{ width: "100%" }}
          placeholder="Sipariş Durumu"
          onChange={(value) => setFilterData({ ...filterData, status: value })}
        >
          <Select.Option value={"Created"}>Yeni Sipariş</Select.Option>
          <Select.Option value={"Delivered"}>Teslim Edilen</Select.Option>
          <Select.Option value={"Picking"}>İşleme Alınan</Select.Option>
          <Select.Option value={"Shipped"}>Taşıma Durumunda</Select.Option>
          <Select.Option value={"Unpacked"}>Eksik Ürün</Select.Option>
          <Select.Option value={"Repack"}>Tekrar Sevk Edilenler</Select.Option>
        </Select>
      </Col>
      <Col xs={12} lg={6}>
        <Input
          placeholder="Barkod Numarası"
          onChange={(e) =>
            setFilterData({ ...filterData, barcode: e.target.value })
          }
        />
      </Col>
      <Col xs={12} lg={6}>
        <Input
          onChange={(e) =>
            setFilterData({ ...filterData, productName: e.target.value })
          }
          placeholder="Ürün araması yap"
          suffix={
            <Button
              style={{ position: "absolute", right: 0 }}
              onClick={() => setToggle(!toggle)}
              type="primary"
            >
              Arama Yap
            </Button>
          }
        />
      </Col>
      <Col xs={24}>
        <Row gutter={[0, 8]}>
          <Col xs={24} lg={7}>
            <Row>
              <Col xs={12}>
                <Dropdown overlay={batchOperation} trigger={["click"]}>
                  <Space className="batch-container">
                    Toplu İşlemler
                    <Button className="batch">
                      <DownOutlined />
                    </Button>
                  </Space>
                </Dropdown>
              </Col>
              <Col xs={12} align={"middle"} className="marketplace-container">
                <Select
                  mode="multiple"
                  maxTagCount={1}
                  allowClear
                  className="marketplace"
                  dropdownClassName="product-filter"
                  style={{ width: "100%" }}
                  placeholder="Lütfen pazaryeri seçin"
                  onChange={handleMarketplaceSelect}
                  value={marketPlace}
                >
                  <Select.Option value={"all"}>Hepsi</Select.Option>
                  {marketPlaceSelectOptions}
                </Select>
              </Col>
            </Row>
          </Col>
          <Col xs={24} lg={17} className="order-status-list">
            <Row gutter={[8, 8]}>
              <Col>
                <label className="order-status-container">
                  Yeni Sipariş
                  <input
                    type="checkbox"
                    defaultChecked={filterData.status_in.includes("Created")}
                    onChange={(event) =>
                      handleSetStatus(event.target.checked, "Created")
                    }
                  />
                  <span className="checkmark checkmark-green"></span>
                </label>
              </Col>
              <Col>
                <label className="order-status-container">
                  Teslim Edilen
                  <input
                    type="checkbox"
                    defaultChecked={filterData.status_in.includes("Delivered")}
                    onChange={(event) =>
                      handleSetStatus(event.target.checked, "Delivered")
                    }
                  />
                  <span className="checkmark checkmark-blue"></span>
                </label>
              </Col>
              <Col>
                <label className="order-status-container">
                  İşleme Alınanlar
                  <input
                    type="checkbox"
                    defaultChecked={filterData.status_in.includes("Picking")}
                    onChange={(event) =>
                      handleSetStatus(event.target.checked, "Picking")
                    }
                  />
                  <span className="checkmark checkmark-orange"></span>
                </label>
              </Col>
              <Col>
                <label className="order-status-container">
                  Taşıma Durumunda
                  <input
                    type="checkbox"
                    defaultChecked={filterData.status_in.includes("Shipped")}
                    onChange={(event) =>
                      handleSetStatus(event.target.checked, "Shipped")
                    }
                  />
                  <span className="checkmark checkmark-purple"></span>
                </label>
              </Col>
              <Col>
                <label className="order-status-container">
                  Eksik Ürün
                  <input
                    type="checkbox"
                    defaultChecked={filterData.status_in.includes("Unpacked")}
                    onChange={(event) =>
                      handleSetStatus(event.target.checked, "Unpacked")
                    }
                  />
                  <span className="checkmark checkmark-red"></span>
                </label>
              </Col>
              <Col>
                <label className="order-status-container">
                  Tekrar Sevk Edilenler
                  <input
                    type="checkbox"
                    defaultChecked={filterData.status_in.includes("Repack")}
                    onChange={(event) =>
                      handleSetStatus(event.target.checked, "Repack")
                    }
                  />
                  <span className="checkmark checkmark-grey"></span>
                </label>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Col xs={24}></Col>
    </Row>
  );
};

export default OrderFilter;
