import React from 'react'
import { Pie } from '@ant-design/plots';

const EndorsementChart = (props) => {

  const data = [
    {
      type: 'Trendyol',
      value: props.data?.orderCount ?? 0,
    },
  /*   {
      type: 'Hepsiburada',
      value: 25,
    },
    {
      type: 'Amazon',
      value: 18,
    }, */
  ];
  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    color: ({type}) => {
      if(type === 'Trendyol') {
        return '#ff5c09'
      } else if (type === 'Hepsiburada'){
        return 'rgba(245, 35, 45, 0.7)'
      } else if (type === 'Amazon'){
        return 'rgba(82, 196, 26, 0.7)'
      }
    },
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
    },
    label: {
      type: 'inner',
      offset: '-50%',
      style: {
        textAlign: 'center',
      },
      autoRotate: false,
      content: '{value}',
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: 12,
        },
        content: `TOPLAM\n${props.data.totalPrice?.toFixed(2) ?? 0} ₺`,
      },
    },
  };
  return <Pie {...config} />;
}

export default EndorsementChart